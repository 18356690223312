import React, { useEffect, Fragment } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import ReactDOM from 'react-dom';
import BaseSelect from "react-select";
import FixRequiredSelect from '../../../helpers/FixRequiredSelect';

import { getIconoTable, dateSqlToLocal } from '../../../helpers/helperApp';

import { getApiUrl } from '../../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowgroup')

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();

var botones = [
    // {
    //     extend: 'pageLength',
    //     text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Ver número de registros'
    // },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    // {
    //     extend: 'colvis',
    //     text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    // }
];

function MiDataTableAdjLin(props){


    // ***** props *****
    const { id, columnas, datosTable, saveDatosTable, readonly, estadoForm, columnactions, traduction, handleEditReg } = props;
    let { buttons, ordering, order, searching, paging, select, grouping, pagelength } = props;
    
    useEffect(()=>{

        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);


        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        select === undefined ? select = false : select = select;
        grouping === undefined ? grouping = false : grouping = grouping;
        pagelength === undefined ? pagelength = traduction.datatable.defaultpagelength : pagelength = pagelength;

    
        if( columnactions != null && columnactions != "null" && columnactions != "" ){
            columnas.push({      
                title: columnactions,
                data: null,
                className: 'text-center',
                sortable: false,
                width: "5%", 
                createdCell : (td, cellData, rowData, row, col) => {
                    if( rowData.permiso == "false" ){
                        // Ocultamos los row de los ficheros de los que no tenemos permiso para ver
                        $(td).parent().css("display", "none")
                    }else{
                        let templateBtn =  ReactDOM.render(<div className="flex-center">                                                         
                                                    <span 
                                                                id={ rowData.idlin } 
                                                                className=" btn btn-flat btn-outline-primary mr-1" 
                                                                style={{border: 0}} 
                                                                onClick={ e => { handleEditReg(e) } }
                                                            >
                                                                    <i  
                                                                            className="fal fa-edit"
                                                                            id={ rowData.idlin } 
                                                                            onClick={ e => { handleEditReg(e) }}
                                                                        >
                                                                    </i>
                                                            </span>
                                                    
                                                </div>
                                            ,td);
                        $(td).html(templateBtn)
                    }   
                }
            });
        }

        columnas.map((columna, i) => {
            if( i == 1 ){
                // La foto/icono
                columna.createdCell = (td, cellData, rowData, row, col) => { 
                    if( rowData.type == undefined ){
                        // Adjunto tipo url
                        let template = `
                            <div class="text-center">
                                <a href="${rowData.rut}" target="_blank" >
                                    <i class="fab fa-chrome fa-2x" ></i>
                                </a>                                                                                                                                        
                            </div>                     
                        `;
                        $(td).html(template);
                    }else{
                        if( rowData.type.split("/")[0] !== "image" ){
                            let ext = rowData.rut.split(".")[rowData.rut.split(".").length-1];
                            let confIco = getIconoTable(ext);
                            let template = `
                                <div class="text-center">
                                    <a href=${getApiUrl(window.location.origin)+rowData.rut} target="_blank" download=${getApiUrl(window.location.origin)+rowData.rut} >
                                        <i class="${confIco["icono"]}" style="color:${confIco["color"]}"></i>
                                    </a>                                                                                                                                        
                                </div>                     
                            `;
                            $(td).html(template);
                        }else if( rowData.type.split("/")[0] == "image" ){
                            let templateFoto = `
                                <div>                
                                    <div class="containerImgTable">
                                        <img class="imgUplmTable" src=${getApiUrl(window.location.origin)+cellData} />
                                    </div>
                                </div>
                            `;
                            $(td).html(templateFoto);
    
                        }                                                            
                    }                
                }
            }
            
        })

        if( datosTable ){
            if( datosTable.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < datosTable.length; i++){
                        oTable.row.add(datosTable[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({                      
                        mark: true,
                        columnDefs: [
                            { "type": "html-input", "targets": [3, 4] }
                        ], 
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: datosTable,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: false,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: -1,
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.datatable.sprocessing.title,
                            "sLengthMenu":    traduction.datatable.slengthmenu.title,
                            "sZeroRecords":   traduction.datatable.szerorecords.title,
                            "sEmptyTable":    traduction.datatable.semptytable.title,
                            "sInfo":          traduction.datatable.sinfo.title,
                            "sInfoEmpty":     traduction.datatable.sinfoempty.title,
                            "sInfoFiltered":  traduction.datatable.sinfofiltered.title,
                            "sInfoPostFix":   traduction.datatable.sinfopostfix.title,
                            "sSearch":        traduction.datatable.ssearch.title,
                            "sUrl":           traduction.datatable.surl.title,
                            "sInfoThousands":  traduction.datatable.sinfothousands.title,
                            "sLoadingRecords": traduction.datatable.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.datatable.sfirst.title,
                                "sLast":    traduction.datatable.slast.title,
                                "sNext":    traduction.datatable.snext.title,
                                "sPrevious": traduction.datatable.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.datatable.ssortascending.title,
                                "sSortDescending": traduction.datatable.ssortdescending.title
                            },
                            select: {
                                rows: traduction.datatable.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.datatable.copytitle.title,
                                copyKeys: traduction.datatable.copykeys.title,
                                copySuccess: {
                                    _: traduction.datatable.copysuccess.many.title,
                                    1: traduction.datatable.copysuccess.one.title
                                },
                            }        
                        },                     
                        drawCallback : function ( settings ) {
                            var api = this.api();
                            var rows = api.rows( {page:'current'} ).nodes();
                            var last=null;
                 
                            if( grouping ){
                                api.column(3, {page:'current'} ).data().each( function ( group, i ) {
                                    if ( last !== group ) {
                                        if( group != "" ){
                                            $(rows).eq( i ).before(
                                                '<tr class="group"><td colspan="5" style="background-Color: rgba(0,0,0,.05)"><h6>'+group+'</h6></td></tr>'
                                            );
                                        }
                     
                                        last = group;
                                    }
                                } );                            
                            }
                        }
                    })    
                } 
            }          
        }
    }, [datosTable])
    

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;

    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas


    return(
        <Fragment>
            {
                datosTable == null
                ?
                  null
                :
                datosTable.length > 0
                ?
                    <div className="mb-2" style={{overflowX: "auto"}}>
                        <table id={id} className={id+" table table-sm table-bordered table-hover dataTable adjPlus"} style={{width:"100%"}}>                            
                        </table>
                    </div>   
                :
                    null
            }
               
            
        </Fragment>
    )
}

export default MiDataTableAdjLin