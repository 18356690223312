import React, { useEffect, useState, useContext } from 'react';
import StepWizard from "react-step-wizard";
import WizardCheckListStep from './WizardCheckListStep';
import WizardCheckListStepAgrupado from './WizardCheckListStepAgrupado';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import axios from 'axios';

import { LoginContext } from '../../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';
import { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import ButtonOutline from '../../ui/ButtonOutline';
import Informes from '../informes/Informes';

import { getIndexUrl, getApiUrl, axiosPost } from '../../../helpers/helperApp';

function WizardCheckList(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { titulo, cabecera, registrosHijo, controles, estadoForm, adjuntos, adjuntosExistentes, confPantalla, saveLoadReg, propiedades, adjuntosCabecera, informeSelect, informeLoadReg, informeEstadoForm, informeTraduction, loadedHijos } = props;

    const { apiKey, menuMode } = useContext(LoginContext);

    const [ lineas, saveLineas ] = useState([]);
    const [ currentStep, saveCurrentStep] = useState(0);
    const [ regAnterior, saveRegAnterior ] = useState();
    const [ loadedSteps, saveLoadedSteps ] = useState(false);
    const [ loadingForms, saveLoadingForms ] = useState(true);
    const [ trabajoSinLineas, saveTrabajoSinLineas ] = useState(false); 
    const [ lineaSoloHerramientas, saveLineaSoloHerramientas ] = useState([]);
    const [ lineasAgrupadas, saveLineasAgrupadas ] = useState([]);
    const [ nomEquipo, saveNomEquipo ] = useState("");
    const [ loadedHijosState, saveLoadedHijosState ]=useState(loadedHijos);
           
    useEffect( () => { 

        // Cargamos la configuración de todas las líneas y guardamos las que no sean herramientas en el state para pasarle al stepwizard
        init(registrosHijo); 
        
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 

    },[registrosHijo])

    // Preparamos los adjuntos de la cabecera para el datatable    
    adjuntosCabecera.map(adj => {
        adj["html"] = `<a 
                        href='${getApiUrl(window.location.origin)+adj.rut}'
                        target='_blank'
                        download=${getApiUrl(window.location.origin)+adj.rut}
                        class='btn btn-success'
                        style='color: white !important'
                        >
                            <i 
                                class='fal fa-download'
                                data-rut=${getApiUrl(window.location.origin)+adj.rut}
                                style='margin-right:5'
                            >
                            </i>
                        </a>
                        ${adj.des}
                    `; 

    }) 

    const init = async (registrosHijo) => {

        await loadConfWizard(registrosHijo)

    }


    const loadConfWizard = async(registrosHijo) => {

        const data = new FormData();
        data.append("validacion", apiKey());

        let regsIdUniIdTip = []
        registrosHijo.map( reg => {
            let aux = { idreg : reg.id, iduni : reg.iduni, idtip : reg.tipuni }
            regsIdUniIdTip.push(aux);
        } )

        data.append("getconf", JSON.stringify(regsIdUniIdTip));
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+controles.wizardchecklist.getconfuni, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+controles.wizardchecklist.getconfuni, data, source.token);
        let todasLineasSinConfiguracion = true;
        let materialInsertado = false;
        let auxLineasSinAgrupar = [];
        if( Object.keys(respuesta.data).length > 0 ){
            for( let i=0; i<Object.keys(respuesta.data).length; i++ ){
                for (const [key, configuracionLinea] of Object.entries(respuesta.data[i])) {
                    let agrupado = false;

                    // Filtro el registro que estoy iterando
                    const registro = registrosHijo.filter( reg => reg.id == key )[0];

                    // Guardamos el nombre del equipo en el state
                    saveNomEquipo(registro.equipo);                    

                    if( configuracionLinea ){
                        todasLineasSinConfiguracion = false;
                        configuracionLinea.split(";").map((e) => {
                            if( e == 21 || e == "21R" || e == "R21" || e == "21SL" || e == "SL21"){                                
                                agrupado=true; 
                            }                                    
                        })
                        // Almacenamos en el state que pasamos al stepwizar las lineas que no están agrupadas
                        if( !agrupado ){
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineas(lineas => [...lineas, aux]);
                            auxLineasSinAgrupar.push(aux);
                        }
                        // Almacenamos en el state de lineas agrupadas para crear un único paso para ellos
                        if( agrupado ){
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineasAgrupadas(lineasAgrupadas => [...lineasAgrupadas, aux]);
                            if( !materialInsertado ){
                                materialInsertado = true;
                                let auxLineaMat = [];
                                auxLineaMat.push(aux);
                                for( let i=0; i<auxLineasSinAgrupar.length; i++ ){
                                    auxLineaMat.push(auxLineasSinAgrupar[i]);
                                }
                                saveLineas(auxLineaMat);
                            }                               
                        }                           
                    }
    
                }
            }
            
            // Modificación corrección mensaje trabajo no disponble mientras no llegan las lineas
            saveTrabajoSinLineas(false);
            if( todasLineasSinConfiguracion ){
                saveTrabajoSinLineas(true);
            }
            
        }else if( respuesta.data.length == 0 ){
            // Si i es igual a 0 no hay lineas
            saveTrabajoSinLineas(true);
            saveLoadedSteps(true);
        }
        
        saveLoadedSteps(true);

    }

    const steps = []
    for (let i = 1 ; i <= lineas.length; i++) {        
            steps.push(
                <Step>
                    <StepLabel></StepLabel>
                </Step>
            )
    }

    const noLinesNext = () => {
        propiedades.history.push(getIndexUrl(menuMode, "wizardchecklist"));
    } 

    return(
        <div>
            {
                lineas.length > 0 && loadedSteps 
                ?
                <Fragment>
                    <Informes
                        select =     { informeSelect }
                        formulario = { cabecera }
                        loadReg =    { informeLoadReg ? true : false }
                        estadoForm=  { informeEstadoForm } 
                        traduction=  { informeTraduction }
                    />   
                    <div className="row">
                        <hr data-content={currentStep+1+"/"+lineas.length} className="hr-text-xl"></hr>
                    </div>
                    <div className="row" style={{overflowY: "auto"}}>
                        <div className="col-md-12 stepsContainer">
                            <Stepper activeStep={currentStep}>
                                {steps}                      
                            </Stepper>
                        </div>
                    </div>
                    
                    <StepWizard>
                        {
                            lineas.map((linea, index, lineas) => {
                                
                                if( index == 0 && lineasAgrupadas.length > 0 ){
                                    return(
                                        <WizardCheckListStepAgrupado
                                            key={ index }
                                            lineasAgrupadas={ lineasAgrupadas }
                                            saveLineasAgrupadas={ saveLineasAgrupadas }                                            
                                            cabecera={ cabecera }
                                            paso={ index+1 }
                                            pasos={ lineas.length }
                                            saveCurrentStep={ saveCurrentStep }
                                            controles={ controles } 
                                            estadoForm={ estadoForm }
                                            confPantalla={ confPantalla }
                                            saveLoadReg={ saveLoadReg }
                                            propiedades={ propiedades }
                                            saveRegAnterior={ saveRegAnterior }
                                            loadingForms={ loadingForms }
                                            saveLoadingForms={ saveLoadingForms }
                                            adjuntosCabecera={ adjuntosCabecera }
                                            updateagrupados={ controles.wizardchecklist.updateagrupados } 
                                            nomEquipo={ nomEquipo }
                                        />
                                    )
                                }else{
                                    return(                                    
                                        <WizardCheckListStep                                    
                                            key={ index }
                                            cabecera={ cabecera }
                                            paso={  index+1  }
                                            pasos={ lineas.length }
                                            linea={ linea.linea }
                                            respuesta={ linea.respuesta }
                                            saveCurrentStep={ saveCurrentStep }
                                            controles={ controles } 
                                            estadoForm={ estadoForm }
                                            adjuntos={ adjuntos }
                                            adjuntosExistentes={ adjuntosExistentes }
                                            confPantalla={ confPantalla }
                                            saveLoadReg={ saveLoadReg }
                                            propiedades={ propiedades }
                                            regAnterior={ regAnterior }
                                            saveRegAnterior={ saveRegAnterior }
                                            loadingForms={ loadingForms }
                                            saveLoadingForms={ saveLoadingForms }
                                            adjuntosCabecera={ adjuntosCabecera }
                                            nomEquipo={ nomEquipo }
                                        />
                                    )
                                }                             
                            })
                        }                
                    </StepWizard>                    
                </Fragment>                
                :
                    trabajoSinLineas && loadedSteps && loadedHijosState
                    ?
                        <Fragment>
                            <h1 className="text-center">{controles.wizardchecklist.nolines.title}</h1>
                            <div className="float-right">
                                <ButtonOutline 
                                    clases={ "btn-trab" }
                                    color={ "primary" }  
                                    type="button" 
                                    icono={ "fal fa-arrow-right " }   
                                    id=""     
                                    texto={ "" }   
                                    accion={ noLinesNext } 
                                    disabled={ false }
                                />
                            </div>
                        </Fragment>
                    : 
                        <div className="text-center">
                            <Spinner animation="border" />
                        </div>
            }
        </div>
    )
}

export default WizardCheckList;