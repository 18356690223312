import React,{ createContext, useState, useEffect } from "react";

import Cookies from 'universal-cookie';
import axios from 'axios';
import swal from 'sweetalert';
import { alertService } from '../../services/alertservice';

// import { urlConf, webSocketServer } from '../../confApp';

import { getTreeJson, translateTreeJson, isArray, getApiUrl, helperDeleteCookiesLogin, helperSetCookiesLogin } from '../../helpers/helperApp';

import 'bootstrap/dist/css/bootstrap.min.css';

import { io } from 'socket.io-client';

export const LoginContext = createContext();


var socket = null;
var urlConf = null;
var webSocketServer = null;

export const LoginProvider = ({ children }) => {

    const cookies = new Cookies();

    // ***** Inicio states *****
    const [confApp, saveConfApp] = useState();

    const [form, saveForm] = useState({
        username: "",
        password: ""
    })
    const [formRegistro, saveFormRegistro] = useState({
        username: "",
        password: "",
        email: "",
        repetirPassword: ""
    })

    const [formResetPass, saveFormResetPass] = useState({
        email: ""
    });

    const [formChangePass, saveFormChangePass] = useState({
        pass : "",
        repetirPassword: ""
    });

    const [minMayus, saveMinMayus] = useState(
        {"cant" : "", "clase" : "errorValidacion", "icono" : "fal fa-times"},        
    )
    const [minMinus, saveMinMinus] = useState(
        {"cant" : "", "clase" : "errorValidacion", "icono" : "fal fa-times"}
    )    
    const [minNum, saveMinNum] = useState(
        {"cant" : "", "clase" : "errorValidacion", "icono" : "fal fa-times"}
    )
    const [loginGoogle, saveLoginGoogle] = useState("")
    const [logo, saveLogo] = useState("");
    const [recuperapass, saveRecuperapass] = useState("");
    const [registrarse, saveRegistrarse] = useState("");
    const [backgroundImg, saveBackgroundImg] = useState("");
    const [editUserName, saveEditUserName] = useState("");
    const [editMail, saveEditMail] = useState("");
    const [darkLightForm, saveDarkLightForm] = useState("");
    const [loadLogin, saveLoadLogin] = useState(false);
    const [activeMenuColor, saveActiveMenuColor] = useState("");
    const [sidebarColor, saveSidebarColor] = useState("");
    const [sidebarLogo, saveSidebarLogo] = useState("");
    const [concurrencia, saveConcurrencia] =  useState("");
    const [validacionesCargadas, saveValidacionesCargadas] = useState(false);
    const [noConfText, saveNoConfText] = useState("");
    const [menuMode, saveMenuMode] = useState(null);
    const [keyGA, saveKeyGa] = useState(null);
    const [googleClientId, saveGoogleClienteId] = useState(null);
    const [loginTxtColor, saveLoginTxtColor] = useState("");
    const [linkReadOnly, saveLinkReadOnly] = useState("");
    const [urlConfState, saveUrlConfState] = useState(urlConf);
    const [customMenu, saveCustomMenu] = useState("");
    const [reportWorkStatus, saveReportWorkStatus] = useState("");
    const [forceGlobalReload, saveForceGlobalReload] = useState(0); // Lo utilizamos cuando queremos recargar un componente tras la acción de otro. Ej. En Notifications cuando marcamos como visto una Alarma desde MiDataTableAlarmasMo

    // ***** Fin states *****

    useEffect(() => {

        // Liberamos la conexión al salir
        return () =>{
            if( socket != null ){
                socket.emit("desconectar", { "idusu": cookies.get('id'), "tabla": "undefined" })
                socket.disconnect();
            }
        } 
          
    }, []);


    // ***** Obtener las validaciones y configuraciones iniciales *****
    const getValidaciones = async () => {
        const url = `${getApiUrl(window.location.origin)}/login/getValidaciones`;
        const res = await axios.post(url);
        return res.data;
    }


    // ***** Hace la carga inicial de las validaciones o elimina el span si alguna cantidad es 0 *****
    const inicioValidaciones = (res) => {

        if( res.length > 0 ){  
            // Obtenemos primero la url del servidor de sockets ya que nos hace falta en el siguiente map si la concurrencia está activada
            const auxUrlServerSocket = res.filter(r => r.cod === "app.urlserversocket");
            webSocketServer = auxUrlServerSocket[0].val;
            
            res.map( r => {                
                        if( r.cod === "login.pass.mayus" ){
                            if( r.val === "0" ){
                                if( document.querySelector(".validacionPass span:nth-child(1)") ){
                                    document.querySelector(".validacionPass span:nth-child(1)").remove();
                                }
                            }else{
                                saveMinMayus({...minMayus, "cant": r.val })                                
                            }
                        }
                        if( r.cod === "login.pass.minus" ){
                            if( r.val === "0" ){
                                if( document.querySelector(".validacionPass span:nth-child(2)") ){
                                    document.querySelector(".validacionPass span:nth-child(2)").remove();
                                }
                            }else{
                                saveMinMinus({...minMinus, "cant": r.val })                                
                            }
                        }
                        if( r.cod === "login.pass.number" ){
                            if( r.val === "0" ){
                                if( document.querySelector(".validacionPass span:nth-child(3)") ){
                                    document.querySelector(".validacionPass span:nth-child(3)").remove();
                                }
                            }else{
                                saveMinNum({...minNum, "cant": r.val })
                            }
                        }
                        if( r.cod === "login.google" ){
                            saveLoginGoogle(r.val);
                        }

                        if( r.cod === "login.logo" ){
                            saveLogo(r.val);
                        }
                        if( r.cod === "login.recoverpass.allow" ){
                            saveRecuperapass(r.val);
                        }
                        if( r.cod === "login.register.allow" ){
                            saveRegistrarse(r.val);
                        }
                        if( r.cod === "login.background" ){
                            saveBackgroundImg(r.val);
                        }
                        if( r.cod === "login.profile.edit.name" ){
                            saveEditUserName(r.val);
                        }
                        if( r.cod === "login.profile.edit.email" ){
                            saveEditMail(r.val);
                        }
                        if( r.cod === "login.background.lightdark" ){
                            saveDarkLightForm(r.val);
                        }
                        if( r.cod === "dash.menu.activecolor" ){
                            saveActiveMenuColor(r.val);
                        }
                        if( r.cod === "dash.sidebar.color" ){
                            saveSidebarColor(r.val);
                        }
                        if( r.cod === "app.concurrencia" ){
                            saveConcurrencia(r.val);
                            if( r.val === "1" ){
                                // LOCAL
                                // const socketServer = getApiUrl(window.location.origin);
                                // const httpServerSocket = socketServer.replace('https', 'http')+":8080";
                                // socket = io(httpServerSocket,{ transports : ['websocket'] });
                                // socket.on("connect_error", (err) => {
                                //     console.log(`connect_error to serverSocket: ${err}. SERVER: ${httpServerSocket}:8080`);
                                // });
                                // socket.on('connect', () => {    
                                //     console.log('CONNECTED to the serverSocket:'+httpServerSocket+":8080")
                                // })
                                // ISSHOST
                                socket = io(webSocketServer,{ transports : ['websocket'], secure: true });
                                socket.on("connect_error", (err) => {
                                    console.log(`connect_error to serverSocket: ${err}. SERVER: ${webSocketServer}`);
                                });
                                socket.on('connect', () => {    
                                    console.log('CONNECTED to the serverSocket:'+webSocketServer)
                                })
                            }
                        }
                        if( r.cod === "app.noconftext"){
                            saveNoConfText(r.val);
                        }
                        if( r.cod === "dash.sidebar.logo"){
                            saveSidebarLogo(r.val);
                        }
                        if( r.cod === "menu.mode"){
                            saveMenuMode(r.val);
                        }
                        if( r.cod === "analytics.key"){
                            saveKeyGa(r.val);
                        }
                        if( r.cod === "login.google.clientid"){
                            saveGoogleClienteId(r.val);
                        }
                        if( r.cod === "login.txtcolor"){
                            saveLoginTxtColor(r.val);
                        }
                        if( r.cod === "auto.ot"){
                            saveLinkReadOnly(r.val);
                        }
                        if( r.cod === "app.urlconf"){
                            urlConf = r.val;
                            saveUrlConfState(r.val);
                        }
                        if( r.cod === "menu.custom" ){
                            saveCustomMenu(r.val)
                        }
                        if( r.cod === "report.work.status" ){
                            saveReportWorkStatus(r.val)
                        }                                                 
            } )
            saveValidacionesCargadas(true);                       
        }else{
            const rem = document.querySelector(".validacionPass");
            if(rem){
                rem.remove();
            }
        }
    }

    const iniciarSesionExt = async (data) => {
        const { paramId, paramEmail, paramNombre, paramSesion, paramNivel, paramEstado, paramIdioma, paramImgUser, paramIdAge, paramOrigenTabMA } = data;

        //Establecemos las cookies con los valores que recibimos por parámetro. Le establecemos el path por si queremos limitar la cookie a una parte en concreto del programa
        // cookies.set('id', atob(paramId), {path: '/'});
        // cookies.set('email', atob(paramEmail), {path: '/'});
        // cookies.set('nombre', atob(paramNombre), {path: '/'});
        // cookies.set('sesion', atob(paramSesion), {path: '/'});
        // cookies.set('nivel', atob(paramNivel), {path: '/'});
        // cookies.set('estado', atob(paramEstado), {path: '/'});
        // cookies.set('idioma', atob(paramIdioma).toLowerCase(), {path: '/'});
        // cookies.set('imguser', atob(paramImgUser).toLowerCase(), {path: '/'});
        // cookies.set('idage', atob(paramIdAge), {path: '/'});
        helperSetCookiesLogin({"id" : atob(paramId), "email":  atob(paramEmail), "nombre" : atob(paramNombre), "sesion" : atob(paramSesion), "nivel" : atob(paramNivel), "estado" : atob(paramEstado), "idioma" : atob(paramIdioma).toLowerCase(), "imguser" : atob(paramImgUser).toLowerCase(), "idage" : atob(paramIdAge)})

        // Si paramOrigenTabMA es igual a 0 quiere decir que hacemos login externo de alguien que no se logueó en el la tabla de login del maintenance. Ej.: Se logueó en el Monitoring hecho con el sistema antiguo. Con lo que no podemos comparar estos datos con los que hay en la bbdd del entorno Maintenance, tendremos que loguearlo.
        if( atob(paramOrigenTabMA) == "0" ){
            // Logueamos al usuario externo. Para ello actualizamos la sesion que recibimos(del monitoring antiguo por ejemplo) en la bbdd de login del maintenance
            const data = new FormData();
            data.append("id", atob(paramId));
            data.append("sesion", atob(paramSesion));
            const res = await axios.post(getApiUrl(window.location.origin)+"/login/setsesion", data);
        }
        
        //Tras establecer las cookies las validamos con la bbdd
        validateSesion(data);

    }

    const iniciarSesion = async (e, googleEmail=null, returnUser=false) => {
        
        let iniciar, password, errorValidacion;
        
        let iniciarMacros= []; 
        let usuarioMacros;


        if( e ){
            e.preventDefault();
            password = e.target[1].value;
            errorValidacion = validaPassword(password);
        }

        if( !errorValidacion || googleEmail ){

            const data = new FormData();
            
            if( googleEmail ){
                data.append("googleEmail", googleEmail);
            }else{
                data.append("username", e.target[0].value);
                data.append("password", e.target[1].value);                
            }
            await axios.post(getApiUrl(window.location.origin)+"/login/comprueba", data)
            .then( response => {
                return response.data;
            }).then( response=>{            
                response = JSON.parse(response);
                if( typeof response != "string" ){
                        // cookies.set('id', response.id, {path: '/'});
                        // cookies.set('email', response.email, {path: '/'});
                        // cookies.set('nombre', response.nombre, {path: '/'});
                        // cookies.set('sesion', response.sesion, {path: '/'});  
                        // cookies.set('nivel', response.nivel, {path: '/'});  
                        // cookies.set('estado', response.estado, {path: '/'});
                        // cookies.set('idioma', response.idioma.toLowerCase(), {path: '/'});
                        // cookies.set('imguser', response.imguser, {path: '/'});  
                        // cookies.set('idage', response.idage, {path: '/'});
                        helperSetCookiesLogin(response);

                        usuarioMacros = response;

                        iniciar = true;                      
                }else{
                    if( !returnUser ){
                        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${response}`, {autoClose: true});
                    }
                    iniciar = false;    
                }
            })
            .catch( error => {
                console.log(error);
                iniciar = false;    
            })
        }else{
            if( !returnUser ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
            }
             iniciar = false; 
        }
            
        if( returnUser ){
            iniciarMacros["iniciar"] = iniciar;
            iniciarMacros["usuario"] = usuarioMacros;
            return iniciarMacros;
        }else{
            saveLoadLogin(false);   
            return iniciar;
        }

    }

    // ***** Valida el password con la configuración recibida de la bbdd y cambia los estilos a los span del formulario *****
    const validaPassword = (value) => {
        let totMayus = 0;
        let totMinus = 0;
        let totNums = 0;
        let error = false;
        for(let i = 0; i < value.length; i++ ){        
            if( value.charAt(i) >= '0' && value.charAt(i) <= '9' ){
                totNums++;
            }else{
                if( value.charAt(i) >= 'A' && value.charAt(i) <= 'Z' ){
                    if( value.charAt(i) === value.charAt(i).toUpperCase() ){
                        totMayus++;
                    }
                }
                if( value.charAt(i) >= 'a' && value.charAt(i) <= 'z' ){
                    if( value.charAt(i) === value.charAt(i).toLowerCase() ){
                        totMinus++;
                    }
                }
            }
        }
        if( totMayus >= minMayus["cant"] ){
            saveMinMayus({...minMayus, "clase": "succesValidacion", "icono": "fal fa-check" })
        }else{
            saveMinMayus({...minMayus, "clase": "errorValidacion", "icono": "fal fa-times" })
            error = `La contraseña debe tener ${minMayus["cant"]} mayúscula`;
        }
        if( totMinus >= minMayus["cant"] ){
            saveMinMinus({...minMinus, "clase": "succesValidacion", "icono": "fal fa-check" })
        }else{
            saveMinMinus({...minMinus, "clase": "errorValidacion", "icono": "fal fa-times" })
            error = `La contraseña debe tener ${minMayus["cant"]} minúscula`;
        }
        if( totNums >= minNum["cant"] ){
            saveMinNum({...minNum, "clase": "succesValidacion", "icono": "fal fa-check" })
        }else{
            saveMinNum({...minNum, "clase": "errorValidacion", "icono": "fal fa-times" })
            error = `La contraseña debe tener ${minNum["cant"]} número`;
        }
        
        return error;
    }


    // ***** Obtenemos la sesión de la bbdd y la comparamos con la que tenemos en nuestra cookie. Si no coinciden o no hay cookie id cerramos sesión *****
    // ***** Validamos que el usuario tenga nivel para entrar en la pantalla sino lo enviamos a inicio *****
    const validateSesion = async (props) => {
        const cookies = new Cookies();
        const res = await getSesion();
        const permiso = await tienePermiso(res[0].idusu, props.location.pathname);

        // Actualizamos las cookies 
        if( res[0]["nivel"] ){
            cookies.set('nivel', res[0]["nivel"], {path: '/'});
        }
        if( res[0]["nombre"] ){
            cookies.set('nombre', res[0]["nombre"], {path: '/'});
        }
        if( res[0]["email"] ){
            cookies.set('email', res[0]["email"], {path: '/'});
        }
        if( res[0]["idioma"] ){
            cookies.set('idioma', res[0]["idioma"].toLowerCase(), {path: '/'});
        }

        cookies.set('imguser', res[0]["imguser"], {path: '/'});

        if( !cookies.get('id') || res[0]["sesion"] !== cookies.get('sesion') ){
            // alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.errorsesion.title}`, {autoClose: true});
            if( confApp && cookies.get('id') ){
                // Si no existe confApp es porque intentamos acceder a una url directamente sin estar logueado con lo que no mostramos ningún mensaje, solo ejecutamos cerraSesion. Si si existe confApp es porque accedimos desde otro dispositivo y mostramos ese mensaje y cerramosSesion
                // Comprobamos también que exista la cookie para que no muestre el mensaje de que se "inició sesión en otro dispositivo con este usuario" si lo que hicimos fue cerrar sesión en una pestaña y teníamos otra abierta e intentamos navegar por esta última.              
                swal({
                    title: "",
                    text: confApp.config.trad.login.errorsesion.title,
                    icon: "error",
                    buttons: {
                        confirm : {text: 'Ok', className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                    } 
                })
            }

            cerrarSesion(props);
            return null;          
        }else{

            // Volvemos a cargar todas las validaciones sino estan cargadas
            if( !validacionesCargadas ){
                const respuesta = await getValidaciones();
                await inicioValidaciones(respuesta);        
            }

        }




        return permiso;
    }

    // ***** Función a la que le pasamos el id de un usuario y la pantalla y hace la comprobación de si tiene permiso(sistema de permisos de permisosusu) para entrar *****
    const tienePermiso = async (idusu, pantalla) => {
        const data = new FormData();
        data.append("idusu", idusu);
        data.append("pantalla", pantalla);
        const url = `${getApiUrl(window.location.origin)}/app/tienepermiso`;
        const res = await axios.post(url, data);

        return res.data;
    }

    // ***** Función que carga la configuración general de la aplicación *****
    const getConfApp = async () => {
        const cookies = new Cookies();

        const data = new FormData();
        data.append("key", "key");
        data.append("url", document.domain);
        data.append("nfo", "json");
        data.append("file", ""); // Hacer por opcion de configuración esta opción. Si va en blanco descarga cfg.json si se pone x descargaría x.json
        if( urlConf != null ){
            const res = await axios.post(urlConf, data);
    
            if( res.data.res.toLowerCase() === "ko" ){
                saveConfApp("ko");
                return res.data;
            }
            res.data.msg = JSON.parse(res.data.msg)
            let traducir = [];
            if( res.data.msg.config.trad.active === true ){
                // Obtenemos los textos a traducir
                const tra = await getTreeJson(res.data.msg, res.data.msg.config.trad.keystrad, traducir);
                // Llamamos a la rutina que nos devolverá las traducciones
                const data = new FormData();
                data.append("tra", JSON.stringify(tra));            
                if( cookies.get('id') ){
                    data.append("id", cookies.get('id'));
                }
                const url = `${getApiUrl(window.location.origin)}/app/getTra`;
                const traducciones = await axios.post(url, data);
                // res.data.msg = await translateTreeJson(res.data.msg, traducciones.data);
                res.data.msg = await translateTreeJson(res.data.msg, traducciones.data, res.data.msg.config.trad.keystrad);
            }
            saveConfApp(res.data.msg);
            return res.data;
        }
    }

    // ***** Nos devuelve la traducción de un texto que le pasamos *****
    const getTra = async (txt) => {
            let tra = [];
            tra.push(txt);
            const data = new FormData();
            data.append("tra", JSON.stringify(tra));            
            const url = `${getApiUrl(window.location.origin)}/app/getTra`;
            const traduccion = await axios.post(url, data);
            return traduccion.data[0].tra;
    }

    // ***** Función que elimina las cookies de la sesión actual *****
    const cerrarSesion = async (props) => {
        const cookies = new Cookies();

        if( socket != null ){
            socket.emit("cerrarSesion", { "url": window.location.origin, "idusu": cookies.get('id') })
        }

        // Enviamos mensaje al log
        const data = new FormData();
        if( confApp ){
            // Sino existe confApp no enviamos al login, esto quiere decir que estamos intentando acceder a una url directamente sin estar logueado, con lo cual no se envía nada al login
            data.append("validacion", apiKey());
            data.append("mensaje", confApp.config.trad.login.cerrarsesionlog.title)
            const url = `${getApiUrl(window.location.origin)}/app/log`;
            const res = await axios.post(url, data);
        }

        helperDeleteCookiesLogin(); 

        // cookies.remove('id', {path: '/'});
        // cookies.remove('email', {path: '/'});
        // cookies.remove('nombre', {path: '/'});
        // cookies.remove('sesion', {path: '/'});
        // cookies.remove('nivel', {path: '/'});
        // cookies.remove('estado', {path: '/'});
        // cookies.remove('idioma', {path: '/'});
        // cookies.remove('imguser', {path: '/'});
        // cookies.remove('idage', {path: '/'});
        // cookies.remove('intervalo', {path: '/'});
        // cookies.remove('kpiIni', {path: '/'});
        // cookies.remove('kpiFin', {path: '/'});
        // cookies.remove('defaultActiveKey', {path: '/'});
        // cookies.remove('defaultActiveKeyCheckList', {path: '/'});


        if(props.props){
            props.props.history.push('/');
        }else{
            props.history.push('/');
        }
    }

    // ***** Obtenemos la sesión guardada en la bbdd para comprobar que es la misma que está en nuesta cookie *****  
    // ***** Además nos traemos el nivel de usuario para actualizarlo en caso de que haya cambiado y poder ver los cambios en la aplicación según los niveles ***** 
    const getSesion = async () => {    

        const cookies = new Cookies();

        const data = new FormData();
        const idUsu = cookies.get('id');
        data.append("id", idUsu);
        const validacion = apiKey();
        data.append("validacion", validacion);
        const url = `${getApiUrl(window.location.origin)}/login/getSesion`;
        const res = await axios.post(url, data);
        if( isArray(res.data) ){
            res.data[0]["idusu"] = idUsu;
        }
        return res.data;
    }

    // ***** JSON que se envía en todas las llamadas a la API ya que ésta comprueba que nuestra sesion e id coincidan con las de la bbdd antes de devolver cualquier resultado *****
    const apiKey = () => {
        const cookies = new Cookies();

        const idSesion = cookies.get('sesion');
        const sesion = cookies.get('id');
        const validacion = {"sesion":idSesion, "id":sesion};
        
        return JSON.stringify(validacion);
    }

    // ***** Función que comprueba si un numbre de usuario ya está ocupado por otro usuario*****
    const checkUser = async (nomUser, idReg) => {

        const data = new FormData();
        data.append("user", nomUser);
        if( idReg !== "" ){
            data.append("id", idReg);
        }
        const url = `${getApiUrl(window.location.origin)}/login/chkUser`;
        const res = await axios.post(url, data);
        return res.data;
    }

    // ***** Función que comprueba si un email ya está ocupado por otro usuario*****
    const checkEmail = async (email, idReg) => {

        const data = new FormData();
        data.append("email", email);
        if( idReg !== "" ){
            data.append("id", idReg);
        }
        const url = `${getApiUrl(window.location.origin)}/login/chkemail`;
        const res = await axios.post(url, data);
        return res.data;
    }

    // ***** Función que comprueba si la concurrencia en los registros está activada o desactivada *****
    const checkConcurencia = async () => { 

        const res = await axios.post(`${getApiUrl(window.location.origin)}/login/chkconcurrencia`);
        return res.data;
    }

    return(
        <LoginContext.Provider value={{
            form,
            saveForm,
            formRegistro,
            saveFormRegistro,
            minMayus, 
            saveMinMayus,
            minMinus, 
            saveMinMinus,
            minNum,
            saveMinNum,
            cookies, 
            getValidaciones, 
            inicioValidaciones,
            iniciarSesionExt,
            iniciarSesion,
            validaPassword, 
            validateSesion,
            cerrarSesion,
            getSesion,
            apiKey,
            loginGoogle, 
            saveLoginGoogle,
            logo,
            recuperapass, 
            saveRecuperapass,
            registrarse, 
            saveRegistrarse,
            checkUser,
            checkEmail,
            formResetPass, 
            saveFormResetPass,
            formChangePass, 
            saveFormChangePass,
            backgroundImg,
            saveBackgroundImg,
            editUserName, 
            saveEditUserName,
            editMail, 
            saveEditMail,
            darkLightForm, 
            saveDarkLightForm,
            loadLogin, 
            saveLoadLogin,
            activeMenuColor, 
            saveActiveMenuColor,
            sidebarColor, 
            saveSidebarColor,
            socket,
            confApp, 
            saveConfApp,
            concurrencia, 
            saveConcurrencia,
            checkConcurencia,
            getConfApp,
            validacionesCargadas, 
            saveValidacionesCargadas,
            getTra,
            noConfText, 
            saveNoConfText, 
            sidebarLogo,
            saveSidebarLogo,
            menuMode, 
            saveMenuMode,
            keyGA, 
            saveKeyGa,
            googleClientId, 
            saveGoogleClienteId,
            loginTxtColor, 
            saveLoginTxtColor,
            linkReadOnly, 
            saveLinkReadOnly,
            customMenu, 
            saveCustomMenu,
            reportWorkStatus, 
            saveReportWorkStatus,
            forceGlobalReload, 
            saveForceGlobalReload
        }} >
            { children }
        </LoginContext.Provider>
    )
} 