import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { LoginContext } from '../../hooks/context/LoginContext';

import { alertService } from '../../services/alertservice';

import Section from '../ui/Section';
import MiDataTable from '../ui/MiDataTable';
import swal from 'sweetalert';
import Kpi from '../ui/Kpi';

import { getApiUrl, axiosPost } from '../../helpers/helperApp';

function InicioTrab(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const{ trabajador, traduction, propiedades } = props;

    const { apiKey } = useContext(LoginContext);

    const [ loadingTable, saveLoadingTable] = useState(true);
    const [ datosTabableOts, saveDatosTableOts ] = useState([]);

    useEffect( () => {        
                        
        return () =>{               
            document.querySelector("body").classList.remove("sidebar-open");
            
            // Token cancelación axios al desmontar componentes
            source.cancel();    
        } 
        
    },[])

    const checkOt = (e, rowData) => {
        
        const aux = trabajador.controles.ots;        
        switch (rowData.style) {
            case "alert-warning":
            case "alert-primary":
            case "":
                    // Pregunta trabajador: Iniciar trabajo (NOM1 DEL SELECT) -> SI/NO -> LA PREGUNTA SE HACE:
                    swal({
                        title: aux.iniciar.swal.title,
                        text: rowData.descripcion,
                        content: {
                            element : "p",
                            attributes : {
                                innerHTML : aux.iniciar.swal.responsabilidad.title
                            }
                        },
                        icon: "warning",
                        buttons: {
                            cancel : {text: aux.iniciar.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.iniciar.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                            
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            if( rowData.id_tra != "0" ){
                                // Si tiene trabajo lanzamos el asistente                                
                                propiedades.history.push("/documentostrab/"+rowData.id_tra); 
                            }else if( rowData.id_tra = "0" ){ 
                                // Si es una OT y aun no tiene trabajo, creamos el trabajo y lanzamos el asistente con el id que recibimos tras crear el trabajo
                                const res = createDoc(rowData.id, aux.createtrab, aux.swalerror.title, aux.swalerror.text.title, aux.swalerror.btncancel.title);                                                            
                            }                            
                        }
                    })
                break
            case "alert-danger":
            case "alert-success":
                // DANGER CLICK -> NADA DE NADA(MENSAJE "NO se puede EDITAR este TRABAJO")
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${trabajador.controles.ots.revision.title}`, {autoClose: true});
            break
        }        
    }
    
    const createDoc = async (id, url, titleError, textError, btnCancelError) => {

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", id )
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+url, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+url, data, source.token);

        if( typeof respuesta.data == "object" ){
            // Ver el trabajo
            propiedades.history.push("/documentostrab/"+respuesta.data.idClon); 
           
        }else if( typeof respuesta.data == "string" ){
            swal({
                title: titleError,
                text: textError,
                icon: "error",
                buttons: {
                    cancel : {text: btnCancelError, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                } 
            })
        }

    }

    return(
        <Fragment>
            <Kpi
                conf={ trabajador.controles.kpi }
                getDataTable1 = { trabajador.controles.ots.datatable.getdata }
                saveDataTable1 = { saveDatosTableOts }
                saveLoadingTable = { saveLoadingTable }
            />
            <Section titulo={ trabajador.controles.ots.section.titulo } icono={ trabajador.controles.ots.section.icono } col={ trabajador.controles.ots.section.col } wiki={ trabajador.controles.ots.section.wiki }>
                {
                    datosTabableOts != null
                    ?
                        <Fragment>
                            <div className="row" >
                                <div className="col-12">
                                    <div className="row justify-content-md-center"> 
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-primary alertHelp" role="alert">
                                                {trabajador.controles.ots.help.primary.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-warning alertHelp" role="alert">
                                                {trabajador.controles.ots.help.warning.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-danger alertHelp" role="alert">
                                                {trabajador.controles.ots.help.danger.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-light alertHelp" role="alert" style={{borderColor: "#888888"}}>
                                                {trabajador.controles.ots.help.light.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-success alertHelp" role="alert">
                                                {trabajador.controles.ots.help.success.title}
                                            </div>
                                        </div>
                                    </div>
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"otsTrabajador"}
                                    columnas={trabajador.controles.ots.datatable.columns}
                                    data={datosTabableOts}
                                    linkColumns={trabajador.controles.ots.datatable.linkColumns}
                                    buttons={trabajador.controles.ots.datatable.buttons}
                                    ordering={trabajador.controles.ots.datatable.ordering}
                                    order={trabajador.controles.ots.datatable.order}
                                    searching={trabajador.controles.ots.datatable.searching}
                                    paging={trabajador.controles.ots.datatable.paging}
                                    select={trabajador.controles.ots.datatable.select}
                                    pagelength={trabajador.controles.ots.datatable.pagelength}
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={trabajador.controles.ots.datatable.columnactions.title}
                                    traduction={traduction.datatable}
                                    functionClickTd={ checkOt }
                                />                          

                                </div>
                            </div>
                        </Fragment>
                    :
                        null
                }
            </Section>            
        </Fragment>
    )
}

export default InicioTrab;