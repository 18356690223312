import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { LoginContext } from '../../hooks/context/LoginContext';

import Kpi from '../ui/Kpi';
import Section from '../ui/Section';
import MiDataTable from '../ui/MiDataTable';
import CalendarioYear from '../ui/CalendarioYear';
import swal from 'sweetalert';
import { Accordion, Card } from "react-bootstrap";
import MiSelect from '../formulario/MiSelect';
import Modal from 'react-bootstrap/Modal';
import FormInput from '../formulario/FormInput';
import Informes from '../formulario/informes/Informes';
import Button from '../ui/Button';
import DataTable from 'datatables.net';
import $ from 'jquery';
import Spinner from 'react-bootstrap/Spinner';


import { alertService } from '../../services/alertservice';

import "bootstrap/dist/css/bootstrap.min.css";

import { getApiUrl, getLinkReadOnly, getAhorayyyymmdd, axiosPost } from '../../helpers/helperApp';

function InicioEncargado(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    const{ encargado, traduction, propiedades, confPantalla } = props;

    const { apiKey, linkReadOnly } = useContext(LoginContext);

    const [ loadingTable, saveLoadingTable] = useState(true)

    const [ datosTablePlantillas, saveDatosTablePlantillas ] = useState([]);
    const [ valCalendarPlantillas, saveValCalendarPlantillas ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]);
    const [ acordeonPrin, saveAcordeonPrin ] = useState(null);
    const [ acordeonCalPlantillas, saveAcordeonCalPlantillas ] = useState(null);
    const [ acordeonCalTrabajos, saveAcordeonCalTrabajos ] = useState(null);

    const [ datosTableTrabajos, saveDatosTableTrabajos ] = useState([]);
    const [ valCalendarTrabajos, saveValCalendarTrabajos ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]);
    const [ plantillasCorrectivos, savePlantillasCorrectivos ] = useState([]);
    const [ loadingPlantillasCorrectivos, saveLoadingPlantillasCorrectivos ] = useState(false);
    const [ reloadKpi, saveReloadKpi ] = useState(0);

    const [ valFec1, saveValFec1 ] = useState(getAhorayyyymmdd());
    const [ valTrabajador, saveValTrabajador ] = useState(cookies.get('idage'));
    const [ descripcionDocu, saveDescripcionDocu ] = useState("")
    // Tabla mantenimientos
    const [ idMantenimientos, saveIdMantenimientos ] = useState(0);
    const [ idOriMantenimientos, saveIdOriMantenimientos ] = useState(0);
        // Modal Mantenimientos
        const [ showModalMatenimientosPendientes, saveShowModalMantenimientosPendientes ] = useState(false);
    // Tabla mantenimientos correctivos
    const [ idMantenimientosCorrectivos, saveIdMantenimientosCorrectivos ] = useState(0);
        // Modal Mantenimientos correctivos
        const [ showModalMatenimientosCorrectivos, saveShowModalMantenimientosCorrectivos ] = useState(false);

    const[ isPlanPreventivo, saveIsPlanPreventivo] = useState(false);

    const[ checkear, saveCheckear ] = useState(false);
    const[ checkeando, saveCheckeando ] = useState(false);
    const[ errorCheckeando, saveErrorCheckeando ] = useState(false);
    const[ checkCentro, saveCheckCentro ] = useState(null);
    const[ checkCentroNom, saveCheckCentroNom ] = useState(null);
    const[ checkEstado, saveCheckEstado ] = useState(null);
    const[ checkEstadoNom, saveCheckEstadoNom ] = useState(null);
    const[ checkFecha, saveCheckFecha ] = useState(null);
    const[ checkFechaNom, saveCheckFechaNom ] = useState(null);
    const[ checkRealizado, saveCheckRealizado ] = useState(null);
    const[ checkRealizadoNom, saveCheckRealizadoNom ] = useState(null);

    useEffect( () => {                

        if( cookies.get('defaultActiveKey') ){
            toogleAcordeon(cookies.get('defaultActiveKey'), "acordeonPrin")
        }  

        loadPlantillasCorrectivos();
        
        return () =>{               
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
            source3.cancel();
        } 
        
    },[])  

    const handleFec1 = (e) => {
        const { name, value } = e.target;
        saveValFec1(value);    
    }
    const handleSelectTrabajador = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveValTrabajador(selectedItem.value);  
        }else{
            saveValTrabajador(0);
        }

    }

    const checkOt = async (e, rowData) => {

        // Reseteamos el control de fecha y ponemos por defecto el agente asociado al usuario que está logueado
        saveValFec1(getAhorayyyymmdd());  
        saveValTrabajador(cookies.get('idage'));

        const aux = encargado.controles.plantillas;

        let style, id, id_ori, descripcion, recurrencia, estado, idest;
        if( rowData ){
            style = rowData.style;
            id = rowData.id;
            id_ori = rowData.id_ori;
            descripcion = rowData.equipo+" - "+rowData.descripcion;
            recurrencia = rowData.recurrencia;
            estado = rowData.estado;
            idest = rowData.idest; 
        }else{
            style = e.target.dataset.style;
            id = e.target.dataset.iddoc;
            id_ori = e.target.dataset.id_ori;
            descripcion = e.target.dataset.title;
            recurrencia = e.target.dataset.recurrencia;
            estado = e.target.dataset.estado;
            idest = e.target.dataset.idest;  
        }

        saveIdMantenimientos(id);
        saveIdOriMantenimientos(id_ori);
        saveDescripcionDocu(descripcion);

        switch (style) {
            case "alert-secondary":
            case "alert-danger":
            case "alert-success":
            case "":
                    // PLANTILLA. Acciones. VER PLANTILLA(id) Y CREAR OT
                    saveShowModalMantenimientosPendientes(true);
                    break                          
        }        
    }

    const actionsModalMantenimientosPendientes = (action) => {
        
        switch(action) {
            case 0:
                    // Cancelar
                    saveShowModalMantenimientosPendientes(false);             
              break;
            case 1:
                    // Creamos la ot
                    createDoc(idMantenimientos, encargado.controles.plantillas.plantilla.createot, encargado.controles.plantillas.plantilla.swalerror.text.title, false, encargado.controles.plantillas.plantilla.createsuccess.title);                    
              break;
            case 2:
                    // Ver plantilla en solo lectura
                    window.open("/documentosenc/"+idMantenimientos, "_blank");
              break;             
          }
    }



    const checkTrabajo = async (e, rowData) => {

        const aux = encargado.controles.trabajos;

        let style, id, tip_ori, id_tra, descripcion, pregunta, recurrencia, estado, idest;

        if( rowData ){
            style = rowData.style;
            id = rowData.id;
            tip_ori = rowData.tip_ori;
            id_tra = rowData.id_tra;
            descripcion = rowData.equipo+" - "+rowData.descripcion;
            recurrencia = rowData.recurrencia;
            estado = rowData.estado;
            idest = rowData.idest;                 
        }else{
            style = e.target.dataset.style;
            id = e.target.dataset.iddoc;
            tip_ori = e.target.dataset.tip_ori;
            id_tra = e.target.dataset.id_tra;
            descripcion = e.target.dataset.title;
            recurrencia = e.target.dataset.recurrencia;
            estado = e.target.dataset.estado;
            idest = e.target.dataset.idest; 
        }

        switch (style) {
            case "alert-dark":
                // MOSTRAR MENSAJE. DOCUMENTO ES ESTADO INCORRECTO. CONTACTE CON SOPORTE TECNICO.
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${aux.docestincorrecto.title}`, {autoClose: true});
                break
            case "":
            case "alert-secondary":
                    // case gris -> OT SIN TRABAJO -> ACCIONES -> VER OT(id) Y CREAR TRABAJO
                    swal({
                        title: aux.otsintrabajo.swal.title,
                        text: aux.otsintrabajo.swal.text.title,
                        content: {
                            element : "div",
                            attributes : {
                                innerHTML : "<h6>"+descripcion+"</h6><br>"+aux.trabajo.swal.text.responsabilidad.title,
                                style : "max-width:fit-content"
                            }
                        },
                        icon: "warning",
                        buttons: {
                            cancel : {text: aux.otsintrabajo.swal.btncancel.title, className:'btn btn-flat btn-outline-secondary', visible: true},
                            confirm : {text: aux.otsintrabajo.swal.btnconfirm.title, className:'btn btn-flat btn-outline-success btnSwalSuccess'},
                            crearyejecutar :  {text: aux.otsintrabajo.swal.btncrearyejecutar.title, className:'btn btn-flat btn-outline-success btnSwalSuccess'},
                            verot : {text: aux.otsintrabajo.swal.btnverot.title, className:'btn btn-flat btn-outline-warning btnSwalWarning'}
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            // Crear el trabajo
                            createDoc(id, aux.otsintrabajo.createot, aux.otsintrabajo.swalerror.text.title, false, aux.otsintrabajo.createsuccess.title);
                        }else if( confirmacion == "crearyejecutar" ){
                            // Crear el trabajo y ejecutar
                            createDoc(id, aux.otsintrabajo.createot, aux.otsintrabajo.swalerror.text.title, true);                                               
                        }else if( confirmacion == "verot" ){
                            // Ver la ot y 
                            // propiedades.history.push("/documentosenc/"+id+getLinkReadOnly(linkReadOnly), "_blank");                    
                            propiedades.history.push("/documentosenc/"+id, "_blank");                    
                        }
                    })
                break
            case "alert-success":                 
                    // case success -> TRABAJOS CORRECTIVOS/TRABAJOS PREVENTIVOS en estado REALIZADO
                    if( style == "alert-success" ){
                        if( tip_ori == 'P' ){
                            pregunta = aux.trabajo.swal.text.trabrealizado.preventivo.title;
                        }else if( tip_ori == 'C' ){
                            pregunta = aux.trabajo.swal.text.trabrealizado.correctivo.title;
                        }                        
                    }
                    swal({
                        title: aux.trabajo.swal.title,
                        text: pregunta,
                        content: {
                            element : "div",
                            attributes : {
                                innerHTML : "<h6>"+descripcion+"</h6><br>"+aux.trabajo.swal.text.responsabilidad.title,
                                style : "max-width:fit-content"
                            }
                        },
                        icon: "warning",
                        buttons: {
                            cancel : {text: aux.trabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.trabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                            verot : {text: aux.trabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            // Ver trabajo
                            // propiedades.history.push("/documentosenc/"+id_tra+getLinkReadOnly(linkReadOnly), "_blank");    
                            propiedades.history.push("/documentosenc/"+id_tra, "_blank");    
                        }else if( confirmacion == "verot" ){
                            // Ver la ot
                            // propiedades.history.push("/documentosenc/"+id+getLinkReadOnly(linkReadOnly), "_blank");                    
                            propiedades.history.push("/documentosenc/"+id, "_blank");                    
                        }
                    })
                break
            case "alert-primary":
            case "alert-warning":
            case "alert-danger":
                    // case primary -> TRABAJOS PREVENTIVOS -> ACCIONES -> VER TRABAJO(id_tra) Y VER OT(id)
                    // case warning -> TRABAJOS CORRECTIVOS -> ACCIONES -> VER TRABAJO(id_tra) Y VER OT(id)
                    // case danger -> TRABAJOS A REVISAR -> ACCIONES -> VER TRABAJO(id_tra) Y VER OT(id)
                    let buttons;
                    if(idest == 9){
                        buttons = {
                            cancel : {text: aux.trabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.trabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},                           
                            verot : {text: aux.trabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }else{
                        buttons = {
                            cancel : {text: aux.trabajo.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.trabajo.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                            // MOSTRAR ESTE BOTÓN SOLO SI EL ESTADO ES DISTINTO DE REALIZADO. HACERLO TAMBIÉN EN EL ADMINISTRADOR
                            btnejecutar : {text: aux.trabajo.swal.btnejecutar.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                            verot : {text: aux.trabajo.swal.btnverot.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                        } 
                    }
                    if( style == "alert-primary" ){
                        pregunta = aux.trabajo.swal.text.trabpreventivo.title;
                    }else if( style == "alert-warning" ){
                        pregunta = aux.trabajo.swal.text.trabcorrectivo.title;
                    }else if( style == "alert-danger" ){
                        pregunta = aux.trabajo.swal.text.trabrevision.title;
                    }
                    swal({
                        title: aux.trabajo.swal.title,
                        text: pregunta,
                        content: {
                            element : "div",
                            attributes : {
                                innerHTML : "<h6>"+descripcion+"</h6><br>"+aux.trabajo.swal.text.responsabilidad.title,
                                style : "max-width:fit-content"
                            }
                        },
                        icon: "warning",
                        buttons: buttons,
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            // Ver trabajo
                            // propiedades.history.push("/documentosenc/"+id_tra+getLinkReadOnly(linkReadOnly), "_blank");    
                            propiedades.history.push("/documentosenc/"+id_tra, "_blank");    
                        }else if( confirmacion == "verot" ){
                            // Ver la ot
                            // propiedades.history.push("/documentosenc/"+id+getLinkReadOnly(linkReadOnly), "_blank");                    
                            propiedades.history.push("/documentosenc/"+id, "_blank");                    
                        }else if( confirmacion == "btnejecutar" ){
                            // Ejecutar el wizard
                            propiedades.history.push("/documentostrab/"+id_tra); 
                        }
                    })
                break                 
        }
       
    }


    const createDoc = async(idplantilla, url, textError, goToTrabajo=false, txtConfirmacion="") => {

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idplantilla );
        data.append("fec1", valFec1 );
        data.append("idage", valTrabajador );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+url, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+url, data, source.token);

        if( typeof respuesta.data.errorot == "string" ){
            // Ya existe una ot
            alertService.error(`<a href='${window.location.origin}/documentosenc/${respuesta.data.idot}'>${respuesta.data.errorot}</a>`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            if( goToTrabajo ){
                // Crear trabajo y ejecutar
                // Así lo mantenemos en la misma pestaña y no habría que actualizar la tabla
                // propiedades.history.push("/documentosenc/"+respuesta.data.idClon); 
                // Así ejecutamos el wizard
                propiedades.history.push("/documentostrab/"+respuesta.data.idClon); 
    
                // Así abrimos la ot en otra pestaña y recargamos la tabla para que se actualice el campo que indica si hay ot abierta
                // window.open("/documentosenc/"+respuesta.data.idClon, "_blank");
                // loadDatosTable();   
            }else{
                // Crear trabajo/ot depende desde donde se llame              
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${txtConfirmacion}`, {autoClose: true});
                saveReloadKpi(reloadKpi => reloadKpi+1)
            }
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${textError}`, {autoClose: false});
        }

        saveShowModalMantenimientosPendientes(false);
        saveShowModalMantenimientosCorrectivos(false);

    }

    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){
            case "acordeonPrin":
                if( acordeonPrin == id ){
                    saveAcordeonPrin(null);
                    cookies.set('defaultActiveKey', null, {path: '/'});
                }else{
                    saveAcordeonPrin(id);
                    cookies.set('defaultActiveKey', id, {path: '/'});
                }                
                break;
            case "acordeonCalPlantillas":
                if( acordeonCalPlantillas == id ){
                    saveAcordeonCalPlantillas(null);
                    cookies.set('defaultActiveKey', null, {path: '/'});
                }else{
                    saveAcordeonCalPlantillas(id);
                    cookies.set('defaultActiveKey', id, {path: '/'});
                }                
                break;
            case "acordeonCalTrabajos":
                if( acordeonCalTrabajos == id ){
                    saveAcordeonCalTrabajos(null);
                    cookies.set('defaultActiveKey', null, {path: '/'});
                }else{
                    saveAcordeonCalTrabajos(id);
                    cookies.set('defaultActiveKey', id, {path: '/'});
                }                
                break;
        }
    }

    const clickCorrectivo = async (e, rowData) => {

        saveCheckear(false);
        saveErrorCheckeando(false);

        // Reseteamos el control de fecha y ponemos por defecto el agente asociado al usuario que está logueado
        saveValFec1(getAhorayyyymmdd());  
        saveValTrabajador(cookies.get('idage'));

        if( rowData.idtip == 10 ){
            saveIsPlanPreventivo(true);
        }else{
            saveIsPlanPreventivo(false);
        }

        saveIdMantenimientosCorrectivos(rowData.id);
        saveDescripcionDocu(rowData.equipo+" - "+rowData.descripcion);    
        saveShowModalMantenimientosCorrectivos(true);
    }

    const actionsModalMantenimientosCorrectivos = (action) => {
        switch(action) {
            case 0:
                    // Cancelar
                    saveShowModalMantenimientosCorrectivos(false);              
              break;
            case 1:
                    // Creamos el correctivo
                    createCorrectivo(idMantenimientosCorrectivos);
              break;
            case 2:
                    // Creamos el preventivo
                    createDoc(idMantenimientosCorrectivos, encargado.controles.plantillas.plantilla.createot, encargado.controles.plantillas.plantilla.swalerror.text.title, false, encargado.controles.plantillas.plantilla.createsuccess.title);
              break;
            case 3:
                    // Ver plantilla en solo lectura
                    window.open("/documentosenc/"+idMantenimientosCorrectivos, "_blank");
              break;
            case 4:
                // Chekear documento
                checkearDocumento();
              break;              
          }
    }

    const checkearDocumento = async () => {
        saveCheckear(false);
        saveErrorCheckeando(false);
        saveCheckeando(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idMantenimientosCorrectivos );
        data.append("fecini", cookies.get('kpiIni') );
        data.append("fecfin", cookies.get('kpiFin') );
        data.append("idage", valTrabajador );        
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+encargado.controles.createcorrectivo.checkear.getdata, data, source4.token);
        
        saveCheckeando(false);

        if( typeof respuesta.data[0] == "string" ){
            saveErrorCheckeando(respuesta.data);
        }else{
            saveCheckear(true);
            saveCheckCentro(respuesta.data[0].centro);
            saveCheckCentroNom(respuesta.data[0].centronom)
            saveCheckEstado(respuesta.data[0].estado);
            saveCheckEstadoNom(respuesta.data[0].estadonom);
            saveCheckFecha(respuesta.data[0].fecha);
            saveCheckFechaNom(respuesta.data[0].fechanom);
            saveCheckRealizado(respuesta.data[0].realizado);
            saveCheckRealizadoNom(respuesta.data[0].realizadonom);
        }


    }

    const createCorrectivo = async (id) => {

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", id );
        data.append("fec1", valFec1 );
        data.append("idage", valTrabajador );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+encargado.controles.createcorrectivo.createcorrectivo, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+encargado.controles.createcorrectivo.createcorrectivo, data, source2.token);

        if( typeof respuesta.data.errorot == "string" ){
            // Ya existe una ot
            alertService.error(`<a href='${window.location.origin}/documentosenc/${respuesta.data.idot}'>${respuesta.data.errorot}</a>`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            // Así lo mantenemos en la misma pestaña y no habría que actualizar la tabla
            propiedades.history.push("/documentosenc/"+respuesta.data.idClon); 
    
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${encargado.controles.createcorrectivo.swalerror.text.title}`, {autoClose: false});             
        }
        saveShowModalMantenimientosCorrectivos(false);
    }

    const loadPlantillasCorrectivos = async() => {

        saveLoadingPlantillasCorrectivos(true);
        const data = new FormData();
        data.append("validacion", apiKey());       
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+encargado.controles.createcorrectivo.datatable.getdata, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+encargado.controles.createcorrectivo.datatable.getdata, data, source3.token);

        savePlantillasCorrectivos(respuesta.data);
        saveLoadingPlantillasCorrectivos(false);

    }

    const setValuesKpis = (saveValue) => {
        let tableMantenimientos = $('#plantillasEncargado').DataTable();
        let tableTrabajos = $('#trabajosEncargado').DataTable();
        let exceden = 0;
        let faltan = 0;
        let otros = 0;
        let trabVerdes = 0;
        let trabNoVerdes = 0;
                
        tableMantenimientos.rows().data().map(row => {
            if(row.style == "alert-danger") exceden++;
            if(row.style == "alert-success") faltan++;            
            if(row.style == "alert-secondary") otros++; 
        })

        tableTrabajos.rows().data().map(row => {
            // if(row.style == "alert-success") trabVerdes++;            
            // if(row.style != "alert-success") trabNoVerdes++;
            if(row.idest == "9") trabVerdes++;            
            if(row.idest != "9") trabNoVerdes++;
        })

        // saveValue([
        //             tableMantenimientos.rows().count(),
        //             <Fragment><span className='text-danger'>{exceden}</span>/<span className='text-success'>{faltan}</span>/<span>{otros}</span></Fragment>,
        //             <Fragment><span className='text-success'>{trabVerdes}</span>/<span>{trabNoVerdes}</span></Fragment>
        //         ]);
        saveValue([
                    tableMantenimientos.rows().count(),
                    <Fragment>{exceden}/{faltan}/{otros}</Fragment>,
                    <Fragment>{trabVerdes}/{trabNoVerdes}</Fragment>
        ]);
    }

    const verCalMan = () => {
        const acordeon = document.getElementById("accordMant");
        if( !acordeon.classList.contains('activeAcordeon') ){
            acordeon.click();
        }
        
        const acordeonCal = document.getElementById("accordMantCal");
        if( !acordeonCal.classList.contains('activeAcordeon') ){
            acordeonCal.click();
        }        
        setTimeout(()=>{
            acordeonCal.scrollIntoView({block: "start", behavior: "smooth"});              
        }, 500)               
    }

    return(
        <Fragment>     
            <Section titulo={encargado.controles.section.title} icono={encargado.controles.section.icono}>
                <Kpi
                    conf={ encargado.controles.kpi }
                    getDataTable1 = { encargado.controles.plantillas.datatable.getdata }
                    saveCalendar1 = { saveValCalendarPlantillas }
                    saveDataTable1 = { saveDatosTablePlantillas }
                    getDataTable2 = { encargado.controles.trabajos.datatable.getdata }
                    saveCalendar2 = { saveValCalendarTrabajos}
                    saveDataTable2 = { saveDatosTableTrabajos }
                    saveLoadingTable = { saveLoadingTable }
                    forceReload={ reloadKpi }
                    customValueAction={ setValuesKpis }
                    accionesLinkKpi ={[verCalMan]}
                />
                <Accordion defaultActiveKey={ cookies.get('defaultActiveKey') ? cookies.get('defaultActiveKey') : ""  } >
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" id="accordMant" onClick={() => toogleAcordeon('0', "acordeonPrin")} className={ acordeonPrin == "0" ? "activeAcordeon" : "" }>
                            <i className={ encargado.controles.plantillas.section.icono } ></i> { encargado.controles.plantillas.section.titulo }
                            {
                                acordeonPrin == "0"
                                ?
                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                :
                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                            }
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0" className={"slowAcordeon acordeonMant"} >
                            <Card.Body>
                                {/* <Section titulo={ encargado.controles.plantillas.section.titulo } icono={ encargado.controles.plantillas.section.icono } col={ encargado.controles.plantillas.section.col } wiki={ encargado.controles.plantillas.section.wiki }> */}
                                    {
                                        datosTablePlantillas != null
                                        ?
                                            <Fragment>
                                                <div className="row" >
                                                    <div className="col-12">
                                                        {/* <div className="row"> 
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="alert alert-primary alertHelp" role="alert">
                                                                    {encargado.controles.plantillas.help.primary.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="alert alert-warning alertHelp" role="alert">
                                                                    {encargado.controles.plantillas.help.warning.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-4">
                                                                <div className="alert alert-light alertHelp" role="alert" style={{borderColor: "#888888"}}>
                                                                    {encargado.controles.plantillas.help.light.title}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    <MiDataTable 
                                                        estadoForm= { "" }
                                                        formulario={ {id:0} }
                                                        id={"plantillasEncargado"}
                                                        columnas={encargado.controles.plantillas.datatable.columns}
                                                        data={datosTablePlantillas}
                                                        linkColumns={encargado.controles.plantillas.datatable.linkColumns}
                                                        buttons={encargado.controles.plantillas.datatable.buttons}
                                                        ordering={encargado.controles.plantillas.datatable.ordering}
                                                        order={encargado.controles.plantillas.datatable.order}
                                                        searching={encargado.controles.plantillas.datatable.searching}
                                                        paging={encargado.controles.plantillas.datatable.paging}
                                                        select={encargado.controles.plantillas.datatable.select}
                                                        pagelength={encargado.controles.plantillas.datatable.pagelength}
                                                        // saveLoadingTable={ saveLoadingTable }
                                                        loadingTable={loadingTable}
                                                        columnactions={encargado.controles.plantillas.datatable.columnactions.title}
                                                        traduction={traduction.datatable}
                                                        functionClickTd={ checkOt }
                                                    />                          

                                                    </div>
                                                    <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                                        <Accordion /*defaultActiveKey="0"*/ >
                                                            <Card>
                                                                <Accordion.Toggle as={Card.Header} id="accordMantCal" eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalPlantillas")} className={ acordeonCalPlantillas == "0" ? "activeAcordeon" : "" } >
                                                                    <i className={ encargado.controles.plantillas.calendario.acordeon.icono } ></i> { encargado.controles.plantillas.calendario.acordeon.title }
                                                                    {
                                                                        acordeonCalPlantillas == "0"
                                                                        ?
                                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                                        :
                                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card>
                                                            <Accordion.Collapse eventKey="0" className={"slowAcordeon acordeonMantCal"} >
                                                                <Card.Body>
                                                                    <CalendarioYear 
                                                                        data = { valCalendarPlantillas }
                                                                        locale = { cookies.get('idioma') }
                                                                        onDayClick={ true }
                                                                        colorTitleEvent={ true }
                                                                        functionClickTitle = { checkOt }
                                                                    />
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }
                                {/* </Section> */}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" id="accordTrabj" onClick={() => toogleAcordeon('1', "acordeonPrin")} className={ acordeonPrin == "1" ? "activeAcordeon" : "" } >
                           <i className={ encargado.controles.trabajos.section.icono } ></i> { encargado.controles.trabajos.section.titulo }
                           {
                                acordeonPrin == "1"
                                ?
                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                :
                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                            }
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1" className={"slowAcordeon acordeonTrab"}>
                            <Card.Body>
                                {/* <Section titulo={ encargado.controles.trabajos.section.titulo } icono={ encargado.controles.trabajos.section.icono } col={ encargado.controles.trabajos.section.col } wiki={ encargado.controles.trabajos.section.wiki }> */}
                                    {
                                        datosTableTrabajos != null
                                        ?
                                            <Fragment>
                                                <div className="row" >
                                                    <div className="col-12">
                                                        <div className="row justify-content-md-center"> 
                                                            <div className="col-md-12 col-lg-2">
                                                                <div className="alert alert-primary alertHelp" role="alert">
                                                                    {encargado.controles.trabajos.help.primary.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-2">
                                                                <div className="alert alert-warning alertHelp" role="alert">
                                                                    {encargado.controles.trabajos.help.warning.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-2">
                                                                <div className="alert alert-danger alertHelp" role="alert">
                                                                    {encargado.controles.trabajos.help.danger.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-2">
                                                                {/* <div className="alert alert-light alertHelp" role="alert" style={{borderColor: "#888888"}}> */}
                                                                <div className="alert alert-secondary alertHelp" role="alert" >
                                                                    {encargado.controles.trabajos.help.light.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-2">
                                                                <div className="alert alert-success alertHelp" role="alert">
                                                                    {encargado.controles.trabajos.help.success.title}
                                                                </div>
                                                            </div>                                                       
                                                        </div>
                                                    <MiDataTable 
                                                        estadoForm= { "" }
                                                        formulario={ {id:0} }
                                                        id={"trabajosEncargado"}
                                                        columnas={encargado.controles.trabajos.datatable.columns}
                                                        data={datosTableTrabajos}
                                                        linkColumns={encargado.controles.trabajos.datatable.linkColumns}
                                                        buttons={encargado.controles.trabajos.datatable.buttons}
                                                        ordering={encargado.controles.trabajos.datatable.ordering}
                                                        order={encargado.controles.trabajos.datatable.order}
                                                        searching={encargado.controles.trabajos.datatable.searching}
                                                        paging={encargado.controles.trabajos.datatable.paging}
                                                        select={encargado.controles.trabajos.datatable.select}
                                                        pagelength={encargado.controles.trabajos.datatable.pagelength}
                                                        // saveLoadingTable={ saveLoadingTable }
                                                        loadingTable={loadingTable}
                                                        columnactions={encargado.controles.trabajos.datatable.columnactions.title}
                                                        traduction={traduction.datatable}
                                                        functionClickTd={ checkTrabajo }
                                                    />                          

                                                    </div>
                                                    <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                                        <Accordion /*defaultActiveKey="0"*/ >
                                                            <Card>
                                                                <Accordion.Toggle as={Card.Header} id="accordTrabjCal" eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalTrabajos")}  className={ acordeonCalTrabajos == "0" ? "activeAcordeon" : "" } >
                                                                    <i className={ encargado.controles.trabajos.calendario.acordeon.icono } ></i> { encargado.controles.trabajos.calendario.acordeon.title }
                                                                    {
                                                                        acordeonCalTrabajos == "0"
                                                                        ?
                                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                                        :
                                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                                    }                                                                  
                                                                </Accordion.Toggle>
                                                            </Card>
                                                            <Accordion.Collapse eventKey="0" className={"slowAcordeon acordeonTrabCal"}>
                                                                <Card.Body>
                                                                    <CalendarioYear 
                                                                        data = { valCalendarTrabajos }
                                                                        locale = { cookies.get('idioma') }
                                                                        onDayClick={ true }
                                                                        colorTitleEvent={ true }
                                                                        functionClickTitle = { checkTrabajo }
                                                                    />
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </div>                                                    
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }
                                {/* </Section> */}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>   
            </Section>
            <Section titulo={encargado.controles.createcorrectivo.section.title} icono={encargado.controles.createcorrectivo.section.icono} >
                <MiDataTable 
                    estadoForm= { "" }
                    formulario={ {id:0} }
                    id={"createcorrectivo"}
                    columnas={encargado.controles.createcorrectivo.datatable.columns}
                    data={plantillasCorrectivos}
                    linkColumns={encargado.controles.createcorrectivo.datatable.linkColumns}
                    buttons={encargado.controles.createcorrectivo.datatable.buttons}
                    ordering={encargado.controles.createcorrectivo.datatable.ordering}
                    order={encargado.controles.createcorrectivo.datatable.order}
                    searching={encargado.controles.createcorrectivo.datatable.searching}
                    paging={encargado.controles.createcorrectivo.datatable.paging}
                    select={encargado.controles.createcorrectivo.datatable.select}
                    pagelength={encargado.controles.createcorrectivo.datatable.pagelength}
                    saveLoadingTable={ saveLoadingPlantillasCorrectivos }
                    loadingTable={ loadingPlantillasCorrectivos }
                    columnactions={encargado.controles.createcorrectivo.datatable.columnactions.title}
                    traduction={traduction.datatable}
                    functionClickTd={ clickCorrectivo }
                />    
            </Section>                     
            {
            showModalMatenimientosPendientes
            ?
                <Modal
                    size="lg"
                    show={true}
                    onHide={ () => saveShowModalMantenimientosPendientes(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}
                    contentClassName="likeSw"
                    backdropClassName="backLikeSw"                                              
                >                          
                    <Modal.Body>
                        <div className="swal-icon swal-icon--warning">
                            <span className="swal-icon--warning__body">
                            <span className="swal-icon--warning__dot"></span>
                            </span>
                        </div>                        
                        <div className="swal-text">{encargado.controles.plantillas.plantilla.swal.text.title}</div>
                        <h6 className='text-center'>{descripcionDocu}</h6>
                        <MiSelect  
                            estadoForm=  { "" }
                            val=         { valTrabajador }   
                            loading=     { false }
                            id=          { confPantalla.documentosenc.padre.controles.idage2.id }    
                            name=        { confPantalla.documentosenc.padre.controles.idage2.name }   
                            label=       { confPantalla.documentosenc.padre.controles.idage2.label }    
                            placeholder= { confPantalla.documentosenc.padre.controles.idage2.placeholder }
                            col=         { 12 } 
                            isClearable= { confPantalla.documentosenc.padre.controles.idage2.isclearable }
                            isSearchable={ confPantalla.documentosenc.padre.controles.idage2.issearchable }
                            dataType=    { confPantalla.documentosenc.padre.controles.idage2.datatype }
                            data=        { confPantalla.documentosenc.padre.controles.idage2.data }
                            required=    { false } 
                            disabled=    { false }
                            readOnly =   { false }
                            disablethisid={false}
                            disableids=   {false}
                            formulario= { {id:0} }   
                            change= { handleSelectTrabajador }
                        />
                        <FormInput                                                        
                            estadoForm= { "" }
                            value=      { valFec1 }
                            loading=    { false }
                            id=         { confPantalla.documentosenc.padre.controles.fec1.id }    
                            type=       { "date" }  
                            name=       { confPantalla.documentosenc.padre.controles.fec1.name }   
                            label=      { confPantalla.documentosenc.padre.controles.fec1.label }                                                       
                            placeholder={ confPantalla.documentosenc.padre.controles.fec1.placeholder }    
                            col=        { "12" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { handleFec1 }
                            onBlur=     {null} 
                        />
                        <div className='form-group col-md-12'>
                            <Informes
                                select =     { encargado.controles.plantillas.informes.select }
                                formulario = { {id: idMantenimientos} }
                                getRegistro = { encargado.controles.plantillas.informes.getregistro }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnGenerar= { encargado.controles.plantillas.informes.btngenerar.title }
                            />
                        </div>
                        <div className='text-right'>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosPendientes(0) }                                        
                            >
                                {encargado.controles.plantillas.plantilla.swal.btncancel.title}
                            </button>
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ valTrabajador == "0" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosPendientes(1) }
                            >
                                {encargado.controles.plantillas.plantilla.swal.btnconfirm.title}
                            </button>
                            <button 
                                className="swal-button swal-button--verplant btn btn-flat mr-1 mt-1 btn-outline-warning btnSwalWarning"
                                onClick={ e => actionsModalMantenimientosPendientes(2) }
                            >
                                {encargado.controles.plantillas.plantilla.swal.btnverplant.title}
                            </button>                                                                                          
                        </div>
                    </Modal.Body>                            
                </Modal>                
            :
                null
        }
        {/* ********* MODAL PARA CLICK EN LA TABLA MANTENIMIENTOS CORRECTIVOS ********* */}
        {
            showModalMatenimientosCorrectivos
            ?
                <Modal
                    size="lg"
                    show={true}
                    onHide={ () => saveShowModalMantenimientosCorrectivos(false)} 
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}
                    contentClassName="likeSw"
                    backdropClassName="backLikeSw"   
                >                          
                    <Modal.Body>
                        <div className="swal-icon swal-icon--warning">
                            <span className="swal-icon--warning__body">
                            <span className="swal-icon--warning__dot"></span>
                            </span>
                        </div>                        
                        <div className="swal-text">{encargado.controles.createcorrectivo.swal.text.title}</div>
                        <h6 className='text-center'>{descripcionDocu}</h6>
                        <MiSelect  
                            estadoForm=  { "" }
                            val=         { valTrabajador }   
                            loading=     { false }
                            id=          { confPantalla.documentosenc.padre.controles.idage2.id }    
                            name=        { confPantalla.documentosenc.padre.controles.idage2.name }   
                            label=       { confPantalla.documentosenc.padre.controles.idage2.label }    
                            placeholder= { confPantalla.documentosenc.padre.controles.idage2.placeholder }
                            col=         { 12 } 
                            isClearable= { confPantalla.documentosenc.padre.controles.idage2.isclearable }
                            isSearchable={ confPantalla.documentosenc.padre.controles.idage2.issearchable }
                            dataType=    { confPantalla.documentosenc.padre.controles.idage2.datatype }
                            data=        { confPantalla.documentosenc.padre.controles.idage2.data }
                            required=    { false } 
                            disabled=    { false }
                            readOnly =   { false }
                            disablethisid={false}
                            disableids=   {false}
                            formulario= { {id:0} }   
                            change= { handleSelectTrabajador }
                        />
                        <FormInput                                                        
                            estadoForm= { "" }
                            value=      { valFec1 }
                            loading=    { false }
                            id=         { confPantalla.documentosenc.padre.controles.fec1.id }    
                            type=       { "date" }  
                            name=       { confPantalla.documentosenc.padre.controles.fec1.name }   
                            label=      { confPantalla.documentosenc.padre.controles.fec1.label }                                                       
                            placeholder={ confPantalla.documentosenc.padre.controles.fec1.placeholder }    
                            col=        { "12" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { handleFec1 }
                            onBlur=     {null} 
                        />
                        <div className='form-group col-md-12'>
                            <Informes
                                select =     { encargado.controles.createcorrectivo.informes.select }
                                formulario = { {id: idMantenimientos} }
                                getRegistro = { encargado.controles.createcorrectivo.informes.getregistro }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnGenerar= { encargado.controles.createcorrectivo.informes.btngenerar.title }
                            />
                        </div>
                        <div className='text-right'>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosCorrectivos(0) }                                        
                            >
                                {encargado.controles.createcorrectivo.swal.btncancel.title}
                            </button>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosCorrectivos(4) }
                            >
                                {encargado.controles.createcorrectivo.swal.comprobar.title}
                            </button>
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ valTrabajador == "0" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosCorrectivos(1) }
                            >
                                {encargado.controles.createcorrectivo.swal.btnconfirm.title}
                            </button>
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ !isPlanPreventivo || valTrabajador == "0" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosCorrectivos(2) }
                            >
                                {encargado.controles.createcorrectivo.swal.btncreateprev.title}
                            </button>
                            <button 
                                className="swal-button swal-button--verplant btn btn-flat mr-1 mt-1 btn-outline-warning btnSwalWarning"
                                onClick={ e => actionsModalMantenimientosCorrectivos(3) }
                            >
                                {encargado.controles.createcorrectivo.swal.btnverplant.title}
                            </button>
                            {
                                checkeando == true
                                ?
                                    <div className='mt-2 alert alert-primary'  role='alert' style={{display: "flex", justifyContent: "space-around"}}>
                                        <Spinner animation="border" />                                    
                                    </div>
                                :
                                    null
                            }
                            {
                                checkear == true
                                ?
                                    <div className='mt-2 alert alert-primary text-left'  role='alert' /*style={{display: "flex", justifyContent: "space-around"}}*/ >
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkEstado == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkEstado == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {encargado.controles.createcorrectivo.checkear.estado.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkEstadoNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkFecha == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkFecha == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {encargado.controles.createcorrectivo.checkear.fecha.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkFechaNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkCentro == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkCentro == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            } 
                                            {encargado.controles.createcorrectivo.checkear.centro.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkCentroNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkRealizado == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkRealizado == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {encargado.controles.createcorrectivo.checkear.realizado.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkRealizadoNom}</span>
                                            </p>
                                        </div>
                                    </div>                                                                                          
                                :
                                    null
                            }
                              {
                                errorCheckeando != false
                                ?
                                    <div className='mt-2 alert alert-danger'  role='alert' style={{display: "flex", justifyContent: "space-around"}}>
                                        <div>
                                            <h5>{errorCheckeando}</h5>
                                        </div>                                     
                                    </div>                                                                                          
                                :
                                    null
                            }                                                                                          
                        </div>
                    </Modal.Body>                            
                </Modal>                
            :
                null
        }
        </Fragment>
    )
}

export default InicioEncargado;