import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../helpers/FixRequiredSelect";
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import { MiReplaceAll, getApiUrl, axiosPost } from '../../helpers/helperApp';


let auxOptions; 

function MiSelect(props){

    const cookies = new Cookies();
    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    // const source2 = CancelToken.source();
    // const source3 = CancelToken.source();

    const [loadingSelect, saveLoadingSelect] = useState(false);
    const [options, saveOptions] = useState([]);

    const { apiKey, confApp } = useContext(LoginContext); 

    const animatedComponents = makeAnimated();

    const { estadoForm, val, loading, id, name, label, placeholder, col, isClearable, isSearchable, dataType, data, extradata, required, disabled, readOnly, change, modifyOptions, disablethisid, formulario, disableids, bbdd } = props;
    let { depends } = props;
    
    if( depends == null || depends == "" ){
        depends = {control : 0}        
    }

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor:'#ced4da',
            borderColor: state.isFocused ? '#80bdff': '#ced4da',
            outline: 0,
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
            background: readOnly === "readonly" || loading || estadoForm === "readonly" ? "#e9ecef" : "white",
            color: "#495057",
          }),
        //   option: (provided, state) => ({
        //     ...provided,
        //     color: "#212529",
        //   }),
          singleValue: (provided) => ({
            ...provided,
            color: "#495057",
          }),
          menuPortal: (provided) => ({
            ...provided,            
            zIndex: 9999
          }) 
    }

    useEffect( () => {

                saveLoadingSelect(true);
                                       
                getDataCbo(dataType, data);

                saveLoadingSelect(false); 
                
                return () =>{    
                    // Token cancelación axios al desmontar componentes
                    // source.cancel();
                    // source2.cancel();
                    // source3.cancel();
                } 

    },[loadingSelect, loading, formulario.id, formulario[depends.control], data])


    const getDataCbo = async (fuente, opcion) => {

        let savedOptions = false;
        if( fuente === "codigo" ){
            if( typeof opcion === "string" ){
                if( opcion.includes("´") ){
                    const search = '´';
                    const replaceWith = '"';
                    opcion = opcion.split(search).join(replaceWith);
                }
                opcion = JSON.parse(opcion);
            }
            saveOptions(opcion); 
        }else{
            const data = new FormData();
            const validacion = apiKey();
            data.append("validacion", validacion);
            if( opcion.includes("usu_actual") ){
                opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
            }
            if( bbdd ){
                data.append("bbdd", bbdd);
            }
            data.append(fuente, opcion);

            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }

            const respuesta = await axios.post(url, data);
            // const respuesta = await axiosPost(url, data, source.token);
            
            // Datos añadidos manualmente en el json
            if( extradata ){                
                respuesta.data = respuesta.data.concat(extradata);
            }    
    
            // Si está activada la opción para que desactive el option que tenga el mismo id que el del registro del formulario lo recorremos y le añadimos la opción de deshabilitarlo
            if( disablethisid ){
                respuesta.data.map((option) => {               
                    if(option.value == formulario.id){
                        option["isDisabled"] = true;
                    }
                })

                saveOptions(respuesta.data);
                savedOptions = true;
            }

            // Si queremos desactivar algún id por el motivo que sea le pasamos un controller y función a la que llamar y que nos devuelva los ids que queremos desactivar. 
            // Ej.: /controller/funcion
            if( disableids != null &&  disableids != "" ){
                const data = new FormData();
                const validacion = apiKey();
                data.append("validacion", validacion);
                data.append("idreg", formulario.id);
                const idsDisable = await axios.post(getApiUrl(window.location.origin)+disableids,data);
                // const idsDisable = await axiosPost(getApiUrl(window.location.origin)+disableids, data, source2.token);
                respuesta.data.map((option) => {               
                    if( idsDisable.data.includes(option.value) ){
                        option["isDisabled"] = true;
                    }
                })

                saveOptions(respuesta.data);
                savedOptions = true;
            }

            // Si queremos filtrar las opciones en función del valor de otro control.
            if( depends.control != 0 ){
                const data = new FormData();
                const validacion = apiKey();
                data.append("validacion", validacion);
                data.append("value", formulario[depends.control]);
                const respuesta = await axios.post(getApiUrl(window.location.origin)+depends.action,data);
                // const respuesta = await axiosPost(getApiUrl(window.location.origin)+depends.action, data, source3.token);

                saveOptions(respuesta.data);
                savedOptions = true;
            }

            if( !savedOptions ){
                saveOptions(respuesta.data);                
            }
        }
    }          
      
    const Select = props => (
        <FixRequiredSelect
          {...props}
          SelectComponent={BaseSelect}
        //   options={props.options || options}
        />
    );

    if( modifyOptions ){    
        auxOptions = modifyOptions(options);       
    }

      return(        
            modifyOptions
            ?
                <div className={"form-group col-md-"+col}>          
                        <label htmlFor={id}>{label}</label>
                        <Select 
                                // Este select de ubicaciones del modal de dividir o añadir materiales del wizard de materiales
                                options={auxOptions}
                                name={name}             
                                id={id}
                                placeholder={ placeholder } 
                                // value={options.filter(option => option.value == val)}
                                value={auxOptions.length > 0 ? auxOptions.filter(option => option.value == val) : null}
                                onChange={ change }
                                required={required}
                                // isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" || readOnly ? true :  disabled}
                                isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" ? true :  disabled}
                                // isClearable={isClearable}
                                isClearable={readOnly === "readonly" ? false : isClearable}
                                isSearchable={isSearchable}
                                isMulti={false}
                                isLoading={loading || loadingSelect ? true : false}
                                components={animatedComponents}    
                                noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title } 
                                styles={customStyles}   
                                menuIsOpen={readOnly === "readonly" || estadoForm === "readonly" || loading || loadingSelect ? false : undefined}
                                isReadOnly={readOnly}
                                menuPortalTarget={document.body}                                              
                                menuPlacement={"auto"}
                        />                                     
                </div>
            :
                <div className={"form-group col-md-"+col}>          
                        <label htmlFor={id}>{label}</label>
                        <Select 
                                options={options}
                                name={name}             
                                id={id}
                                placeholder={ placeholder } 
                                // value={options.filter(option => option.value == val)}
                                value={options.length > 0 ? options.filter(option => option.value == val) : null}
                                onChange={ change }
                                required={required}
                                // isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" || readOnly ? true :  disabled}
                                isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" ? true :  disabled}
                                // isClearable={isClearable}
                                isClearable={readOnly === "readonly" ? false : isClearable}
                                isSearchable={isSearchable}
                                isMulti={false}
                                isLoading={loading || loadingSelect ? true : false}
                                components={animatedComponents}    
                                noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title } 
                                styles={customStyles}   
                                menuIsOpen={readOnly === "readonly" || estadoForm === "readonly" || loading || loadingSelect ? false : undefined}
                                isReadOnly={readOnly}
                                menuPortalTarget={document.body}                                              
                                menuPlacement={"auto"}
                        />            
                </div>       
    )
}

export default MiSelect;