import React, { useEffect, Fragment } from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner';

import { getIconoTable, dateSqlToLocal, getApiUrl } from '../../../helpers/helperApp';


require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowgroup')

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();

var botones = [
    {
        extend: 'pageLength',
        text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        // titleAttr: 'Ver número de registros'
    },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    {
        extend: 'colvis',
        text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
    }
];

function MiDataTableUplmInfo(props){


    // ***** props *****
    const { id, columnas, data, columnactions, traduction } = props;
    let { buttons, ordering, order, searching, paging, select, grouping, pagelength, loading } = props;
    
    useEffect(()=>{

        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);


        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        select === undefined ? select = false : select = select;
        grouping === undefined ? grouping = false : grouping = grouping;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;

    
        if( columnactions != null && columnactions != "null" && columnactions != "" ){
            columnas.push({      
                title: columnactions,
                data: null,
                className: 'text-center',
                sortable: false,
                width: "5%", 
                createdCell : (td, cellData, rowData, row, col) => {
                    if( rowData.permiso == "false" ){
                        // Ocultamos los row de los ficheros de los que no tenemos permiso para ver
                        $(td).parent().css("display", "none")
                    }else{
                        if( rowData.type == undefined ){
                            let templateBtn =  ReactDOM.render(<div className="flex-center">                                                         
                                                <a className="btn btn-flat success btnLink"  href={rowData.rut} target="_blank" >
                                                    <i className="fal fa-link " data-id={rowData.id} data-rut={rowData.rut} style={{ color: "inherit" }}></i>                                                            
                                                </a>                                                             
                                            </div>
                                        ,td);
                        $(td).html(templateBtn)
                        }else{
                            let templateBtn =  ReactDOM.render(<div className="flex-center">                                                         
                                                        <a className="btn btn-flat success btnDownload"  href={getApiUrl(window.location.origin)+rowData.rut} target="_blank" download={getApiUrl(window.location.origin)+rowData.rut} >
                                                            <i className="fal fa-download " data-id={rowData.id} data-rut={getApiUrl(window.location.origin)+rowData.rut} style={{ color: "inherit" }}></i>                                                            
                                                        </a>                                                             
                                                    </div>
                                                ,td);
                            $(td).html(templateBtn)
                        }  
                    }
                }
            });
        }

        columnas.map((columna, i) => {
            if( i == 1 ){
                // La foto/icono
                columna.createdCell = (td, cellData, rowData, row, col) => {
                    if( rowData.type == undefined ){
                        // Adjunto tipo url
                        let template = `
                            <div class="text-center">
                                <a href="${rowData.rut}" target="_blank" >
                                    <i class="fab fa-chrome fa-2x" ></i>
                                </a>                                                                                                                                        
                            </div>                     
                        `;
                        $(td).html(template);
                    }else{
                        if( rowData.type.split("/")[0] !== "image" ){
                            let ext = rowData.rut.split(".")[rowData.rut.split(".").length-1];
                            let confIco = getIconoTable(ext);
                            let template = `
                                <div class="text-center">
                                    <a href=${getApiUrl(window.location.origin)+rowData.rut} target="_blank" download=${getApiUrl(window.location.origin)+rowData.rut} >
                                        <i class="${confIco["icono"]}" style="color:${confIco["color"]}"></i>
                                    </a>                                                                                                                                        
                                </div>                     
                            `;
                            $(td).html(template);
                        }else if( rowData.type.split("/")[0] == "image" ){
                            let templateFoto = `
                                <div>                
                                    <div class="containerImgTable">
                                        <img class="imgUplmTable" src=${getApiUrl(window.location.origin)+cellData} />
                                    </div>
                                </div>
                            `;
                            $(td).html(templateFoto);
    
                        }                                                            
                    }                 
                }
            }            
        })





        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({                      
                        mark: true,
                        columnDefs: [
                            { "type": "html-input", "targets": [3, 4] }
                        ], 
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },                     
                        drawCallback : function ( settings ) {
                            var api = this.api();
                            var rows = api.rows( {page:'current'} ).nodes();
                            var last=null;
                            var data = api.rows().data();

                            if( grouping ){
                                api.column(0, {page:'current'} ).data().each( function ( group, i ) {

                                    if ( last !== group ) {
                                        const nombreGrupo = data.filter((option) => {
                                            if( option.idtip == group ){
                                                return option.tipo;
                                            }
                                        })
                                        if( nombreGrupo[0] ){
                                            $(rows).eq( i ).before(
                                                '<tr class="group"><td colspan="6" style="background-Color: rgba(0,0,0,.05)"><h6>'+nombreGrupo[0].tipo+'</h6></td></tr>'
                                            );
                                        }
                     
                                        last = group;
                                    }
                                } );                            
                            }
                        }
                    })                    
                }  
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{    
                    $("."+id).DataTable({                      
                        mark: true,
                        columnDefs: [
                            { "type": "html-input", "targets": [3, 4] }
                        ], 
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },                     
                        drawCallback : function ( settings ) {
                            var api = this.api();
                            var rows = api.rows( {page:'current'} ).nodes();
                            var last=null;
                            var data = api.rows().data();

                            if( grouping ){
                                api.column(0, {page:'current'} ).data().each( function ( group, i ) {

                                    if ( last !== group ) {
                                        const nombreGrupo = data.filter((option) => {
                                            if( option.idtip == group ){
                                                return option.tipo;
                                            }
                                        })
                                        if( nombreGrupo[0] ){
                                            $(rows).eq( i ).before(
                                                '<tr class="group"><td colspan="6" style="background-Color: rgba(0,0,0,.05)"><h6>'+nombreGrupo[0].tipo+'</h6></td></tr>'
                                            );
                                        }
                     
                                        last = group;
                                    }
                                } );                            
                            }
                        }
                    })                                 
                }
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({                      
                    mark: true,
                    columnDefs: [
                        { "type": "html-input", "targets": [3, 4] }
                    ], 
                    rowId: function(a) {
                        return '#row-' + a.id;},
                    dom: buttons,
                    data: data,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }        
                    },                     
                    drawCallback : function ( settings ) {
                        var api = this.api();
                        var rows = api.rows( {page:'current'} ).nodes();
                        var last=null;
                        var data = api.rows().data();

                        if( grouping ){
                            api.column(0, {page:'current'} ).data().each( function ( group, i ) {

                                if ( last !== group ) {
                                    const nombreGrupo = data.filter((option) => {
                                        if( option.idtip == group ){
                                            return option.tipo;
                                        }
                                    })
                                    if( nombreGrupo[0] ){
                                        $(rows).eq( i ).before(
                                            '<tr class="group"><td colspan="6" style="background-Color: rgba(0,0,0,.05)"><h6>'+nombreGrupo[0].tipo+'</h6></td></tr>'
                                        );
                                    }
                 
                                    last = group;
                                }
                            } );                            
                        }
                    }
                })    
            }
        }

    }, [data])
      

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;

    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas


    return(
        <Fragment>
            {   loading ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            <div className="mb-2" style={{overflowX: "auto"}}>
                <table id={id} className={id+" table table-sm table-bordered table-hover dataTable"} style={{width:"100%"}}>                            
                </table>
            </div>   
                        
        </Fragment>
    )
}

export default MiDataTableUplmInfo;