import React from 'react';

import { SRLWrapper } from "simple-react-lightbox";

import MiDataTableUplmPlusList from './MiDataTableUplmPlusList';


function UplmPlusList(props){
    
    const { id, adjuntosExistentes, saveAdjuntosExistentes, readonly, estadoForm, traduction, datatable, optionSelect } = props; 

    const _URL = window.URL || window.webkitURL;

    return(
            <SRLWrapper>
                    {
                        adjuntosExistentes
                        ?
                            <MiDataTableUplmPlusList
                                id={id}
                                columnas={datatable.columns}
                                adjuntosExistentes={ adjuntosExistentes }
                                saveAdjuntosExistentes={ saveAdjuntosExistentes }
                                readonly={ readonly }
                                optionSelect={optionSelect}
                                estadoForm= { estadoForm }
                                columnactions={datatable.columnactions.title}
                                traduction={traduction}
                                buttons={ datatable.buttons}
                                ordering={ datatable.ordering}
                                order={ datatable.order}
                                searching={ datatable.searching}
                                paging={ datatable.paging}
                                select={ datatable.select}
                                grouping={ datatable.grouping}
                                pagelength={ datatable.pagelength }
                            />   
                        :
                            null
                    }
            </SRLWrapper>                               
    )
}

export default UplmPlusList;