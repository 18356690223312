import React, { useContext, useState, useEffect, Fragment } from 'react';
import Cookies from 'universal-cookie';
import Select from 'react-select';
import axios from 'axios';
import { Alert } from '../../ui/Alert';
import { alertService } from '../../../services/alertservice';

import { getApiUrl } from '../../../helpers/helperApp';


import { LoginContext } from '../../../hooks/context/LoginContext';

import Menusupitem from './Menusupitem';


function Menusup(props){

    const { cerrarSesion, confApp, apiKey } = useContext(LoginContext);    

    const [loadedConf, saveLoadedConf] = useState(false);

    const cookies = new Cookies();

    const customStyles = {
      control: (base, state) => ({
          ...base,
          borderColor:'#ced4da',
          borderColor: state.isFocused ? '#80bdff': '#ced4da',
          outline: 0,
          boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
          // background: readOnly === "readonly" || loading || estadoForm === "readonly" ? "#e9ecef" : "white",
          color: "#495057",
          borderTop: "none",
          borderLeft : "none",
          borderRight : "none",
          borderRadius: "0px",
          boxShadow: state.isHover ? "none" : "none"
        }),
        option: (provided, state) => ({
          ...provided,
          color: "#212529",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#495057",
        }),
        menuPortal: (provided) => ({
          ...provided,            
          zIndex: 9999
        }) 
  }

    useEffect(() => {

      saveLoadedConf(true);
      
  },[loadedConf])

    const handleCerrarSesion = (props) => {
        cerrarSesion(props);       
    }

    const handleChangeLang = async (selectedItem, nameOfComponent) => {

      const data = new FormData();
      data.append("validacion", apiKey());
      data.append("lang", selectedItem.value)

      const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/updateLang', data);
      if( typeof respuesta.data == "number" ){
        // Recargamos la página. Le pasamos el parámetro a true para recargue desde el servidor y no desde la caché.
        window.location.reload(true);
      }else if(typeof respuesta.data == "string"){
        // Mostramos el error
        alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
      }
    }

    return(
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link sidebar-toogle-btn" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
         
        </ul>

        <ul className="navbar-nav ml-auto">
          {
            loadedConf != undefined
            ?
              <Fragment>
                <Alert />
                {/* <li className="nav-item dropdown">
                  <a className="nav-link" data-toggle="dropdown" href="#">
                    <i className="far fa-comments"></i>
                    <span className="badge badge-danger navbar-badge">3</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" className="dropdown-item">
                      <div className="media">
                        <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                        <div className="media-body">
                          <h3 className="dropdown-item-title">
                            Brad Diesel
                            <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                          </h3>
                          <p className="text-sm">Call me whenever you can...</p>
                          <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link" data-toggle="dropdown" href="#">
                    <i className="far fa-bell"></i>
                    <span className="badge badge-warning navbar-badge">15</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span className="dropdown-item dropdown-header">15 Notifications</span>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item">
                      <i className="fas fa-envelope mr-2"></i> 4 new messages
                      <span className="float-right text-muted text-sm">3 mins</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                  </div>
                </li> */}

                {
                    confApp != undefined
                    ?
                      <li className="nav-item" style={{width:"75px"}}>
                        <Select
                          value={confApp.menuidioma.length > 0 ? confApp.menuidioma.filter(option => option.value == cookies.get('idioma')) : null}
                          onChange={handleChangeLang}
                          options={
                            confApp.menuidioma.map((idioma)=>{
                              return { value: idioma.value, label: idioma.label }
                            })
                          }
                          styles={customStyles}
                        />
                      </li>                      
                    :
                      null
                }
                <li className="nav-item">
                  <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i className="fas fa-expand-arrows-alt"></i>
                  </a>
                </li>
                {
                    confApp != undefined
                    ?
                      <li className="nav-item">
                        <a onClick={ e => { handleCerrarSesion(props) } }  className="nav-link" role="button">
                          <i className="fal fa-sign-out-alt"></i>{confApp.config.trad.menusup.cerrarsesion.title}
                        </a>
                      </li>
                    :
                      null
                }



              </Fragment>
            :
            null
          }
          

                    
        </ul>
      </nav>
    )
}

export default Menusup;