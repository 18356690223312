import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";
import axios from 'axios';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import DataTable from 'datatables.net';



import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, axiosPost } from '../../../helpers/helperApp';

import MiDataTableUplmInfo from './MiDataTableUplmInfo';


function UplmInfo(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { apiKey } = useContext(LoginContext);

    const { id, table, traduction, filter } = props;

    const [datosTable, saveDatosTable] = useState([]);
    const [loading, saveLoading] = useState(false);

     
    useEffect( () => {   

        saveLoading(true);
        
        getDataDataTable()

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        }        

    },[])

    // Filtramos en la tabla de documentos
    let tablaDocus = $('#uplmInfo-'+id).DataTable();
    tablaDocus.search(filter).draw();

    const getDataDataTable = async () =>{

        const data = new FormData();
        data.append("validacion", apiKey());

        data.append("tablas", JSON.stringify(table.tablas));
        // const res = await axios.post(getApiUrl(window.location.origin)+table.data.url, data);
        const res = await axiosPost(getApiUrl(window.location.origin)+table.data.url, data, source.token);
        
        saveLoading(false);
        
        saveDatosTable(res.data);
  
    }

    return(

        <MiDataTableUplmInfo 
            id={ "uplmInfo-"+id }
            columnas={ table.columns }
            data={ datosTable }
            buttons={ table.buttons }
            ordering={ table.ordering }
            order={ table.order }
            searching={ table.searching }
            paging={ table.paging }
            select={ table.select }
            columnactions={ table.columnactions.title }
            traduction={ traduction }
            linkColumns = {table.linkColumns }
            pagelength = { table.pagelength }
            grouping={ table.grouping}
            loading={ loading }
        />                  
    )
}

export default UplmInfo;