import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import UplmPlus from '../../componentes/formulario/UplmPlus/UplmPlus';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';
import Informes from '../../componentes/formulario/informes/Informes';
import MiDataTableAdjLin from '../../componentes/formulario/MiDataTableAdjLin/MiDataTableAdjLin';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import { alertService } from '../../services/alertservice';
import { NavLink } from 'react-router-dom';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost } from '../../helpers/helperApp';

function Documentosencenc(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();
    const source5 = CancelToken.source();    

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp);
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");
    const [ docuFromOt, saveDocuFromOt ] = useState(false);
    const [ cerradaOtOri, saveCerradaOtOri ] = useState();
    const [ otConTrabajo, saveOtConTrabajo ] = useState();
    const [ noQuestionSubmit, saveNoQuestionSubmit ] = useState(false);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo, editReg, loadHijos } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = confPantalla.config.trad.maintenance.title+" - "+confPantalla.documentosenc.title;
                    if( confPantalla.documentosenc.panminlvl == null || cookies.get('nivel') <= confPantalla.documentosenc.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            saveEstadoFormPrin(confPantalla.documentosenc.padre.estadoform);
                            saveEstadoFormHijo(confPantalla.documentosenc.hijo.estadoform);                            
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.documentosenc.padre.urls);
                                    saveFormularioPrin(confPantalla.documentosenc.padre.modelform);
                                    saveFormularioHijo(confPantalla.documentosenc.hijo.modelform);     
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo); 
                                }                        
                            }                      
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })    
            

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();                
                source2.cancel();                
                source3.cancel();                
                source4.cancel();                
                source5.cancel();                
        } 

    },[confPantalla])


    const handleAge2AndUpdateLineas = (selectedItem, nameOfComponent) => {

        // Guardamos en el state el valor del select
        handleSelectFormPrin(selectedItem, nameOfComponent);              
        
        if( formularioPrin.id != "" ){
            // Realizamos la consulta para ver si actualizamos todas las líneas con el valor seleccionado en el combo
            swal({
                title: confPantalla.documentosenc.padre.controles.updateages.title,
                text: confPantalla.documentosenc.padre.controles.updateages.text.title,
                icon: "warning",
                buttons: {
                    cancel : {text: confPantalla.documentosenc.padre.controles.updateages.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                    idage1 : {text: confPantalla.documentosenc.padre.controles.updateages.btnresp.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                    idage2 : {text: confPantalla.documentosenc.padre.controles.updateages.btntrab.title, className:'btn btn-flat mr-1 btn-outline-warning btnSwalWarning'}
                } 
            }).then(confirmacion => {
                if( confirmacion ){
                    // Actualizar el agente en las lineas
                    updateCampo(confirmacion, selectedItem ? selectedItem.value : "", confPantalla.documentosenc.padre.controles.updateages.updateageslineas, confPantalla.documentosenc.padre.controles.updateages.txtsuccess.title, confPantalla.documentosenc.padre.controles.updateages.txterror.title);
                }
            })       
        }
    } 

    
    const handleFec2AndUpdateLineas = (e, formularioPrin, saveFormularioPrin) => {        
            if( estadoFormPrin != "readonly" && estadoFormPrin != "disabled" ){
                handleInputForm(e, formularioPrin, saveFormularioPrin);
                
                if( formularioPrin.id != "" ){
                    swal({
                        title: confPantalla.documentosenc.padre.controles.updatefec2.title,
                        text: confPantalla.documentosenc.padre.controles.updatefec2.text.title,
                        icon: "warning",
                        buttons: {
                            cancel : {text: confPantalla.documentosenc.padre.controles.updatefec2.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: confPantalla.documentosenc.padre.controles.updatefec2.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}
                        } 
                    }).then(confirmacion => {
                        if( confirmacion ){
                            // Actualizar la fecha de previsión en todas las lineas
                            updateCampo("fec2", e.target.value, confPantalla.documentosenc.padre.controles.updatefec2.updatefec2lineas, confPantalla.documentosenc.padre.controles.updatefec2.txtsuccess.title, confPantalla.documentosenc.padre.controles.updatefec2.txterror.title);
                        }
                    })    
                }
            }
    }

    const handleUbiAndUpdateLineas = (selectedItem, nameOfComponent) => {

        // Guardamos en el state el valor del select
        handleSelectFormPrin(selectedItem, nameOfComponent);              
        
        if( formularioPrin.id != "" ){
            // Realizamos la consulta para ver si actualizamos todas las líneas con el valor seleccionado en el combo
            swal({
                title: confPantalla.documentosenc.padre.controles.updateubi.title,
                text: confPantalla.documentosenc.padre.controles.updateubi.text.title,
                icon: "warning",
                buttons: {
                    cancel : {text: confPantalla.documentosenc.padre.controles.updateubi.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                    confirm : {text: confPantalla.documentosenc.padre.controles.updateubi.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}
                } 
            }).then(confirmacion => {
                if( confirmacion ){
                    // Actualizar el agente en las lineas
                    updateCampo("idubi", selectedItem ? selectedItem.value : "", confPantalla.documentosenc.padre.controles.updateubi.updateubilineas, confPantalla.documentosenc.padre.controles.updateubi.txtsuccess.title, confPantalla.documentosenc.padre.controles.updateubi.txterror.title);
                }
            })       
        }
    }
    
    
    const handleEstAndUpdateLineas = (selectedItem, nameOfComponent) => {

        // Guardamos el estado anterior
        const estAnt = { "value" : formularioPrin.idest };

        // Guardamos en el state el valor del select
        handleSelectFormPrin(selectedItem, nameOfComponent);              
        
        // EDITANDO TRABAJOS
	    // DOCUMENTOS DEL ENCARGARDO
        // Edita ES preguntar si aplicar ese ES a todas las lineas (TODAS, sin excepción) CON pregunta
        if( formularioPrin.idtip == 18 ){
            if( formularioPrin.id != "" ){
                // if( formularioPrin.fec2 == "" || formularioPrin.fec2 == "0000-00-00T00:00" ){
                //     // Si no tiene puesta la fecha de previsión, dejamos el estado como estaba y le sacamos un mensaje para que la cubra ya que es un campo obligatorio
                //     alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.documentosenc.padre.controles.updateest.fec2required.title}`, {autoClose: true});
                //     // VOLVER A PONER EL ESTADO QUE TENIA Y NO HACER NADA
                //     handleSelectFormPrin(estAnt, nameOfComponent);  
                // }else{
                    // Realizamos la consulta para ver si actualizamos todas las líneas con el valor seleccionado en el combo
                    swal({
                        title: confPantalla.documentosenc.padre.controles.updateest.title,
                        text: confPantalla.documentosenc.padre.controles.updateest.text.title,
                        icon: "warning",
                        buttons: {
                            cancel : {text: confPantalla.documentosenc.padre.controles.updateest.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: confPantalla.documentosenc.padre.controles.updateest.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}
                        } 
                    }).then(confirmacion => {
                        if( confirmacion ){
                            // Actualizar el agente en las lineas
                            updateCampo("idest", selectedItem ? selectedItem.value : "", confPantalla.documentosenc.padre.controles.updateest.updateestlineas, confPantalla.documentosenc.padre.controles.updateest.txtsuccess.title, confPantalla.documentosenc.padre.controles.updateest.txterror.title);
                        }
                    }).then(()=>{                    
                        if( selectedItem.value == 9 || selectedItem.value == 12 ){
                            // Al cambiar el estado a realizado done/undone y viene de una OT dar la opción de cerrar la OT                        
                            if( docuFromOt == true ){
                                // Es un trabajo que viene de una ot
                                if( cerradaOtOri == true ){
                                    // La ot de origen está cerrada. Mensaje informando al usuario de ello
                                    alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confPantalla.documentosenc.padre.controles.closeot.otisclosed.title}`, {autoClose: true});
                                }else if( cerradaOtOri == false ){
                                    // La ot de origen está en estado distinto a done/undone. Dar opción de cerrarla
                                    swal({
                                        title: `${confPantalla.documentosenc.padre.controles.closeot.swal.title}`,
                                        text: "",
                                        icon: "warning",
                                        buttons: {
                                            cancel : {text:  `${confPantalla.documentosenc.padre.controles.closeot.swal.button.cancel.title}`, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                                            confirm : {text: `${confPantalla.documentosenc.padre.controles.closeot.swal.button.confirm.title}`, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}
                                        } 
                                    }).then(confirmacion => {
                                        if( confirmacion ){                                        
                                            // Cerrar la OT de origen y hacer submit del form
                                            cerrarOt();
                                        }else{
                                            // VOLVER A PONER EL ESTADO QUE TENIA Y NO HACER NADA
                                            handleSelectFormPrin(estAnt, nameOfComponent);  
                                        }
                                    })
                                }
                            }
                            
                        }
    
                    })       
                // }
            }
        }
    }

    const cerrarOt = async(e) => {
       
        // Cambiamos el estado para que el submit no haga pregunta de si quiere guardar el registro y lo guarde directamente
        await saveNoQuestionSubmit(true);
        
        const btnSubmitFormPrin = document.querySelector("button.btnsave.formPrin");
        if( btnSubmitFormPrin ){            
            btnSubmitFormPrin.click();
        }

        // Volvemos a dejar el state del submit del form como estaba
        await saveNoQuestionSubmit(false);    

        const data = new FormData();
        data.append("id", formularioPrin.id);
        data.append("validacion", apiKey());
        // const res = await axios.post(`${getApiUrl(window.location.origin)}/documentosenc/cerrarOt`, data);
        const res = await axiosPost(`${getApiUrl(window.location.origin)}/documentosenc/cerrarOt`, data, source.token);
        if( res.data == null ){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.documentosenc.padre.controles.closeot.alert.error.title}`, {autoClose: true});
        }else{
            if( res.data.id == formularioPrin.id ){
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.documentosenc.padre.controles.closeot.alert.ok.title}`, {autoClose: true});
                // Si cerramos la ot ponemos los formularios como solo lectura                
                saveEstadoFormPrin("readonly");
                saveEstadoFormHijo("readonly");
            }else if( typeof res.data == "string" ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.documentosenc.padre.controles.closeot.alert.error.title}`, {autoClose: true});
            }
        }
    }


    const updateCampo = async(campo, value, url, txtsuccess, txterror) => {
        const data = new FormData();
        data.append("campo", campo);
        data.append(campo, value);
        data.append("idreg", formularioPrin.id);
        data.append("validacion", apiKey());
        // const res = await axios.post(`${getApiUrl(window.location.origin)}${url}`, data);
        const res = await axiosPost(`${getApiUrl(window.location.origin)}${url}`, data, source2.token);

        if( res.data == formularioPrin.id ){
            // Lineas acutalizadas
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${txtsuccess}`, {autoClose: true});;
        }else{
            // Error en la actualización de lineas
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${txterror}`, {autoClose: true});
        }
    }


    const getEsTraspasoAndHandleSelect = async (selectedItem, nameOfComponent) => {

        // Hacemos una llamada para saber si el tipo de documento es de tipo traspado para mostrar u ocultar la ubicación 2
        if( selectedItem ){
            const data = new FormData();
            data.append("id", selectedItem.value);
            const validacion = apiKey();
            data.append("validacion", validacion);
            const url = `${getApiUrl(window.location.origin)}/documentosenc/getTipoStock`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(url, data, source3.token);
            
            if( res.data[0].sto == 4){
                saveFormularioPrin(
                    {
                        ... formularioPrin, 
                        ["tipostock"] : res.data[0].sto,
                        [nameOfComponent.name]:selectedItem.value                        
                    }
                )
            }else{
                saveFormularioPrin(
                    {
                        ... formularioPrin, 
                        ["tipostock"] : "",
                        [nameOfComponent.name]:selectedItem.value                         
                    }
                )
            }            
           
        }else{
            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["tipostock"] : ""                       
                }
            )

        }

    }
    const handleUnidadAndSetDescription = async (selectedItem, nameOfComponent, setValue) => {

        if( selectedItem ){
            const data = new FormData();
            data.append("id", selectedItem.value);
            data.append("validacion", apiKey());
            // const res = await axios.post(`${getApiUrl(window.location.origin)}/articulos/getDescripciones`, data); 
            const res = await axiosPost(`${getApiUrl(window.location.origin)}/articulos/getDescripciones`, data, source4.token);       
            handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, setValue, res.data[0].nom1, res.data[0].nom2);
        }else{
            handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, setValue, "", "");

        }
    }

    const checkState = async () => {

        if( formularioPrin.id != "" ){            
            const data = new FormData();
            data.append("idReg", formularioPrin.id);
            data.append("validacion", apiKey());
            // const res = await axios.post(`${getApiUrl(window.location.origin)}/documentosenc/checkState`, data); 
            const res = await axiosPost(`${getApiUrl(window.location.origin)}/documentosenc/checkState`, data, source5.token);       

            if( formularioPrin.idtip == "18" ){
                // ES UN TRABAJO
                // Si el trabajo procede de una OT cerrada donde/undone NO se puede tocar nada, modo solo lectura total
                if( res.data.ori == "15" || res.data.ori == "16" ){
                    // Viene de una ot
                    saveDocuFromOt(true);
                    if( res.data.idest == "9" || res.data.idest == "12" ){
                        saveCerradaOtOri(true);
                        saveEstadoFormPrin("readonly");
                        saveEstadoFormHijo("readonly");
                        alertService.warn(`<i class='fal fa-exclamation-triangle mr-1'></i>${confPantalla.documentosenc.padre.controles.checkotclosed.title}`, {autoClose: true});
                    }else if( res.data.idest != "9" && res.data.idest != "12" ){
                        saveCerradaOtOri(false);
                        saveEstadoFormPrin("");
                        saveEstadoFormHijo("");
                    }
                }else if( res.data.ori != "15" && res.data.ori != "16" ){
                    // No viene de una ot
                    saveDocuFromOt(false);
                }else if( typeof res.data == "string" ){
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${res.data}`, {autoClose: true});
                }
            }
            if( formularioPrin.idtip == "15" || formularioPrin.idtip == "16" ){
                // ES UNA OT
                if( formularioPrin.idest == "9" || formularioPrin.idest == "12" ){
                    saveEstadoFormPrin("readonly");
                    saveEstadoFormHijo("readonly");
                }else{                        
                    if( res.data.destino == "18" ){
                        // Si la OT tiene trabajo no le puede dar a borrar cabecera ni borrar/crear lineas
                        saveOtConTrabajo(true);
                        saveEstadoFormPrin("readonly");
                        saveEstadoFormHijo("readonly");                       
                        // if( res.data.idest == 9 || res.data.idest == 12){
                        //     saveEstadoFormHijo("readonly");
                        // }
                    }else{
                        saveOtConTrabajo(false);
                    }
                }
            }
            
        }        
    }

    return(
        <div className="App wrapper">  
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div> 
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">                                    
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                // titulo={ confPantalla.documentosenc.section.titulo }
                                titulo={
                                    formularioPrin.id == "" ?
                                        confPantalla.documentosenc.section.titulo
                                    : 
                                        cookies.get('idioma').toLowerCase() == "es".toLowerCase() ?
                                            formularioPrin.tipdoccod+" - "+formularioPrin.tipdocnom+" - "+formularioPrin.nom1
                                        :
                                            cookies.get('idioma').toLowerCase() == "en".toLowerCase() ?
                                                formularioPrin.tipdoccod+" - "+formularioPrin.tipdocnom+" - "+formularioPrin.nom1_en
                                            :
                                                formularioPrin.tipdoccod+" - "+formularioPrin.tipdocnom+" - "+formularioPrin.nom1+" - "+formularioPrin.nom1_en
                                }                                  
                                icono={ confPantalla.documentosenc.section.icono } 
                                col={ confPantalla.documentosenc.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                                borderHeader={ formularioPrin.color }
                                recurrencia={formularioPrin.recurrencia}
                        >
                                <Tabs defaultActiveKey="tab1" id="tab1">
                                    <Tab eventKey="tab1" title={ confPantalla.documentosenc.tabs.tab1.titulo }>
                                        <Formulario 
                                                    onChangeIdPadre={ checkState }
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.documentosenc.padre.btnform.new }
                                                    btnDelete={ otConTrabajo ? "false" :  confPantalla.documentosenc.padre.btnform.delete }
                                                    btnSave={ confPantalla.documentosenc.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.documentosenc.padre.btnform.saveandnew }
                                                    btnClone={ confPantalla.documentosenc.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.modelform, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.title, noQuestionSubmit) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.documentosenc.padre.modelform, true, confPantalla.documentosenc.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.modelform, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title) } 
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.padreshijos, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title) } 

                                        >
                                  
                                            <Tabs defaultActiveKey="tab1_1" id="tab1_1" className="tab-pane">
                                                <Tab eventKey="tab1_1" title={ confPantalla.documentosenc.tabs.tab1.subtabs.tab11.titulo }>
                                                     <FormRow clases="" >
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idage2  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idage2.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idage2.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idage2.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idage2.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idage2.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idage2.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idage2.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idage2.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idage2.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idage2.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idage2.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idage2.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idage2.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idage2.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleAge2AndUpdateLineas }                                                            
                                                        />                                                 
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idest  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idest.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idest.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idest.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idest.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idest.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idest.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idest.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idest.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idest.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idest.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idest.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idest.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idest.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idest.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleEstAndUpdateLineas }
                                                        />  
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.fec1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.fec1.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.fec1.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.fec1.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.fec1.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.fec1.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.fec1.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.fec1.required }
                                                            readonly=   { formularioPrin.idtip == "18" ? "readonly" : confPantalla.documentosenc.padre.controles.fec1.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.fec1.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     { formularioPrin.idtip == "18" ? null : e => handleFec2AndUpdateLineas(e, formularioPrin, saveFormularioPrin) } 
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.nom1.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.nom1.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.nom1.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.nom1.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.nom1.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.nom1.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.nom1.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.nom1.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.nom1.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.notas }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.notas.id }   
                                                            name=       { confPantalla.documentosenc.padre.controles.notas.name }
                                                            rows=       { confPantalla.documentosenc.padre.controles.notas.rows }      
                                                            label=      { confPantalla.documentosenc.padre.controles.notas.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.notas.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.notas.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.notas.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.notas.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.notas.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />                                                        
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom1_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.nom1_en.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.nom1_en.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.nom1_en.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.nom1_en.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.nom1_en.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.nom1_en.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.nom1_en.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.nom1_en.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.nom1_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.notas_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.notas_en.id }   
                                                            name=       { confPantalla.documentosenc.padre.controles.notas_en.name }
                                                            rows=       { confPantalla.documentosenc.padre.controles.notas_en.rows }      
                                                            label=      { confPantalla.documentosenc.padre.controles.notas_en.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.notas_en.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.notas_en.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.notas_en.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.notas_en.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.notas_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idequ  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idequ.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idequ.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idequ.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idequ.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idequ.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idequ.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idequ.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idequ.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idequ.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idequ.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idequ.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idequ.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idequ.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idequ.disableids}
                                                            depends={ confPantalla.documentosenc.padre.controles.idequ.depends }
                                                            formulario= { formularioPrin }
                                                            change= { (selectedItem, nameOfComponent,) => handleSelectFormPrinAndSetValue(selectedItem, nameOfComponent, confPantalla.documentosenc.padre.controles.idequ.setvalue) }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idubi  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idubi.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idubi.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idubi.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idubi.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idubi.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idubi.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idubi.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idubi.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idubi.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idubi.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idubi.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idubi.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idubi.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idubi.disableids}
                                                            depends={ confPantalla.documentosenc.padre.controles.idubi.depends }
                                                            formulario= { formularioPrin }   
                                                            change= { handleUbiAndUpdateLineas }
                                                        />
                                                        {
                                                            formularioPrin.tipostock == "4"
                                                            ?
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormPrin }
                                                                val=         { formularioPrin.idubi2  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.documentosenc.padre.controles.idubi2.id }    
                                                                name=        { confPantalla.documentosenc.padre.controles.idubi2.name }   
                                                                label=       { confPantalla.documentosenc.padre.controles.idubi2.label }    
                                                                placeholder= { confPantalla.documentosenc.padre.controles.idubi2.placeholder }
                                                                col=         { confPantalla.documentosenc.padre.controles.idubi2.col } 
                                                                isClearable= { confPantalla.documentosenc.padre.controles.idubi2.isclearable }
                                                                isSearchable={ confPantalla.documentosenc.padre.controles.idubi2.issearchable }
                                                                dataType=    { confPantalla.documentosenc.padre.controles.idubi2.datatype }
                                                                data=        { confPantalla.documentosenc.padre.controles.idubi2.data }
                                                                required=    { confPantalla.documentosenc.padre.controles.idubi2.required } 
                                                                disabled=  { confPantalla.documentosenc.padre.controles.idubi2.disabled }
                                                                readOnly =   { confPantalla.documentosenc.padre.controles.idubi2.readonly }
                                                                disablethisid={confPantalla.documentosenc.padre.controles.idubi2.disablethisid}
                                                                disableids={confPantalla.documentosenc.padre.controles.idubi2.disableids}
                                                                depends={ confPantalla.documentosenc.padre.controles.idubi2.depends }
                                                                formulario= { formularioPrin }   
                                                                change= { handleSelectFormPrin }
                                                            />
                                                            :
                                                            null
                                                        }
                                                    </FormRow>        
                                                                          
                                                </Tab>

                                                <Tab eventKey="tab1_4" title={ confPantalla.documentosenc.tabs.tab1.subtabs.tab14.titulo }>                                                  
                                                    <FormRow clases="" >                                                     
                                                        <UplmPlus
                                                            id={ confPantalla.documentosenc.padre.controles.uplmplus.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            estadoForm= { estadoFormPrin }
                                                            loading= { loadReg ? true : false }
                                                            col={12}
                                                            readonly={ confPantalla.documentosenc.padre.controles.uplmplus.readonly }
                                                            traduction={confPantalla.config.trad.components}                                                            
                                                            datatable={ confPantalla.documentosenc.padre.controles.uplmplus.datatable }
                                                            select={ confPantalla.documentosenc.padre.controles.uplmplus.select }
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                <Tab eventKey="tab1_8" title={ confPantalla.documentosenc.tabs.tab1.subtabs.tab18.titulo }>                                                  
                                                    <FormRow clases="" >
                                                        <MiDataTableAdjLin 
                                                            id =         { confPantalla.documentosenc.padre.controles.tableadjlin.id }     
                                                            loading=     { loadReg ? true : false }
                                                            formulario = { formularioPrin }
                                                            handleEditReg= { e => handleEditRegHijo(e, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.tabla) }
                                                            table =      { confPantalla.documentosenc.padre.controles.tableadjlin }
                                                            traduction=  { confPantalla.config.trad.components }
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                <Tab eventKey="tab1_7" title={ confPantalla.documentosenc.tabs.tab1.subtabs.tab17.titulo }> 
                                                    <Recurrencia
                                                        estadoForm=        { estadoFormPrin }
                                                        loading =          { loadReg ? true : false }
                                                        formulario =       { formularioPrin }
                                                        changeSelect =     { handleSelectFormPrin }
                                                        saveFormulario =   { saveFormularioPrin }
                                                        handleInput =      { handleInputForm }                                                        
                                                        traduction=        { confPantalla.config.trad.components.recurrencia }
                                                    />                                                 
                                                </Tab>
                                                <Tab eventKey="tab1_5" title={ confPantalla.documentosenc.tabs.tab1.subtabs.tab15.titulo }>
                                                    <Informes
                                                        select =     { confPantalla.documentosenc.padre.controles.informes.select }
                                                        formulario = { formularioPrin }
                                                        loadReg =    { loadReg ? true : false }
                                                        estadoForm=  { "" } 
                                                        traduction=  { confPantalla.config.trad.components }
                                                    />                                                  
                                                </Tab>
                                                {
                                                    formularioPrin.id != "" && estadoFormPrin !== "readonly" && estadoFormPrin !== "disabled"
                                                    ?
                                                        <Tab title={
                                                                    <NavLink to={"/edirap/"+formularioPrin.id}>
                                                                        {confPantalla.edirap.sidebarmenu.titulo}
                                                                        <i className={"ml-1 "+confPantalla.edirap.sidebarmenu.icono}></i>
                                                                    </NavLink>
                                                                } 
                                                            tabClassName="btn btn-light">                                                        
                                                        </Tab>
                                                    :
                                                        null
                                                }
                                                    {/* Campos ocultos */}
                                                    <FormRow clases="noDisplay">
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idtip  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idtip.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idtip.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idtip.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idtip.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idtip.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idtip.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idtip.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idtip.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idtip.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idtip.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idtip.disabled }
                                                            readOnly =   { "readonly" }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idtip.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idtip.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { getEsTraspasoAndHandleSelect }                                                           
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idtip  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idtip.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idtip.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idtip.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idtip.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idtip.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idtip.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idtip.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idtip.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idtip.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idtip.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idtip.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idtip.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idtip.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idtip.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { getEsTraspasoAndHandleSelect }
                                                        />
                                                    
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idage1  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idage1.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idage1.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idage1.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idage1.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idage1.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idage1.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idage1.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idage1.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idage1.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idage1.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idage1.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idage1.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idage1.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idage1.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />

                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.cod.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.cod.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.cod.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.cod.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.cod.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.cod.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.cod.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.cod.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.cod.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />

                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.fec2 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.fec2.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.fec2.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.fec2.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.fec2.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.fec2.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.fec2.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.fec2.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.fec2.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.fec2.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.fec3 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.fec3.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.fec3.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.fec3.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.fec3.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.fec3.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.fec3.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.fec3.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.fec3.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.fec3.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />

                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idcen  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idcen.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idcen.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idcen.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idcen.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idcen.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idcen.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idcen.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idcen.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idcen.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idcen.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idcen.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idcen.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idcen.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idcen.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />

                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idapa  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.documentosenc.padre.controles.idapa.id }    
                                                            name=        { confPantalla.documentosenc.padre.controles.idapa.name }   
                                                            label=       { confPantalla.documentosenc.padre.controles.idapa.label }    
                                                            placeholder= { confPantalla.documentosenc.padre.controles.idapa.placeholder }
                                                            col=         { confPantalla.documentosenc.padre.controles.idapa.col } 
                                                            isClearable= { confPantalla.documentosenc.padre.controles.idapa.isclearable }
                                                            isSearchable={ confPantalla.documentosenc.padre.controles.idapa.issearchable }
                                                            dataType=    { confPantalla.documentosenc.padre.controles.idapa.datatype }
                                                            data=        { confPantalla.documentosenc.padre.controles.idapa.data }
                                                            required=    { confPantalla.documentosenc.padre.controles.idapa.required } 
                                                            disabled=  { confPantalla.documentosenc.padre.controles.idapa.disabled }
                                                            readOnly =   { confPantalla.documentosenc.padre.controles.idapa.readonly }
                                                            disablethisid={confPantalla.documentosenc.padre.controles.idapa.disablethisid}
                                                            disableids={confPantalla.documentosenc.padre.controles.idapa.disableids}
                                                            formulario= { formularioPrin }
                                                            change= { handleSelectFormPrin }
                                                        />    
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">                     
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iin"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iin.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.iin.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iin.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iin.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iin.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iin.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iin.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iin.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iin.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iin"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iin.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iin.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iin.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iin.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iin.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iin.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iin.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iin.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin["valoracion.cos"] }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.documentosenc.padre.controles.cos.id }   
                                                            type=       { confPantalla.documentosenc.padre.controles.cos.type }  
                                                            name=       { confPantalla.documentosenc.padre.controles.cos.name }   
                                                            label=      { confPantalla.documentosenc.padre.controles.cos.label }                                                       
                                                            placeholder={ confPantalla.documentosenc.padre.controles.cos.placeholder }    
                                                            col=        { confPantalla.documentosenc.padre.controles.cos.col } 
                                                            required=   { confPantalla.documentosenc.padre.controles.cos.required }
                                                            readonly=   { confPantalla.documentosenc.padre.controles.cos.readonly }                                                
                                                            disabled=   { confPantalla.documentosenc.padre.controles.cos.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null}
                                                            alignRight= { true }
 
                                                        />                                           
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        { 
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.und"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.und.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.und.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.und.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.und.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.und.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.und.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.und.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.und.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.und.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.und"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.und.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.und.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.und.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.und.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.und.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.und.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.und.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.und.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.pre"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.pre.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.pre.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.pre.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.pre.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.pre.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.pre.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.pre.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.pre.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.pre.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null}
                                                                    alignRight= { true } 
                                                                />                                           
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.pre"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.pre.id }   
                                                                    type=       { "hidden"}  
                                                                    name=       { confPantalla.documentosenc.padre.controles.pre.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.pre.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.pre.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.pre.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.pre.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.pre.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.pre.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.imp1.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.imp1.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.imp1.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.imp1.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.imp1.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.imp1.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.imp1.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.imp1.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.imp1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.imp1.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.imp1.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.imp1.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.imp1.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.imp1.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.imp1.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.imp1.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.imp1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.mar1.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.mar1.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.mar1.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.mar1.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.mar1.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.mar1.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.mar1.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.mar1.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.mar1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar1"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.mar1.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.mar1.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.mar1.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.mar1.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.mar1.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.mar1.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.mar1.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.mar1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.dto"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.dto.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.dto.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.dto.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.dto.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.dto.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.dto.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.dto.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.dto.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.dto.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.dto"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.dto.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.dto.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.dto.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.dto.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.dto.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.dto.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.dto.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.dto.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.impd"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.impd.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.impd.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.impd.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.impd.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.impd.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.impd.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.impd.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.impd.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.impd.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.impd"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.impd.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.impd.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.impd.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.impd.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.impd.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.impd.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.impd.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.impd.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.imp2.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.imp2.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.imp2.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.imp2.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.imp2.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.imp2.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.imp2.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.imp2.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.imp2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.imp2.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.imp2.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.imp2.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.imp2.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.imp2.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.imp2.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.imp2.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.imp2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.mar2.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.mar2.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.mar2.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.mar2.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.mar2.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.mar2.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.mar2.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.mar2.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.mar2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar2"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.mar2.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.mar2.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.mar2.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.mar2.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.mar2.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.mar2.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.mar2.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.mar2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iva"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iva.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.iva.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iva.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iva.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iva.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iva.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iva.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iva.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iva.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iva"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iva.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iva.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iva.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iva.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iva.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iva.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iva.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iva.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iiv"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iiv.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.iiv.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iiv.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iiv.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iiv.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iiv.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iiv.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iiv.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iiv.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iiv"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iiv.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iiv.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iiv.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iiv.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iiv.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iiv.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iiv.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iiv.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.rec"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.rec.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.rec.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.rec.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.rec.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.rec.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.rec.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.rec.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.rec.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.rec.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.rec"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.rec.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.rec.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.rec.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.rec.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.rec.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.rec.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.rec.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.rec.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iir"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iir.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.iir.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iir.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iir.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iir.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iir.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iir.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iir.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iir.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iir"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iir.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iir.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iir.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iir.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iir.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iir.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iir.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iir.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1" 
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.ret"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.ret.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.ret.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.ret.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.ret.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.ret.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.ret.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.ret.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.ret.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.ret.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.ret"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.ret.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.ret.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.ret.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.ret.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.ret.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.ret.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.ret.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.ret.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />
                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iit"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iit.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.iit.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iit.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iit.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iit.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iit.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iit.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iit.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iit.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null}
                                                                    alignRight= { true } 
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.iit"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.iit.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.iit.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.iit.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.iit.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.iit.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.iit.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.iit.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.iit.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                        }
                                                    </FormRow>
                                                    <FormRow clases="noDisplay">
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.imp3.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.imp3.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.imp3.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.imp3.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.imp3.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.imp3.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.imp3.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.imp3.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.imp3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                />
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.imp3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.imp3.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.imp3.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.imp3.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.imp3.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.imp3.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.imp3.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.imp3.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.imp3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                />

                                                        }
                                                        {
                                                            cookies.get('nivel') === "1"
                                                            ?
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.mar3.id }   
                                                                    type=       { confPantalla.documentosenc.padre.controles.mar3.type }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.mar3.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.mar3.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.mar3.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.mar3.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.mar3.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.mar3.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.mar3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                    alignRight= { true }
                                                                /> 
                                                            :
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin["valoracion.mar3"] }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.padre.controles.mar3.id }   
                                                                    type=       { "hidden" }  
                                                                    name=       { confPantalla.documentosenc.padre.controles.mar3.name }   
                                                                    label=      { confPantalla.documentosenc.padre.controles.mar3.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.padre.controles.mar3.placeholder }    
                                                                    col=        { confPantalla.documentosenc.padre.controles.mar3.col } 
                                                                    required=   { confPantalla.documentosenc.padre.controles.mar3.required }
                                                                    readonly=   { confPantalla.documentosenc.padre.controles.mar3.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.mar3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                        }
                                                    </FormRow>
                                                    {/* FIN: Campos ocultos */}
                                            </Tabs>
                                        </Formulario>
                                        
                                        <div className="noDisplay">
                                            <MiDataTable 
                                                estadoForm= { estadoFormPrin }
                                                formulario={ formularioPrin }
                                                id={"documentosenc"}
                                                columnas={confPantalla.documentosenc.padre.datatable.columns}
                                                data={registrosPrin}
                                                buttons={confPantalla.documentosenc.padre.datatable.buttons}
                                                ordering={confPantalla.documentosenc.padre.datatable.ordering}
                                                order={confPantalla.documentosenc.padre.datatable.order}
                                                searching={confPantalla.documentosenc.padre.datatable.searching}
                                                paging={confPantalla.documentosenc.padre.datatable.paging}
                                                select={confPantalla.documentosenc.padre.datatable.select}
                                                pagelength={confPantalla.documentosenc.padre.datatable.pagelength}
                                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.padreshijos, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.padre.tabla) }
                                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.modelform, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title) }
                                                saveLoadingTable={ saveLoadingTable }
                                                loadingTable={loadingTable}
                                                columnactions={confPantalla.documentosenc.padre.datatable.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable}
                                            />
                                        </div>                                             
                                    </Tab>
                                    { formularioPrin.id != 0                         
                                        ? 
                                            <Tab eventKey="tab2" title={ confPantalla.documentosenc.tabs.tab2.titulo }>
                                            <Formulario
                                                estadoForm= { estadoFormHijo }
                                                loading=    { loadReg ? true : false } 
                                                btnNew={ otConTrabajo ? "false" : "" }
                                                btnClone={ otConTrabajo ? "false" : "" }
                                                btnSaveAndNew={ otConTrabajo ? "false" : "" }
                                                btnDelete= { formularioHijo.idest == 9 || formularioHijo.idest == 12 || otConTrabajo ? "false" : "" }
                                                handleSubmit = { e => handleSubmitHijo(e, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.hijo.tabla, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title2) } 
                                                formulario = { formularioHijo } 
                                                resetForm = { e => resetFormHijo(confPantalla.documentosenc.hijo.modelform) } 
                                                handleDeleteItem = { e => handleDeleteItemHijo(undefined, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.hijo.tabla, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title2) }
                                                handleCloneItem = { e => handleCloneHijo(formularioHijo.id, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.tabla, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title2) }
                                                esHijo= { true }
                                                formularioPadre= { formularioPrin }  
                                            >
                                                <input type="hidden" name="iddoc" id="lineas.iddoc" value={ formularioPrin.id } />

                                                    <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                        <Tab eventKey="tab1_1" title={ confPantalla.documentosenc.tabs.tab2.subtabs.tab21.titulo }>
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idest == "0" || formularioHijo.idest == "" ? formularioPrin.idest : formularioHijo.idest  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentosenc.hijo.controles.idest.id }    
                                                                    name=        { confPantalla.documentosenc.hijo.controles.idest.name }   
                                                                    label=       { confPantalla.documentosenc.hijo.controles.idest.label }    
                                                                    placeholder= { confPantalla.documentosenc.hijo.controles.idest.placeholder }
                                                                    col=         { confPantalla.documentosenc.hijo.controles.idest.col } 
                                                                    isClearable= { confPantalla.documentosenc.hijo.controles.idest.isclearable }
                                                                    isSearchable={ confPantalla.documentosenc.hijo.controles.idest.issearchable }
                                                                    dataType=    { confPantalla.documentosenc.hijo.controles.idest.datatype }
                                                                    data=        { confPantalla.documentosenc.hijo.controles.idest.data }
                                                                    required=    { confPantalla.documentosenc.hijo.controles.idest.required } 
                                                                    disabled=  { confPantalla.documentosenc.hijo.controles.idest.disabled }
                                                                    readOnly =   { confPantalla.documentosenc.hijo.controles.idest.readonly }
                                                                    disablethisid={confPantalla.documentosenc.hijo.controles.idest.disablethisid}
                                                                    disableids={confPantalla.documentosenc.hijo.controles.idest.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />  
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idage2  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentosenc.hijo.controles.idage2.id }    
                                                                    name=        { confPantalla.documentosenc.hijo.controles.idage2.name }   
                                                                    label=       { confPantalla.documentosenc.hijo.controles.idage2.label }    
                                                                    placeholder= { confPantalla.documentosenc.hijo.controles.idage2.placeholder }
                                                                    col=         { confPantalla.documentosenc.hijo.controles.idage2.col } 
                                                                    isClearable= { confPantalla.documentosenc.hijo.controles.idage2.isclearable }
                                                                    isSearchable={ confPantalla.documentosenc.hijo.controles.idage2.issearchable }
                                                                    dataType=    { confPantalla.documentosenc.hijo.controles.idage2.datatype }
                                                                    data=        { confPantalla.documentosenc.hijo.controles.idage2.data }
                                                                    required=    { confPantalla.documentosenc.hijo.controles.idage2.required } 
                                                                    disabled=  { confPantalla.documentosenc.hijo.controles.idage2.disabled }
                                                                    readOnly =   { confPantalla.documentosenc.hijo.controles.idage2.readonly }
                                                                    disablethisid={confPantalla.documentosenc.hijo.controles.idage2.disablethisid}
                                                                    disableids={confPantalla.documentosenc.hijo.controles.idage2.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />                                                        
                                                            </FormRow> 
                                                            <FormRow>
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.num2 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.num2.id }   
                                                                    type=       { confPantalla.documentosenc.hijo.controles.num2.type }  
                                                                    name=       { confPantalla.documentosenc.hijo.controles.num2.name }   
                                                                    label=      { confPantalla.documentosenc.hijo.controles.num2.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.num2.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.num2.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.num2.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.num2.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.num2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.num1 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.num1.id }   
                                                                    type=       { confPantalla.documentosenc.hijo.controles.num1.type }  
                                                                    name=       { confPantalla.documentosenc.hijo.controles.num1.name }   
                                                                    label=      { confPantalla.documentosenc.hijo.controles.num1.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.num1.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.num1.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.num1.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.num1.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.num1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.iduni  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentosenc.hijo.controles.iduni.id }    
                                                                    name=        { confPantalla.documentosenc.hijo.controles.iduni.name }   
                                                                    label=       { confPantalla.documentosenc.hijo.controles.iduni.label }    
                                                                    placeholder= { confPantalla.documentosenc.hijo.controles.iduni.placeholder }
                                                                    col=         { confPantalla.documentosenc.hijo.controles.iduni.col } 
                                                                    isClearable= { confPantalla.documentosenc.hijo.controles.iduni.isclearable }
                                                                    isSearchable={ confPantalla.documentosenc.hijo.controles.iduni.issearchable }
                                                                    dataType=    { confPantalla.documentosenc.hijo.controles.iduni.datatype }
                                                                    data=        { confPantalla.documentosenc.hijo.controles.iduni.data }
                                                                    required=    { confPantalla.documentosenc.hijo.controles.iduni.required } 
                                                                    disabled=  { confPantalla.documentosenc.hijo.controles.iduni.disabled }
                                                                    readOnly =   { confPantalla.documentosenc.hijo.controles.iduni.readonly }
                                                                    disablethisid={confPantalla.documentosenc.hijo.controles.iduni.disablethisid}
                                                                    disableids={confPantalla.documentosenc.hijo.controles.iduni.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    // change= { (selectedItem, nameOfComponent,) => handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, confPantalla.documentosenc.hijo.controles.iduni.setvalue) }
                                                                    change= { (selectedItem, nameOfComponent,) => handleUnidadAndSetDescription(selectedItem, nameOfComponent, confPantalla.documentosenc.hijo.controles.iduni.setvalue) }
                                                                />
                                                            </FormRow>
                                                            <FormRow>
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.fec1 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.fec1.id }   
                                                                    type=       { confPantalla.documentosenc.hijo.controles.fec1.type }  
                                                                    name=       { confPantalla.documentosenc.hijo.controles.fec1.name }   
                                                                    label=      { confPantalla.documentosenc.hijo.controles.fec1.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.fec1.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.fec1.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.fec1.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.fec1.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.padre.controles.fec1.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.fec2 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.fec2.id }   
                                                                    type=       { confPantalla.documentosenc.hijo.controles.fec2.type }  
                                                                    name=       { confPantalla.documentosenc.hijo.controles.fec2.name }   
                                                                    label=      { confPantalla.documentosenc.hijo.controles.fec2.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.fec2.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.fec2.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.fec2.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.fec2.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.fec2.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.fec3 }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.fec3.id }   
                                                                    type=       { confPantalla.documentosenc.hijo.controles.fec3.type }  
                                                                    name=       { confPantalla.documentosenc.hijo.controles.fec3.name }   
                                                                    label=      { confPantalla.documentosenc.hijo.controles.fec3.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.fec3.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.fec3.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.fec3.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.fec3.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.fec3.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                            </FormRow>                     
                                                            <FormRow>
                                                                <FormInput 
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo.nom1 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.nom1.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.nom1.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.nom1.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.nom1.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.nom1.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.nom1.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.nom1.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.nom1.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.nom1.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        
                                                                        onBlur=     {null} 
                                                                    />
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.notas }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.notas.id }   
                                                                    name=       { confPantalla.documentosenc.hijo.controles.notas.name }
                                                                    rows=       { confPantalla.documentosenc.hijo.controles.notas.rows }      
                                                                    label=      { confPantalla.documentosenc.hijo.controles.notas.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.notas.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.notas.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.notas.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.notas.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.notas.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                            </FormRow>                                                            
                                                            <FormRow>
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.nom1_en }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.nom1_en.id }   
                                                                    type=       { confPantalla.documentosenc.hijo.controles.nom1_en.type }  
                                                                    name=       { confPantalla.documentosenc.hijo.controles.nom1_en.name }   
                                                                    label=      { confPantalla.documentosenc.hijo.controles.nom1_en.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.nom1_en.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.nom1_en.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.nom1_en.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.nom1_en.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.nom1_en.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    
                                                                    onBlur=     {null} 
                                                                />                                                                
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.notas_en }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.documentosenc.hijo.controles.notas_en.id }   
                                                                    name=       { confPantalla.documentosenc.hijo.controles.notas_en.name }
                                                                    rows=       { confPantalla.documentosenc.hijo.controles.notas_en.rows }      
                                                                    label=      { confPantalla.documentosenc.hijo.controles.notas_en.label }                                                       
                                                                    placeholder={ confPantalla.documentosenc.hijo.controles.notas_en.placeholder }    
                                                                    col=        { confPantalla.documentosenc.hijo.controles.notas_en.col } 
                                                                    required=   { confPantalla.documentosenc.hijo.controles.notas_en.required }
                                                                    readonly=   { confPantalla.documentosenc.hijo.controles.notas_en.readonly }                                                
                                                                    disabled=   { confPantalla.documentosenc.hijo.controles.notas_en.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                            </FormRow>
                                                            
                                                            <FormRow>
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioPrin.idequ  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentosenc.hijo.controles.idequ.id }    
                                                                    name=        { confPantalla.documentosenc.hijo.controles.idequ.name }   
                                                                    label=       { confPantalla.documentosenc.hijo.controles.idequ.label }    
                                                                    placeholder= { confPantalla.documentosenc.hijo.controles.idequ.placeholder }
                                                                    col=         { confPantalla.documentosenc.hijo.controles.idequ.col } 
                                                                    isClearable= { confPantalla.documentosenc.hijo.controles.idequ.isclearable }
                                                                    isSearchable={ confPantalla.documentosenc.hijo.controles.idequ.issearchable }
                                                                    dataType=    { confPantalla.documentosenc.hijo.controles.idequ.datatype }
                                                                    data=        { confPantalla.documentosenc.hijo.controles.idequ.data }
                                                                    required=    { confPantalla.documentosenc.hijo.controles.idequ.required } 
                                                                    disabled=  { confPantalla.documentosenc.hijo.controles.idequ.disabled }
                                                                    readOnly =   { confPantalla.documentosenc.hijo.controles.idequ.readonly }
                                                                    disablethisid={confPantalla.documentosenc.hijo.controles.idequ.disablethisid}
                                                                    disableids={confPantalla.documentosenc.hijo.controles.idequ.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                /> 
                                                            </FormRow>
                                                            <FormRow >
                                                                <MiSelect  
                                                                    estadoForm=  { estadoFormHijo }
                                                                    val=         { formularioHijo.idubi == "0" || formularioHijo.idubi == "" ? formularioPrin.idubi : formularioHijo.idubi  }   
                                                                    loading=     { loadReg ? true : false }
                                                                    id=          { confPantalla.documentosenc.hijo.controles.idubi.id }    
                                                                    name=        { confPantalla.documentosenc.hijo.controles.idubi.name }   
                                                                    label=       { confPantalla.documentosenc.hijo.controles.idubi.label }    
                                                                    placeholder= { confPantalla.documentosenc.hijo.controles.idubi.placeholder }
                                                                    col=         { confPantalla.documentosenc.hijo.controles.idubi.col } 
                                                                    isClearable= { confPantalla.documentosenc.hijo.controles.idubi.isclearable }
                                                                    isSearchable={ confPantalla.documentosenc.hijo.controles.idubi.issearchable }
                                                                    dataType=    { confPantalla.documentosenc.hijo.controles.idubi.datatype }
                                                                    data=        { confPantalla.documentosenc.hijo.controles.idubi.data }
                                                                    required=    { confPantalla.documentosenc.hijo.controles.idubi.required } 
                                                                    disabled=  { confPantalla.documentosenc.hijo.controles.idubi.disabled }
                                                                    readOnly =   { confPantalla.documentosenc.hijo.controles.idubi.readonly }
                                                                    disablethisid={confPantalla.documentosenc.hijo.controles.idubi.disablethisid}
                                                                    disableids={confPantalla.documentosenc.hijo.controles.idubi.disableids}
                                                                    formulario= { formularioHijo }   
                                                                    change= { handleSelectFormHijo }
                                                                />
                                                                {
                                                                    formularioPrin.tipostock == "4"
                                                                    ?
                                                                    <MiSelect  
                                                                        estadoForm=  { estadoFormHijo }
                                                                        val=         { formularioPrin.idubi2  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.documentosenc.hijo.controles.idubi2.id }    
                                                                        name=        { confPantalla.documentosenc.hijo.controles.idubi2.name }   
                                                                        label=       { confPantalla.documentosenc.hijo.controles.idubi2.label }    
                                                                        placeholder= { confPantalla.documentosenc.hijo.controles.idubi2.placeholder }
                                                                        col=         { confPantalla.documentosenc.hijo.controles.idubi2.col } 
                                                                        isClearable= { confPantalla.documentosenc.hijo.controles.idubi2.isclearable }
                                                                        isSearchable={ confPantalla.documentosenc.hijo.controles.idubi2.issearchable }
                                                                        dataType=    { confPantalla.documentosenc.hijo.controles.idubi2.datatype }
                                                                        data=        { confPantalla.documentosenc.hijo.controles.idubi2.data }
                                                                        required=    { confPantalla.documentosenc.hijo.controles.idubi2.required } 
                                                                        disabled=  { confPantalla.documentosenc.hijo.controles.idubi2.disabled }
                                                                        readOnly =   { confPantalla.documentosenc.hijo.controles.idubi2.readonly }
                                                                        disablethisid={confPantalla.documentosenc.hijo.controles.idubi2.disablethisid}
                                                                        disableids={confPantalla.documentosenc.hijo.controles.idubi2.disableids}
                                                                        formulario= { formularioHijo }   
                                                                        change= { handleSelectFormHijo }
                                                                    />
                                                                    :
                                                                    null
                                                                }                 
                                                            </FormRow>
                                                        </Tab>
                                                        <Tab eventKey="tab1_2" title={ confPantalla.documentosenc.tabs.tab2.subtabs.tab23.titulo }>
                                                            <FormRow>                                                                
                                                                <UplmPlus
                                                                    id={ confPantalla.documentosenc.hijo.controles.uplmplus.id }
                                                                    adjuntos={ adjuntosHijo }
                                                                    saveAdjuntos= { saveAdjuntosHijo }  
                                                                    adjuntosExistentes= { adjuntosExistentesHijo } 
                                                                    saveAdjuntosExistentes= { saveAdjuntosExistentesHijo }
                                                                    estadoForm= { estadoFormHijo }
                                                                    loading= { loadReg ? true : false }
                                                                    col={12}
                                                                    readonly={ confPantalla.documentosenc.hijo.controles.uplmplus.readonly }
                                                                    traduction={confPantalla.config.trad.components}                                                            
                                                                    datatable={ confPantalla.documentosenc.hijo.controles.uplmplus.datatable }
                                                                    select={ confPantalla.documentosenc.hijo.controles.uplmplus.select }
                                                                />
                                                            </FormRow> 
                                                        </Tab>

                                                        {/* Campos ocultos  */}
                                                        <FormRow clases="noDisplay">
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormHijo }
                                                                val=         { formularioHijo.idage1  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.documentosenc.hijo.controles.idage1.id }    
                                                                name=        { confPantalla.documentosenc.hijo.controles.idage1.name }   
                                                                label=       { confPantalla.documentosenc.hijo.controles.idage1.label }    
                                                                placeholder= { confPantalla.documentosenc.hijo.controles.idage1.placeholder }
                                                                col=         { confPantalla.documentosenc.hijo.controles.idage1.col } 
                                                                isClearable= { confPantalla.documentosenc.hijo.controles.idage1.isclearable }
                                                                isSearchable={ confPantalla.documentosenc.hijo.controles.idage1.issearchable }
                                                                dataType=    { confPantalla.documentosenc.hijo.controles.idage1.datatype }
                                                                data=        { confPantalla.documentosenc.hijo.controles.idage1.data }
                                                                required=    { confPantalla.documentosenc.hijo.controles.idage1.required } 
                                                                disabled=  { confPantalla.documentosenc.hijo.controles.idage1.disabled }
                                                                readOnly =   { confPantalla.documentosenc.hijo.controles.idage1.readonly }
                                                                disablethisid={confPantalla.documentosenc.hijo.controles.idage1.disablethisid}
                                                                disableids={confPantalla.documentosenc.hijo.controles.idage1.disableids}
                                                                formulario= { formularioHijo }   
                                                                change= { handleSelectFormHijo }
                                                            />
                                                            
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            <FormInput 
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo.txt1 }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentosenc.hijo.controles.txt1.id }   
                                                                type=       { confPantalla.documentosenc.hijo.controles.txt1.type }  
                                                                name=       { confPantalla.documentosenc.hijo.controles.txt1.name }   
                                                                label=      { confPantalla.documentosenc.hijo.controles.txt1.label }                                                       
                                                                placeholder={ confPantalla.documentosenc.hijo.controles.txt1.placeholder }    
                                                                col=        { confPantalla.documentosenc.hijo.controles.txt1.col } 
                                                                required=   { confPantalla.documentosenc.hijo.controles.txt1.required }
                                                                readonly=   { confPantalla.documentosenc.hijo.controles.txt1.readonly }                                                
                                                                disabled=   { confPantalla.documentosenc.hijo.controles.txt1.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                            />
                                                            <FormInput 
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo.txt2 }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentosenc.hijo.controles.txt2.id }   
                                                                type=       { confPantalla.documentosenc.hijo.controles.txt2.type }  
                                                                name=       { confPantalla.documentosenc.hijo.controles.txt2.name }   
                                                                label=      { confPantalla.documentosenc.hijo.controles.txt2.label }                                                       
                                                                placeholder={ confPantalla.documentosenc.hijo.controles.txt2.placeholder }    
                                                                col=        { confPantalla.documentosenc.hijo.controles.txt2.col } 
                                                                required=   { confPantalla.documentosenc.hijo.controles.txt2.required }
                                                                readonly=   { confPantalla.documentosenc.hijo.controles.txt2.readonly }                                                
                                                                disabled=   { confPantalla.documentosenc.hijo.controles.txt2.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                            />
                                                            <FormInput 
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo.txt3 }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentosenc.hijo.controles.txt3.id }   
                                                                type=       { confPantalla.documentosenc.hijo.controles.txt3.type }  
                                                                name=       { confPantalla.documentosenc.hijo.controles.txt3.name }   
                                                                label=      { confPantalla.documentosenc.hijo.controles.txt3.label }                                                       
                                                                placeholder={ confPantalla.documentosenc.hijo.controles.txt3.placeholder }    
                                                                col=        { confPantalla.documentosenc.hijo.controles.txt3.col } 
                                                                required=   { confPantalla.documentosenc.hijo.controles.txt3.required }
                                                                readonly=   { confPantalla.documentosenc.hijo.controles.txt3.readonly }                                                
                                                                disabled=   { confPantalla.documentosenc.hijo.controles.txt3.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                            />
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">                                                        
                                                            <FormInput 
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo.num3 }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentosenc.hijo.controles.num3.id }   
                                                                type=       { confPantalla.documentosenc.hijo.controles.num3.type }  
                                                                name=       { confPantalla.documentosenc.hijo.controles.num3.name }   
                                                                label=      { confPantalla.documentosenc.hijo.controles.num3.label }                                                       
                                                                placeholder={ confPantalla.documentosenc.hijo.controles.num3.placeholder }    
                                                                col=        { confPantalla.documentosenc.hijo.controles.num3.col } 
                                                                required=   { confPantalla.documentosenc.hijo.controles.num3.required }
                                                                readonly=   { confPantalla.documentosenc.hijo.controles.num3.readonly }                                                
                                                                disabled=   { confPantalla.documentosenc.hijo.controles.num3.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                            />                                                           
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormHijo }
                                                                val=         { formularioPrin.idapa  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.documentosenc.hijo.controles.idapa.id }    
                                                                name=        { confPantalla.documentosenc.hijo.controles.idapa.name }   
                                                                label=       { confPantalla.documentosenc.hijo.controles.idapa.label }    
                                                                placeholder= { confPantalla.documentosenc.hijo.controles.idapa.placeholder }
                                                                col=         { confPantalla.documentosenc.hijo.controles.idapa.col } 
                                                                isClearable= { confPantalla.documentosenc.hijo.controles.idapa.isclearable }
                                                                isSearchable={ confPantalla.documentosenc.hijo.controles.idapa.issearchable }
                                                                dataType=    { confPantalla.documentosenc.hijo.controles.idapa.datatype }
                                                                data=        { confPantalla.documentosenc.hijo.controles.idapa.data }
                                                                required=    { confPantalla.documentosenc.hijo.controles.idapa.required } 
                                                                disabled=  { confPantalla.documentosenc.hijo.controles.idapa.disabled }
                                                                readOnly =   { confPantalla.documentosenc.hijo.controles.idapa.readonly }
                                                                disablethisid={confPantalla.documentosenc.hijo.controles.idapa.disablethisid}
                                                                disableids={confPantalla.documentosenc.hijo.controles.idapa.disableids}
                                                                formulario= { formularioHijo }   
                                                                change= { handleSelectFormHijo }
                                                            />
                                                        </FormRow>                                                                                                                                                                                                                                                                                   
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iin"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iin.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.iin.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iin.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iin.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iin.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iin.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iin.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iin.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iin.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iin"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iin.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iin.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iin.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iin.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iin.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iin.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iin.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iin.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />

                                                            }
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormHijo }
                                                                value=      { formularioHijo["valoracion.cos"] }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.documentosenc.hijo.controles.cos.id }   
                                                                type=       { confPantalla.documentosenc.hijo.controles.cos.type }  
                                                                name=       { confPantalla.documentosenc.hijo.controles.cos.name }   
                                                                label=      { confPantalla.documentosenc.hijo.controles.cos.label }                                                       
                                                                placeholder={ confPantalla.documentosenc.hijo.controles.cos.placeholder }    
                                                                col=        { confPantalla.documentosenc.hijo.controles.cos.col } 
                                                                required=   { confPantalla.documentosenc.hijo.controles.cos.required }
                                                                readonly=   { confPantalla.documentosenc.hijo.controles.cos.readonly }                                                
                                                                disabled=   { confPantalla.documentosenc.hijo.controles.cos.disabled }
                                                                change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                onBlur=     {null} 
                                                                alignRight= { true }
                                                            />                                           
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.und"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.und.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.und.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.und.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.und.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.und.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.und.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.und.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.und.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.und.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.und"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.und.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.und.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.und.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.und.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.und.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.und.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.und.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.und.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.pre"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.pre.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.pre.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.pre.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.pre.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.pre.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.pre.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.pre.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.pre.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.pre.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />                                           
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.pre"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.pre.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.pre.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.pre.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.pre.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.pre.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.pre.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.pre.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.pre.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.imp1"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.imp1.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.imp1.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.imp1.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.imp1.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.imp1.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.imp1.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.imp1.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.imp1.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.imp1.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.imp1"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.imp1.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.imp1.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.imp1.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.imp1.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.imp1.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.imp1.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.imp1.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.imp1.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.mar1"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.mar1.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.mar1.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.mar1.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.mar1.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.mar1.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.mar1.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.mar1.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.mar1.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.mar1.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.mar1"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.mar1.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.mar1.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.mar1.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.mar1.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.mar1.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.mar1.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.mar1.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.mar1.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.dto"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.dto.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.dto.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.dto.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.dto.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.dto.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.dto.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.dto.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.dto.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.dto.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.dto"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.dto.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.dto.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.dto.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.dto.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.dto.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.dto.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.dto.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.dto.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.impd"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.impd.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.impd.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.impd.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.impd.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.impd.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.impd.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.impd.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.impd.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.impd.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.impd"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.impd.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.impd.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.impd.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.impd.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.impd.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.impd.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.impd.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.impd.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.imp2"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.imp2.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.imp2.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.imp2.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.imp2.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.imp2.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.imp2.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.imp2.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.imp2.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.imp2.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.imp2"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.imp2.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.imp2.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.imp2.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.imp2.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.imp2.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.imp2.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.imp2.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.imp2.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.mar2"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.mar2.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.mar2.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.mar2.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.mar2.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.mar2.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.mar2.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.mar2.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.mar2.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.mar2.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.mar2"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.mar2.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.mar2.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.mar2.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.mar2.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.mar2.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.mar2.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.mar2.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.mar2.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iva"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iva.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.iva.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iva.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iva.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iva.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iva.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iva.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iva.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iva.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iva"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iva.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iva.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iva.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iva.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iva.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iva.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iva.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iva.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iiv"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iiv.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.iiv.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iiv.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iiv.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iiv.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iiv.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iiv.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iiv.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iiv.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iiv"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iiv.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iiv.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iiv.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iiv.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iiv.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iiv.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iiv.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iiv.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.rec"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.rec.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.rec.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.rec.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.rec.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.rec.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.rec.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.rec.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.rec.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.rec.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null}
                                                                        alignRight= { true } 
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.rec"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.rec.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.rec.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.rec.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.rec.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.rec.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.rec.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.rec.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.rec.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iir"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iir.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.iir.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iir.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iir.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iir.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iir.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iir.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iir.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iir.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iir"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iir.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iir.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iir.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iir.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iir.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iir.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iir.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iir.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.ret"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.ret.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.ret.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.ret.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.ret.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.ret.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.ret.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.ret.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.ret.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.ret.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.ret"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.ret.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.ret.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.ret.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.ret.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.ret.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.ret.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.ret.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.ret.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iit"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iit.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.iit.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iit.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iit.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iit.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iit.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iit.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iit.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iit.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.iit"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.iit.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.iit.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.iit.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.iit.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.iit.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.iit.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.iit.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.iit.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.imp3"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.imp3.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.imp3.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.imp3.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.imp3.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.imp3.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.imp3.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.imp3.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.imp3.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.imp3.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    />
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.imp3"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.imp3.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.imp3.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.imp3.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.imp3.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.imp3.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.imp3.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.imp3.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.imp3.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                            {
                                                                cookies.get('nivel') === "1"
                                                                ?
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.mar3"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.mar3.id }   
                                                                        type=       { confPantalla.documentosenc.hijo.controles.mar3.type }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.mar3.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.mar3.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.mar3.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.mar3.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.mar3.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.mar3.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.mar3.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                        alignRight= { true }
                                                                    /> 
                                                                :
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormHijo }
                                                                        value=      { formularioHijo["valoracion.mar3"] }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.documentosenc.hijo.controles.mar3.id }   
                                                                        type=       { "hidden" }  
                                                                        name=       { confPantalla.documentosenc.hijo.controles.mar3.name }   
                                                                        label=      { confPantalla.documentosenc.hijo.controles.mar3.label }                                                       
                                                                        placeholder={ confPantalla.documentosenc.hijo.controles.mar3.placeholder }    
                                                                        col=        { confPantalla.documentosenc.hijo.controles.mar3.col } 
                                                                        required=   { confPantalla.documentosenc.hijo.controles.mar3.required }
                                                                        readonly=   { confPantalla.documentosenc.hijo.controles.mar3.readonly }                                                
                                                                        disabled=   { confPantalla.documentosenc.hijo.controles.mar3.disabled }
                                                                        change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                        onBlur=     {null} 
                                                                    />
                                                            }
                                                        </FormRow>
                                                        <FormRow clases="noDisplay">
                                                            <Recurrencia
                                                                estadoForm=        { estadoFormHijo }
                                                                loading =          { loadReg ? true : false }
                                                                formulario =       { formularioHijo }
                                                                changeSelect =     { handleSelectFormHijo }
                                                                saveFormulario =   { saveFormularioHijo }
                                                                handleInput =      { handleInputForm }                                                        
                                                                traduction=        { confPantalla.config.trad.components.recurrencia }
                                                                noDisplay=         { true }
                                                            /> 
                                                        </FormRow>
                                                        {/* FIN: Campos ocultos */}
                                                    </Tabs>
                                                </Formulario>
                                                {/* <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                                    <Alert />
                                                    {   loadReg ? 
                                                        <Spinner animation="border" />
                                                        :
                                                        null
                                                    }                                
                                                </div> */}
                                                {
                                                    formularioPrin.idtip == 21 || formularioPrin.idtip == 22
                                                    ?
                                                        <MiDataTable 
                                                            estadoForm= { "readonly" }
                                                            formulario={formularioHijo} 
                                                            id={"documentosencHijo"}
                                                            columnas={confPantalla.documentosenc.hijo.datatablechecklist.columns}
                                                            data={registrosHijo}
                                                            buttons={confPantalla.documentosenc.hijo.datatablechecklist.buttons}
                                                            ordering={confPantalla.documentosenc.hijo.datatablechecklist.ordering}
                                                            order={confPantalla.documentosenc.hijo.datatablechecklist.order}
                                                            searching={confPantalla.documentosenc.hijo.datatablechecklist.searching}
                                                            paging={confPantalla.documentosenc.hijo.datatablechecklist.paging}
                                                            select={confPantalla.documentosenc.hijo.datatablechecklist.select}
                                                            pagelength={confPantalla.documentosenc.hijo.datatablechecklist.pagelength}
                                                            handleEditReg= { e => handleEditRegHijo(e, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.tabla) }
                                                            handleDeleteItem={ e => handleDeleteItemHijo(e, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.hijo.tabla, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title2) }
                                                            saveLoadingTable={ saveLoadingTable }
                                                            loadingTable={loadingTable}
                                                            columnactions={confPantalla.documentosenc.hijo.datatablechecklist.columnactions.title}
                                                            traduction={confPantalla.config.trad.components.datatable}
                                                        />
                                                    :
                                                        <MiDataTable 
                                                            estadoForm= { "readonly" }
                                                            formulario={formularioHijo} 
                                                            id={"documentosencHijo"}
                                                            columnas={confPantalla.documentosenc.hijo.datatable.columns}
                                                            data={registrosHijo}
                                                            buttons={confPantalla.documentosenc.hijo.datatable.buttons}
                                                            ordering={confPantalla.documentosenc.hijo.datatable.ordering}
                                                            order={confPantalla.documentosenc.hijo.datatable.order}
                                                            searching={confPantalla.documentosenc.hijo.datatable.searching}
                                                            paging={confPantalla.documentosenc.hijo.datatable.paging}
                                                            select={confPantalla.documentosenc.hijo.datatable.select}
                                                            pagelength={confPantalla.documentosenc.hijo.datatable.pagelength}
                                                            handleEditReg= { e => handleEditRegHijo(e, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.tabla) }
                                                            handleDeleteItem={ e => handleDeleteItemHijo(e, confPantalla.documentosenc.hijo.urls, confPantalla.documentosenc.hijo.modelform, confPantalla.documentosenc.hijo.tabla, confPantalla.documentosenc.padre.urls, confPantalla.documentosenc.padre.tabla, confPantalla.documentosenc.title2) }
                                                            saveLoadingTable={ saveLoadingTable }
                                                            loadingTable={loadingTable}
                                                            columnactions={confPantalla.documentosenc.hijo.datatable.columnactions.title}
                                                            traduction={confPantalla.config.trad.components.datatable}
                                                        />                              
                                                }
                                            </Tab>
                                        :
                                        null
                                    }
                                    
                                </Tabs> 
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Documentosencenc