import React, { useState, useEffect, Fragment, useContext } from 'react';

import axios from 'axios';

import { LoginContext } from '../../../hooks/context/LoginContext';

import ButtonOutline from '../../ui/ButtonOutline';
import FormInput from '../../formulario/FormInput';
import MiSelect from '../../formulario/MiSelect';
import FormRow from '../../formulario/FormRow';
import TextArea from '../../formulario/TextArea';
import Uplm from '../../formulario/Uplm/Uplm';
import CheckIos from '../../ui/CheckIos';
import { Spinner } from 'react-bootstrap';
import { alertService } from '../../../services/alertservice';
import swal from 'sweetalert';
import { Accordion, Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from '../../ui/Button';
import MiDataTable from '../../ui/MiDataTable';


import Cookies from 'universal-cookie';

import { MiReplaceAll, getFechaHoraActualSql, getIndexUrl, getApiUrl, axiosPost } from '../../../helpers/helperApp';

function WizardCheckListStep(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();


    const { key, cabecera, paso, pasos, linea, respuesta, saveCurrentStep, controles, estadoForm, adjuntos, adjuntosExistentes, confPantalla, saveLoadReg, propiedades, regAnterior, saveRegAnterior, resumenHerramientas, resumenMateriales, loadingForms, saveLoadingForms, adjuntosCabecera, nomEquipo } = props;

    const [ formulario, saveFormulario ] = useState(null);
    const [ confControles, saveConfControles ] = useState(null);
    const [ nomUni, saveNomUni ] = useState();
    const [ camposRequired, saveCamposRequired ] = useState();
    const [ configuracionLinea, saveConfiguracionLinea ] = useState("");

    const [ actualAdjuntos, saveActualAdjuntos ] = useState(adjuntos);
    const [ actualAdjuntosExistentes, saveActualAdjuntosExistentes ] = useState(adjuntosExistentes);

    const [ fecDocIn, saveFecDocIn ] = useState(getFechaHoraActualSql());

    const [ acordeon, saveAcordeon ] = useState(null);

    const [ showModal, saveShowModal ] = useState(false);
    const [ fec1, saveFec1 ] = useState("");
    const [ fec3, saveFec3 ] = useState("");
    const [ estableciendoFechas, saveEstableciendoFechas ] = useState();
    const [ btnAceptar, saveBtnAceptar ] = useState(false);
    const [ btnRevisar, saveBtnRevisar ] = useState(false);
    const [ docFinalizado, saveDocFinalizado ] = useState(false);
    
    saveCurrentStep(props.currentStep-1);

    const { apiKey, menuMode } = useContext(LoginContext);

    useEffect( () => { 

        const getConfUni = async (respuesta)=> {            
            if( respuesta.data.length > 0 ){
                
                let lineaValida = true;
                // Hay configuración para línea
                if( respuesta.data[0].val == "" ){
                    // Si la configuración para este tipo está en blanco actuamos como si fuese de tipo 0.NADA (DETECTAR SIGUIENTE) 
                    saveConfControles(["0"]);
                }else{
                    
                    // Si no hay el control cbo artículo en la configuración mostramos el nombre junto a la descripción de la línea.
                    if( !respuesta.data[0].val.split(";").includes("9") ){
                        saveNomUni(`${linea.nomart}`)
                    }
                    
                    // Guardamos la configuración para esta unidad
                    saveConfControles(respuesta.data[0].val.split(";"));

                    let auxRequired = [];
                    respuesta.data[0].val.split(";").map((e) => {

                        if( (e >= 0 && e <=14) || (e >= 21 && e <=21) ){                                                       
                        }else{                            
                             if( e.includes("SL") ){
                                 let soloLec = MiReplaceAll(e, "SL", "");
                                 if( (soloLec >= 0 && soloLec <=14) || soloLec == 21  ){
                                 }else{
                                    lineaValida = false;
                                    return;
                                 }
                             }else if( e.includes("R") ){
                                 let req = MiReplaceAll(e, "R", "");
                                 if( (req >= 0 && req <=14) || req == 21 ){
                                    auxRequired.push(req);
                                 }else{
                                    lineaValida = false;
                                    return;
                                 }
                             }else{
                                lineaValida = false;
                                return;
                             }
                        }
                    })
                    if( !lineaValida ){
                        saveConfControles(["0"]);
                    }else{
                        saveCamposRequired(auxRequired);

                        // Guardamos la configuración de la línea, si es válida, para enviarla en el post y poder comprobar en el backend por las líneas que no pasamos
                        saveConfiguracionLinea(respuesta.data[0].val)
                    }
                }

                await getLinea(linea.id);
              
            }else{
                // Para esta linea no hay configuración actuamos como si fuese de tipo 0.NADA (DETECTAR SIGUIENTE) 
                saveConfControles(["0"]);
                saveFormulario(linea);
            }

            saveLoadingForms(false);
        }
    
        getConfUni(respuesta);

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        }
        
    },[])

    const getLinea = async(id, reload=null) => {     
        
        
        // Obtenemos la línea y sus adjuntos
        const data2 = new FormData();
        data2.append("validacion", apiKey());
        data2.append("id", id);
        // const respuesta2 = await axios.post(getApiUrl(window.location.origin)+controles.wizardchecklist.getlinea, data2);
        const respuesta2 = await axiosPost(getApiUrl(window.location.origin)+controles.wizardchecklist.getlinea, data2, source.token);

        // Guardamos la linea en el state y sus adjuntos tambien
        if( respuesta2.data[0] ){
            if( reload ){
                saveRegAnterior(respuesta2.data[0]);
            }
            saveFormulario(respuesta2.data[0]);
          }
          if( respuesta2.data["adjuntos"] ){
              saveActualAdjuntosExistentes(respuesta2.data["adjuntos"]);
          }
    }
    

    const next = async (e) => { 

        const cumpleRequired = validateRequiredFields(camposRequired);

        if( cumpleRequired == null ){
            saveLoadReg(true);
    
            // preparamos los datos del formulario para enviar
            const data = new FormData();
            data.append("validacion", apiKey());
            data.append("confLinea", configuracionLinea);
            data.append("checkList", true);
            // if( paso == pasos.length ){
            if( paso == pasos ){
                data.append("ultimoPaso", true);
            }
    
            
            
            // Añadimos al data los campos del formulario que aparezcan en el modelform
            for(const key in formulario){
                if( confPantalla.documentostrab.hijo.modelform[key] != undefined){
                    if( key != "fec1" && key != "fec3" ){
                        data.append( key, formulario[key] );
                    }else{
                        var fecAux;        
                        if( paso == 1 ){
                            // Para el primer paso la fecha de entrada es la de entrada al componente al generarse todas las líneas y la de salida la actual
                            fecAux = fecDocIn;        
                        }else{
                            // Para el resto de pasos la fecha de entrada es la de salida del paso anterior   
                            fecAux = regAnterior.fec3wizard;
                        }
                        
                        if( key == "fec1" ){
                            data.append( key, fecDocIn );
                            // Si lo dejo así solo actualiza la fecha de entrada si está vacía. Con lo que si un operario vuelve a entrar al trabajo por lo que sea, habrá mucha diferencia entra la fecha de entrada y la de salida porque dejará la de entrada con la de la primera vez que entró y la de salida pondrá la de la última vez que salió. La de salida está correcta
                            if( formulario.fec1 == "0000-00-00T00:00" || formulario.fec1 == "0000-00-00T00:00:00" ){
                                data.append( key, fecAux );
                            }else{
                                data.append( key, formulario[key] );
                            }
                            // Si lo dejo así si la fechaAux(que sería la de salida del registro anterior, asumimos esto para poder detectar la fecha de entrada en cada paso ya que el wizard) se guardará en la fecha de entrada si está vacía o si la fechaAux es más reciente, con lo que sería más real la fecha de entrada ya que sería la de salida del paso anterior.
                            // if( formulario[key] < fecAux || formulario.fec1 == "0000-00-00T00:00" || formulario.fec1 == "0000-00-00T00:00:00" ){
                            //     data.append( key, fecAux );
                            // }else{
                            //     data.append( key, formulario[key] );
                            // }
                        }
                        if( key == "fec3" ){
                            data.append( key, getFechaHoraActualSql() );                            
                        }
                    }

                }
            }
            
            // Añadimos al data los adjuntos existentes
            const filesExist = document.querySelectorAll(`div[currentstep='${props.currentStep}'] textarea.fileExist`);
            if( filesExist ){
                for( let i = 0; i < filesExist.length; i++ ){
                    data.append( filesExist[i].name, filesExist[i].value );
                }
            }
    
            // Añadimos al data los adjuntos nuevos
            for(let i = 0; i < actualAdjuntos.length; i++ ){
                data.append(actualAdjuntos[i]["id"]+"[]", actualAdjuntos[i]);
            }
    
            // Añadimos al data las descripciones de los nuevo files
            const fileNew = document.querySelectorAll(`div[currentstep='${props.currentStep}'] textarea.fileNew`);
            if( fileNew ){
                for( let i = 0; i < fileNew.length; i++ ){
                    data.append( fileNew[i].name, fileNew[i].value );
                }
            }
    
            // enviamos la petición
            const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.documentostrab.hijo.urls.submit,data,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if( respuesta.data == formulario.id ){
                // Volvemos a cargar el registro modificado para actualizar el control de adjuntos
                await getLinea(formulario.id, true);
                // Reiniciamos los adjuntos pendientes
                saveActualAdjuntos([]);
                // Pasamos a la siguiente línea
                props.nextStep();
    
                // Si completó todos los pasos lo redirigimos al inicio
                // if( paso == pasos.length ){
                if( paso == pasos ){
                    // Modificación 14-12-2022: Al finalizar el wizard preguntar las fechas de inicio y fin
                    saveFec1("");
                    saveFec3("");
                    saveBtnAceptar(false);
                    saveBtnRevisar(false);
                    saveDocFinalizado(false);
                    saveShowModal(true);                                     
                }
            }else if( typeof respuesta.data === "string" ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${cumpleRequired} ${confPantalla.config.trad.components.wizard.saveerror.title}`, {autoClose: true});
            }
    
            saveLoadReg(false);
        }else{
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${cumpleRequired} ${confPantalla.config.trad.components.wizard.required.title}`, {autoClose: true});
        }


    }

    const accionModal = async() => {

        let fecha1 = fec1;
        let fecha3 = fec3;
        let sinFechas = false;
        let continuar = false;

        if( fecha1 == "" && fecha3 == "" ){
            sinFechas = true;
        }
        if( sinFechas == false ){
            if( fecha1 == "" && fecha3 != "" ){
                fecha1 = fecha3;
            }
            if( fecha3 == "" && fecha1 != "" ){
                fecha3 = fecha1;
            }
        }
        saveEstableciendoFechas(true);

        if( sinFechas == false ){
            const data = new FormData();
            data.append("validacion", apiKey());
            data.append("iddoc", cabecera.id);
            data.append("fec1", fecha1);
            data.append("fec3", fecha3);
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.documentostrab.hijo.controles.modalwizard.action, data, source2.token);
            if( respuesta.data == cabecera.id ){
                continuar = true;
            }else if(typeof respuesta.data == "string"){
                alertService.error(`${respuesta.data}`, {autoClose: false});
            }
        }

        saveEstableciendoFechas(false);
        // saveShowModal(false);

        if( sinFechas == true || continuar == true ){
            saveBtnAceptar(true);
            saveBtnRevisar(true);
            saveDocFinalizado(true);            
        }

    }

    const accionBtnRevisarModal = (e) => {
        saveShowModal(false);
    }

    const accionBtnAceptarModal = (e) => {
        propiedades.history.push(getIndexUrl(menuMode));
    }

    const validateRequiredFields = (reqFields) => {
        let error = null;
        if( reqFields ){            
            reqFields.map((field) => {
                if( error == null){
                    switch(field) {
                        case "1":
                            // 1.SI/NO (DETECTAR RESPUESTA). Si es distinto de realizado o no realizado no lo damos por válido
                            if( formulario.idest != 9 && formulario.idest != 12 ){
                                error =  controles.idest.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "2":
                            // 2.ESTADO - CBO ESTADO 
                            if( formulario.idest == "" || formulario.idest == "0" ){
                                error = controles.idest.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "3":
                            // 3.CANTIDAD TXT CANTIDAD
                            if( formulario.num1 == "" ){
                                error = controles.num1.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "4":
                            // 4.HORAS/KMS - TXT KMS/HORAS
                            if( formulario.num2 == "" ){
                                error = controles.num2.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "5":
                            // 5.NOTAS - EDT NOTAS/NOTES
                            if( cookies.get("idioma") == "es" ){
                                if( formulario.notas == "" ){
                                    error = controles.notas.label;
                                }else{
                                    error = null;
                                }
                            }else if( cookies.get("idioma") == "en" ){
                                if( formulario.notas_en == "" ){
                                    error = controles.notas_en.label;
                                }else{
                                    error = null;
                                }
                            }
                          break;
                        case "6":
                            // 6.SACAR FOTO - PEDIR CAPTURA DE UNO O VARIOS ADJUNTOS
                            if( actualAdjuntos.length == 0 ){
                                error = confPantalla.config.trad.components.uplm.btnadd.title;
                            }else{
                                error = null;
                            }
                          break;
                        case "8":
                            // 8. REFERENCIA CLIENTE / LISTA_REFERENCIA / POSCION - MOSTRAR/OCULTAR SIEMPRE TODAS
                            if( formulario.txt1 == "" && formulario.txt2 == "" && formulario.txt3 == "" ){
                                error = controles.txt1.label+"/"+controles.txt2.label+"/"+controles.txt3.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "9":
                            // 9. ARTICULO - CBO ARTICULO
                            if( formulario.iduni == "" || formulario.iduni == "0"  ){
                                error = controles.iduni.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "13":
                            // 13.FECHA PREVISION - SOLO FECHA
                            if( formulario.fec2 == "" || formulario.fec2 == "0000-00-00T00:00" ){
                                error = controles.fec2.label;
                            }else{
                                error = null;
                            }
                          break;
                        case "14":
                            // 14.FECHA PREVISION - SOLO FECHA
                            if( formulario.fec2 == "" || formulario.fec2 == "0000-00-00T00:00" ){
                                error = controles.fec2.label;
                            }else{
                                error = null;
                            }
                          break;                                   
                    }
                }
                
            })
        }

        return error;

    }

    const previous = async () => {        
        props.previousStep();
    }

    const handleInputFecModal = (e, saveFec) => {
        const { name, value } = e.target;
        saveFec(value)       
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        saveFormulario({ ...formulario, [name]:value })       
    }

    const handleSelect = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveFormulario({ ...formulario, [nameOfComponent.name]:selectedItem.value })  
        }else{
            saveFormulario({ ...formulario, [nameOfComponent.name]:"" })
        }
    }

    const handleCheck = (e) => {        

        if( e.target.checked ){
            // Ponemos Estado realizado
            saveFormulario({ ...formulario, idest : 9 })
        }else{
            // Ponemos Estado No realizado
            saveFormulario({ ...formulario, idest : 12 })
        }
    }

    const toogleAcordeon = (id) => {

        if( acordeon == id ){
            saveAcordeon(null);
        }else{
            saveAcordeon(id);
        }                
    }

    return(
        <div
            currentStep={paso}                        
        >
        {
            loadingForms 
            ?
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            :
             null
        }
            {
                formulario != null
                ?
                    <Fragment>
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0')} className={ acordeon == "0" ? "activeAcordeon" : "" } >
                                    {
                                        cookies.get("idioma") == "es"
                                        ?
                                                cabecera.nom1
                                            :
                                                cookies.get("idioma") == "en"
                                                ?
                                                    cabecera.nom1_en
                                                :
                                                    cabecera.nom1.label+" - "+cabecera.nom1_en.label
                                    }
                                    {
                                        acordeon == "0"
                                        ?
                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                        :
                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                    }                                            
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                    <Card.Body>
                                        {
                                            cookies.get("idioma") == "es"
                                            ?
                                                <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas}</div>
                                                :
                                                    cookies.get("idioma") == "en"
                                                    ?
                                                        <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas_en}</div>
                                                    :
                                                        <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas} <br/> {cabecera.notas_en}</div>
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => toogleAcordeon('3')} className={ acordeon == "3" ? "activeAcordeon" : "" } >
                                            { controles.wizardchecklist.acordeon.adjuntos.title } - {"("+adjuntosCabecera.length+")"}
                                            {
                                                acordeon == "3"
                                                ?
                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                :
                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                            }                                            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3" className={"slowAcordeon"} >
                                            <Card.Body>
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"adjuntos"+paso}
                                                    columnas={[{"title":controles.wizardchecklist.datatableadjuntos.columnas.title, "data": "html"}]}
                                                    data={adjuntosCabecera}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                            </div>
                        </Accordion>
                        <div className="alert alert-dark text-center" style={{borderColor: "#ccc"}} role="alert">
                            {
                                cookies.get("idioma") == "es"
                                ?
                                        <div>
                                            <h5>{formulario.txt1} {formulario.txt1 != "" ? "-" : "" } {formulario.nom1}</h5>
                                            <h5>{ nomEquipo }</h5>
                                        </div>
                                    :
                                        cookies.get("idioma") == "en"
                                        ?
                                            <div>
                                                <h5>{formulario.txt1} {formulario.txt1 != "" ? "-" : "" } {formulario.nom1_en}</h5>
                                                <h5>{ nomEquipo }</h5>
                                            </div>
                                        :
                                            <div>
                                                <h5>{formulario.txt1} {formulario.txt1 != "" ? "-" : "" } {formulario.nom1} - {formulario.nom1_en}</h5>
                                                <h5>{ nomEquipo }</h5>
                                            </div>
                            }
                        </div>
                    </Fragment>
                :
                    null
            } 
            {
                formulario != null && confControles != null
                ?
                    <Fragment>

                        <FormRow>
                        {
                            <Fragment>
                                    {                                        
                                        confControles.includes("6")
                                        ?
                                            <Uplm
                                                estadoForm= { estadoForm }
                                                loading=    { false }
                                                col={12}
                                                id={controles.uplm.id}
                                                adjuntos={ actualAdjuntos }
                                                saveAdjuntos= { saveActualAdjuntos }  
                                                adjuntosExistentes= { actualAdjuntosExistentes } 
                                                saveAdjuntosExistentes= { saveActualAdjuntosExistentes }          
                                                readonly={ "" }
                                                traduction={confPantalla.config.trad.components.uplm}
                                                dropzone={ false }
                                            />
                                        :
                                            confControles.includes("6SL") || confControles.includes("SL6")
                                            ?
                                                <Uplm
                                                    estadoForm= { estadoForm }
                                                    loading=    { false }
                                                    col={12}
                                                    id={controles.uplm.id}
                                                    adjuntos={ actualAdjuntos }
                                                    saveAdjuntos= { saveActualAdjuntos }  
                                                    adjuntosExistentes= { actualAdjuntosExistentes } 
                                                    saveAdjuntosExistentes= { saveActualAdjuntosExistentes }          
                                                    readonly={ "readonly"}
                                                    traduction={confPantalla.config.trad.components.uplm}
                                                    dropzone={ false }
                                                />
                                            :
                                                <div className="noDisplay">
                                                    <Uplm
                                                        estadoForm= { estadoForm }
                                                        loading=    { false }
                                                        col={12}
                                                        id={controles.uplm.id}
                                                        adjuntos={ actualAdjuntos }
                                                        saveAdjuntos= { saveActualAdjuntos }  
                                                        adjuntosExistentes= { actualAdjuntosExistentes } 
                                                        saveAdjuntosExistentes= { saveActualAdjuntosExistentes }          
                                                        readonly={ "readonly" }
                                                        traduction={confPantalla.config.trad.components.uplm}
                                                        dropzone={ false }
                                                    />
                                                </div>
                                    }
                                {
                                    confControles.map((i) => {
                                        // fecDocOut = getFechaHoraActualSql();        
                                        return(                                        
                                                i == "0" || i == "0R" || i == "R0" || i == "0SL" || i == "SL0" ?
                                                    // 0.NADA (DETECTAR SIGUIENTE) 
                                                    null
                                                :
                                                i == "10" || i == "10R" || i == "R10" || i == "10SL" || i == "SL10" ?
                                                    // 10.OMITIR LINEA - ESTA LÍNEA NO SE MUESTRA, SE OMITE
                                                    null
                                                :
                                                i == "1" || i == "1R" || i == "R1" || i == "1SL" || i == "SL1" ?
                                                    // 1.SI/NO (DETECTAR RESPUESTA)
                                                    <CheckIos
                                                        val= { formulario.idest == 9 ? "1" : "" }
                                                        name={null}
                                                        label={ formulario.idest == 9 ? controles.checkidest.checked.label : formulario.idest == 12 ? controles.checkidest.unchecked.label : "" }
                                                        label2={ controles.idest.label }
                                                        id={formulario.name}
                                                        col={"12"}
                                                        change={ handleCheck }
                                                        required={ i.includes("R") ? true : false }
                                                        disabled={ i.includes("SL") ? true : false }                                                         
                                                    />                                        
                                                :
                                                i == "2" || i == "2+" || i == "+2" || i == "2-" || i == "S-" ?
                                                    // 2.ESTADO - CBO ESTADO 
                                                    <MiSelect  
                                                        estadoForm=  { estadoForm }
                                                        val=         { formulario.idest }   
                                                        loading=     { false }
                                                        id=          { controles.idest.id }    
                                                        name=        { controles.idest.name }   
                                                        label=       { controles.idest.label }    
                                                        placeholder= { controles.idest.placeholder }
                                                        col=         { controles.idest.col } 
                                                        isClearable= { controles.idest.isclearable }
                                                        isSearchable={ controles.idest.issearchable }
                                                        dataType=    { controles.idest.datatype }
                                                        data=        { controles.idest.data }
                                                        required=    { i.includes("+") ? "required" : false }
                                                        readOnly=    { i.includes("-") ? "readonly" : false } 
                                                        disabled=    { controles.idest.disabled }
                                                        // disablethisid={controles.idest.disablethisid}
                                                        // disableids={controles.idest.disableids}
                                                        formulario= { formulario }   
                                                        change= { handleSelect }
                                                    />  
                                                :
                                                i == "3" || i == "3R" || i == "R3" || i == "3SL" || i == "SL3" ?
                                                    // 3.CANTIDAD TXT CANTIDAD
                                                    <FormInput 
                                                        estadoForm= { estadoForm }
                                                        value=      { formulario.num1 }
                                                        loading=    { false }
                                                        id=         { controles.num1.id }   
                                                        type=       { controles.num1.type }  
                                                        name=       { controles.num1.name }   
                                                        label=      { controles.num1.label }                                                       
                                                        placeholder={ controles.num1.placeholder }    
                                                        col=        { controles.num1.col } 
                                                        required=   { i.includes("R") ? "required" : false }
                                                        readonly=   { i.includes("SL") ? "readonly" : false }                                                 
                                                        disabled=   { controles.num1.disabled }
                                                        change=     { handleInput }
                                                        onBlur=     {null} 
                                                    />   
                                                :
                                                i == "4" || i == "4R" || i == "R4" || i == "4SL" || i == "SL4" ?
                                                    // 4.HORAS/KMS - TXT KMS/HORAS
                                                    <FormInput 
                                                        estadoForm= { estadoForm }
                                                        value=      { formulario.num2 }
                                                        loading=    { false }
                                                        id=         { controles.num2.id }   
                                                        type=       { controles.num2.type }  
                                                        name=       { controles.num2.name }   
                                                        label=      { controles.num2.label }                                                       
                                                        placeholder={ controles.num2.placeholder }    
                                                        col=        { controles.num2.col } 
                                                        required=   { i.includes("R") ? true : false }
                                                        readonly=   { i.includes("SL") ? true : false }                                              
                                                        disabled=   { controles.num2.disabled }
                                                        change=     { handleInput }
                                                        onBlur=     {null} 
                                                    />
                                                :
                                                i == "5" || i == "5R" || i == "R5" || i == "5SL" || i == "SL5" ?
                                                    // 5.NOTAS - EDT NOTAS/NOTES
                                                    <Fragment>
                                                        {
                                                            cookies.get("idioma") == "es"
                                                            ?
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoForm }
                                                                    value=      { formulario.notas }
                                                                    loading=    { false }
                                                                    id=         { controles.notas.id }   
                                                                    name=       { controles.notas.name }
                                                                    rows=       { controles.notas.rows }      
                                                                    label=      { controles.notas.label }                                                       
                                                                    placeholder={ controles.notas.placeholder }    
                                                                    col=        { controles.notas.col } 
                                                                    required=   { i.includes("R") ? true : false }
                                                                    readonly=   { i.includes("SL") ? true : false }                                                 
                                                                    disabled=   { controles.notas.disabled }
                                                                    change=     { handleInput }
                                                                    onBlur=     {null} 
                                                                />
                                                            :
                                                                cookies.get("idioma") == "en"
                                                                ?
                                                                    <TextArea                                                        
                                                                        estadoForm= { estadoForm }
                                                                        value=      { formulario.notas_en }
                                                                        loading=    { false }
                                                                        id=         { controles.notas_en.id }   
                                                                        name=       { controles.notas_en.name }
                                                                        rows=       { controles.notas_en.rows }      
                                                                        label=      { controles.notas_en.label }                                                       
                                                                        placeholder={ controles.notas_en.placeholder }    
                                                                        col=        { controles.notas_en.col } 
                                                                        required=   { i.includes("R") ? true : false }
                                                                        readonly=   { i.includes("SL") ? true : false }                                                
                                                                        disabled=   { controles.notas_en.disabled }
                                                                        change=     { handleInput }
                                                                        onBlur=     {null} 
                                                                    />
                                                                :
                                                                    <Fragment>
                                                                        <TextArea                                                        
                                                                            estadoForm= { estadoForm }
                                                                            value=      { formulario.notas }
                                                                            loading=    { false }
                                                                            id=         { controles.notas.id }   
                                                                            name=       { controles.notas.name }
                                                                            rows=       { controles.notas.rows }      
                                                                            label=      { controles.notas.label }                                                       
                                                                            placeholder={ controles.notas.placeholder }    
                                                                            col=        { controles.notas.col } 
                                                                            required=   { i.includes("R") ? true : false }
                                                                            readonly=   { i.includes("SL") ? true : false }                                                 
                                                                            disabled=   { controles.notas.disabled }
                                                                            change=     { handleInput }
                                                                            onBlur=     {null} 
                                                                        />
                                                                        <TextArea                                                        
                                                                            estadoForm= { estadoForm }
                                                                            value=      { formulario.notas_en }
                                                                            loading=    { false }
                                                                            id=         { controles.notas_en.id }   
                                                                            name=       { controles.notas_en.name }
                                                                            rows=       { controles.notas_en.rows }      
                                                                            label=      { controles.notas_en.label }                                                       
                                                                            placeholder={ controles.notas_en.placeholder }    
                                                                            col=        { controles.notas_en.col } 
                                                                            required=   { i.includes("R") ? true : false }
                                                                            readonly=   { i.includes("SL") ? true : false }                                                
                                                                            disabled=   { controles.notas_en.disabled }
                                                                            change=     { handleInput }
                                                                            onBlur=     {null} 
                                                                        />
                                                                    </Fragment>
                                                        }
                                                    </Fragment>
                                                :                                                
                                                i == "7" || i == "7R" || i == "R7" || i == "7SL" || i == "SL7" ?
                                                    // 7.FIRMAR ALGO - PEDIR 1 FIRMA
                                                    null
                                                :
                                                i == "8" || i == "8R" || i == "R8" || i == "8SL" || i == "SL8" ?
                                                    // 8. REFERENCIA CLIENTE / LISTA_REFERENCIA / POSCION - MOSTRAR/OCULTAR SIEMPRE TODAS
                                                    <Fragment>
                                                        <FormInput 
                                                            estadoForm= { estadoForm }
                                                            value=      { formulario.txt1 }
                                                            loading=    { false }
                                                            id=         { controles.txt1.id }   
                                                            type=       { controles.txt1.type }  
                                                            name=       { controles.txt1.name }   
                                                            label=      { controles.txt1.label }                                                       
                                                            placeholder={ controles.txt1.placeholder }    
                                                            col=        { controles.txt1.col } 
                                                            required=   { i.includes("R") ? true : false }
                                                            readonly=   { i.includes("SL") ? true : false }                                                 
                                                            disabled=   { controles.txt1.disabled }
                                                            change=     { handleInput }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput 
                                                            estadoForm= { estadoForm }
                                                            value=      { formulario.txt2 }
                                                            loading=    { false }
                                                            id=         { controles.txt2.id }   
                                                            type=       { controles.txt2.type }  
                                                            name=       { controles.txt2.name }   
                                                            label=      { controles.txt2.label }                                                       
                                                            placeholder={ controles.txt2.placeholder }    
                                                            col=        { controles.txt2.col } 
                                                            required=   { i.includes("R") ? true : false }
                                                            readonly=   { i.includes("SL") ? true : false }                                                 
                                                            disabled=   { controles.txt2.disabled }
                                                            change=     { handleInput }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput 
                                                            estadoForm= { estadoForm }
                                                            value=      { formulario.txt3 }
                                                            loading=    { false }
                                                            id=         { controles.txt3.id }   
                                                            type=       { controles.txt3.type }  
                                                            name=       { controles.txt3.name }   
                                                            label=      { controles.txt3.label }                                                       
                                                            placeholder={ controles.txt3.placeholder }    
                                                            col=        { controles.txt3.col } 
                                                            required=   { i.includes("R") ? true : false }
                                                            readonly=   { i.includes("SL") ? true : false }                                                 
                                                            disabled=   { controles.txt3.disabled }
                                                            change=     { handleInput }
                                                            onBlur=     {null} 
                                                        />
                                                    </Fragment>                                            
                                                :
                                                i == "9" || i == "9R" || i == "R9" || i == "9SL" || i == "SL9" ?
                                                    // 9. ARTICULO - CBO ARTICULO
                                                    <MiSelect  
                                                        estadoForm=  { estadoForm }
                                                        val=         { formulario.iduni  }   
                                                        loading=     { false }
                                                        id=          { controles.iduni.id }    
                                                        name=        { controles.iduni.name }   
                                                        label=       { controles.iduni.label }    
                                                        placeholder= { controles.iduni.placeholder }
                                                        col=         { controles.iduni.col } 
                                                        isClearable= { controles.iduni.isclearable }
                                                        isSearchable={ controles.iduni.issearchable }
                                                        dataType=    { controles.iduni.datatype }
                                                        data=        { controles.iduni.data }
                                                        required=    { i.includes("R") ? "required" : false }
                                                        readOnly=    { i.includes("SL") ? "readonly" : false } 
                                                        disabled=    { controles.iduni.disabled }
                                                        // disablethisid={controles.iduni.disablethisid}
                                                        // disableids={controles.iduni.disableids}
                                                        formulario= { formulario }   
                                                        change= { handleSelect }
                                                    />
                                                :
                                                i == "13" || i == "13R" || i == "R13" || i == "13SL" || i == "SL13" ?
                                                    // 13. FECHA DE PREVISIÓN(FEC2) SOLO FECHA 
                                                    <FormInput 
                                                        estadoForm= { estadoForm }
                                                        value=      { formulario.fec2.split("T")[0] }
                                                        loading=    { false }
                                                        id=         { controles.fec2.id }   
                                                        type=       { "date" }  
                                                        name=       { controles.fec2.name }   
                                                        label=      { controles.fec2.label }                                                       
                                                        placeholder={ controles.fec2.placeholder }    
                                                        col=        { controles.fec2.col } 
                                                        required=   { i.includes("R") ? true : false }
                                                        readonly=   { i.includes("SL") ? true : false }                                              
                                                        disabled=   { controles.fec2.disabled }
                                                        change=     { handleInput }
                                                        onBlur=     {null} 
                                                    />
                                                :
                                                i == "14" || i == "14R" || i == "R14" || i == "14SL" || i == "SL14" ?
                                                    // 14. FECHA DE PREVISIÓN(FEC2) FECHA+HORA 
                                                    <FormInput 
                                                        estadoForm= { estadoForm }
                                                        value=      { formulario.fec2 }
                                                        loading=    { false }
                                                        id=         { controles.fec2.id }   
                                                        type=       { "datetime-local" }  
                                                        name=       { controles.fec2.name }   
                                                        label=      { controles.fec2.label }                                                       
                                                        placeholder={ controles.fec2.placeholder }    
                                                        col=        { controles.fec2.col } 
                                                        required=   { i.includes("R") ? true : false }
                                                        readonly=   { i.includes("SL") ? true : false }                                              
                                                        disabled=   { controles.fec2.disabled }
                                                        change=     { handleInput }
                                                        onBlur=     {null} 
                                                    />
                                                :                                     
                                                null
                                        )
                                    })
                                }
                            </Fragment>
                        }
                        </FormRow> 

                        <div className="float-right">
                            <ButtonOutline 
                                clases={ "btn-trab" }
                                color={ "primary" }  
                                type="button" 
                                icono={ "fal fa-arrow-left " }   
                                id=""     
                                texto={ "" }   
                                accion={ previous } 
                                disabled={ props.currentStep == 1 ? true : false }
                            />                                     
                            <ButtonOutline 
                                clases={ "btn-trab" }
                                color={ "primary" }  
                                type="button" 
                                icono={ "fal fa-arrow-right " }   
                                id=""     
                                texto={ "" }   
                                accion={ next } 
                                // disabled={ props.currentStep == props.totalSteps ? true : false }
                                disabled={ false }
                            />
                        </div>
                    </Fragment>
                :
                   
                <div className="float-right">
                    <ButtonOutline 
                        clases={ "btn-trab" }
                        color={ "primary" }  
                        type="button" 
                        icono={ "fal fa-arrow-left " }   
                        id=""     
                        texto={ "" }   
                        accion={ previous } 
                        disabled={ props.currentStep == 1 ? true : false }
                    />                                     
                    <ButtonOutline 
                        clases={ "btn-trab" }
                        color={ "primary" }  
                        type="button" 
                        icono={ "fal fa-arrow-right " }   
                        id=""     
                        texto={ "" }   
                        accion={ next } 
                        // disabled={ props.currentStep == props.totalSteps ? true : false }
                        disabled={ false }
                    />
                </div>
            }                      
    <Modal
                show={showModal}
                onHide={ () => saveShowModal(false)}                            
                aria-labelledby="example-custom-modal-styling-title"
                centered={true}                    
                dialogClassName="modal-90w"                                             
            > 
                <Modal.Header closeButton>
                    {
                        !docFinalizado
                        ?
                            <h4>{confPantalla.documentostrab.hijo.controles.modalwizard.titulo.title}</h4>
                        :
                            null
                    }
                </Modal.Header>                         
                <Modal.Body>
                    <FormRow> 
                        {
                            !docFinalizado
                            ?
                                <Fragment>

                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { fec1 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec1.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.required }
                                        readonly=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.disabled }
                                        change=     { e => handleInputFecModal(e,saveFec1) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { cabecera.fec2 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec2.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.required }
                                        readonly=   { true }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.disabled }
                                        change=     {null}
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { fec3 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec3.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.required }
                                        readonly=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.disabled }
                                        change=     { e => handleInputFecModal(e,saveFec3) }
                                        onBlur=     {null} 
                                    />
                                </Fragment>
                            :
                                null
                        }                     
                    </FormRow>
                    {
                        estableciendoFechas
                        ?
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>                    
                        :
                            null
                    }
                    {
                        !docFinalizado
                        ?
                            <Button 
                                clases={ "col-12 mt-2" }
                                color={ "success" }  
                                type="button" 
                                icono={ "" }   
                                id="btnsetmodal"     
                                texto={ confPantalla.documentostrab.hijo.controles.modalwizard.btntitle.title }   
                                accion={ accionModal } 
                                disabled={ false }
                            />
                        :
                            null
                    }
                    {
                        docFinalizado
                        ?
                            <div className='flex-center' style={{flexDirection: "column", alignItems: "center"}}>
                                <p>
                                    <i className="fal fa-check-circle fa-4x" style={{color : "#28a745"}}></i>
                                </p>
                                <h3 style={{fontWeight: "lighter", fontStyle: "italic"}}>
                                    {confPantalla.documentostrab.hijo.controles.modalwizard.txtfin.title}
                                </h3>
                            </div>
                        :
                            null
                    }
                    <div className="text-right"> 
                    {
                        btnRevisar
                        ?
                            <button 
                                    className="swal-button swal-button--danger btn btn-flat mr-1 mt-1 btn-outline-danger"
                                    onClick={ e => accionBtnRevisarModal(e) }                                        
                            >
                                {confPantalla.documentostrab.hijo.controles.modalwizard.btnRevisar.title}
                            </button>
                        :
                            null
                    }
                    {
                        btnAceptar
                        ?
                            <button 
                                    className="swal-button swal-button--success btn btn-flat mr-1 mt-1 btn-outline-success"
                                    onClick={ e => accionBtnAceptarModal(e) }                                        
                            >
                                {confPantalla.documentostrab.hijo.controles.modalwizard.btnAceptar.title}
                            </button>                              
                        :
                            null
                    }                      
                    </div>                                                                        
                </Modal.Body>                            
            </Modal>
        </div>
    )
}

export default WizardCheckListStep;