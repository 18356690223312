import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";
import axios from 'axios';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { SRLWrapper } from "simple-react-lightbox";


import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, axiosPost } from '../../../helpers/helperApp';


import TableMiDataTableAdjLin from './TableMiDataTableAdjLin';


function MiDataTableAdjLin(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();   

    const { apiKey } = useContext(LoginContext);

    const { id, loading, formulario, table, traduction, handleEditReg } = props;

    const [datosTable, saveDatosTable] = useState([]);
    const [loadingData, saveLoadingData] = useState(false);

     
    useEffect( () => {   

        // getDataDataTable(val)
        getDataDataTable()
        .then((res) => {
            saveDatosTable(res["adjuntos"]);
        });

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 

    },[loading, formulario.id])

    const getDataDataTable = async () =>{        
        saveLoadingData(true);

        const data = new FormData();
        data.append("id", formulario.id);
        data.append("validacion", apiKey());
        // const res = await axios.post(getApiUrl(window.location.origin)+table.data.url, data);
        const res = await axiosPost(getApiUrl(window.location.origin)+table.data.url, data, source.token);
                     
        saveLoadingData(false);
        return res.data;
    }

    return(
        <div className={"form-group col-md-12"}>
            <SRLWrapper>
                {
                    loading || loadingData
                    ?
                        <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                            <Spinner animation="border" />
                        </div>
                    :
                    <TableMiDataTableAdjLin 
                        id={ "tableInfo-"+id }
                        columnas={ table.columns }
                        datosTable={ datosTable }
                        saveDatosTable={ saveDatosTable }
                        readonly={ "" }
                        estadoForm= { "" }
                        columnactions={ table.columnactions.title }
                        traduction={ traduction }
                        buttons={ table.buttons }
                        ordering={ table.ordering }
                        order={ table.order }
                        searching={ table.searching }
                        paging={ table.paging }
                        select={ table.select }
                        grouping={ table.grouping}
                        pagelength = { table.pagelength }
                        handleEditReg = { handleEditReg }
                    />                             
                }
            </SRLWrapper>
        </div>      
    )
}

export default MiDataTableAdjLin;