import React, { useEffect, useState, useContext } from 'react';
import StepWizard from "react-step-wizard";
import WizardStepMateriales from './WizardMaterialesStep';
import WizardMaterialesStepMateriales from './WizardMaterialesStepMateriales';
import WizardMaterialesStepAgrupado from './WizardMaterialesStepAgrupado';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import axios from 'axios';

import { LoginContext } from '../../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';
import { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import ButtonOutline from '../../ui/ButtonOutline';
import Informes from '../informes/Informes';

import { getIndexUrl, getApiUrl, axiosPost } from '../../../helpers/helperApp';

function WizardMateriales(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { titulo, cabecera, registrosHijo, controles, estadoForm, adjuntos, adjuntosExistentes, confPantalla, saveLoadReg, propiedades, adjuntosCabecera, informeSelect, informeLoadReg, informeEstadoForm, informeTraduction, loadedHijos } = props;
    const { apiKey, menuMode } = useContext(LoginContext);

    const [ lineas, saveLineas ] = useState([]);
    const [ currentStep, saveCurrentStep] = useState(0);
    const [ regAnterior, saveRegAnterior ] = useState();
    const [ resumenHerramientas, saveResumenHerramientas ] = useState([]);
    const [ resumenMateriales, saveResumenMateriales ] = useState([]);
    const [ loadedSteps, saveLoadedSteps ] = useState(false);
    const [ loadingForms, saveLoadingForms ] = useState(true);
    const [ trabajoSinLineas, saveTrabajoSinLineas ] = useState(false); 
    const [ lineaSoloHerramientas, saveLineaSoloHerramientas ] = useState([]);
    const [ lineasMateriales, saveLineasMateriales ] = useState([]);
    const [ nomEquipo, saveNomEquipo ] = useState("");
    const [ loadedHijosState, saveLoadedHijosState ]=useState(loadedHijos);
    const [ lineasAgrupadas, saveLineasAgrupadas ] = useState([]);
           
    useEffect( () => { 


        // Cargamos la configuración de todas las líneas y guardamos las que no sean herramientas en el state para pasarle al stepwizard
        init(registrosHijo);  
        
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        }

    },[registrosHijo])

    // Preparamos los adjuntos de la cabecera para el datatable    
    adjuntosCabecera.map(adj => {
        adj["html"] = `<a 
                        href='${getApiUrl(window.location.origin)+adj.rut}'
                        target='_blank'
                        download=${getApiUrl(window.location.origin)+adj.rut}
                        class='btn btn-success'
                        style='color: white !important'
                        >
                            <i 
                                class='fal fa-download'
                                data-rut=${getApiUrl(window.location.origin)+adj.rut}
                                style='margin-right:5'
                            >
                            </i>
                        </a>
                        ${adj.des}
                    `; 

    })    

    const init = async (registrosHijo) => {

        await loadConfWizard(registrosHijo)

    }

    // Reordena las lineas con el orden materiales, agrupados, adjuntos y el resto de lineas
    const reorderLines = data => {
        let newData = [];
        let auxMateriales = [];
        let auxAgrupados = [];
        let auxAdjuntos = [];
        let auxRestoLineas = [];

        for( let i=0; i<Object.keys(data).length; i++ ){
            for (const [key, configuracionLinea] of Object.entries(data[i])) {                
                    if( configuracionLinea.includes("12") || configuracionLinea.includes("12R") || configuracionLinea.includes("R12") || configuracionLinea.includes("12SL") || configuracionLinea.includes("SL12") ){
                        // Materiales
                        auxMateriales.push(data[i]);
                    }else if( configuracionLinea.includes("21") || configuracionLinea.includes("21R") || configuracionLinea.includes("R21") || configuracionLinea.includes("21SL") || configuracionLinea.includes("SL21") ){
                        // Agrupados
                        auxAgrupados.push(data[i]);
                    }else if( configuracionLinea.includes("6") || configuracionLinea.includes("6R") || configuracionLinea.includes("R6") || configuracionLinea.includes("6SL") || configuracionLinea.includes("SL6") ){
                        // Adjuntos
                        auxAdjuntos.push(data[i]);
                    }else{
                        auxRestoLineas.push(data[i]);
                    }
            }
        }
      
        // Montamos el array final con el orden que queremos
        auxMateriales.map((aux)=> {
            newData.push(aux);
        })
        auxAgrupados.map((aux)=> {
            newData.push(aux);
        })
        auxAdjuntos.map((aux)=> {
            newData.push(aux);
        })
        auxRestoLineas.map((aux)=> {
            newData.push(aux);
        })

        return newData;
    }


    const loadConfWizard = async(registrosHijo) => {

        const data = new FormData();
        data.append("validacion", apiKey());

        let regsIdUniIdTip = []
        registrosHijo.map( reg => {
            let aux = { idreg : reg.id, iduni : reg.iduni, idtip : reg.tipuni }
            regsIdUniIdTip.push(aux);
        } )

        data.append("getconf", JSON.stringify(regsIdUniIdTip));
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+controles.wizardmateriales.getconfuni, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+controles.wizardmateriales.getconfuni, data, source.token);
        let auxResumenHerramientas = [];
        let auxResumenMateriales = [];
        let todasLineasSinConfiguracion = true;
        let materialInsertado = false;
        let siNoInsertado = false;
        let auxLineasSinAgrupar = [];
        let totalLineas = [];

        // Reordenamos las líneas para colocar primero los materiales(si existen), seguido de los agrupados(si los hay), luego adjunto y finalmente el resto de lineas. Se hace porque sino si van los adjuntos antes de las lineas agrupadas no cuadra el wizard y hace preguntas mal. 
        respuesta.data = reorderLines(respuesta.data);

        if( Object.keys(respuesta.data).length > 0 ){
            for( let i=0; i<Object.keys(respuesta.data).length; i++ ){
                for (const [key, configuracionLinea] of Object.entries(respuesta.data[i])) {
                    let esHerramienta = false;
                    let material = false;
                    let agrupado = false;
                    
                    // Filtro el registro que estoy iterando
                    const registro = registrosHijo.filter( reg => reg.id == key )[0];

                    // Guardamos el nombre del equipo en el state
                    saveNomEquipo(registro.equipo);                    

                    if( registro.tipuni == 11 ){
                        material = true;
                    }

                    if( configuracionLinea ){
                        todasLineasSinConfiguracion = false;
                        configuracionLinea.split(";").map((e) => {
                            if( e == 11 ){
                                // Si es una herramienta la línea no va a stepper y no va a ser un paso
                                // 11. RESUMEN HERRAMIENTAS
                                if( auxResumenHerramientas.filter(herr => herr.html == registro.nomart).length == 0) { 
                                    let auxHerr = [];
                                    auxHerr["html"] = registro.nomart;                          
                                    auxResumenHerramientas.push(auxHerr);
                                }
                                esHerramienta=true; 
                            }
                            if( e == 12 ){
                                // 12. RESUMEN MATERIALES                               
                                if( auxResumenMateriales.filter(mat => mat.mat == registro.nomart).length == 0 ){ 
                                    let auxMat = [];
                                    auxMat["mat"] = registro.nomart;
                                    auxMat["html"] = `<a 
                                                      href='${confPantalla.articulos.padre.controles.stoact.linkright}/${registro.iduni}'
                                                      target='_blank'
                                                      class='btn btn-${confPantalla.articulos.padre.controles.stoact.linkcolor}'
                                                      style='color: white !important'
                                                      >
                                                      <i class='${confPantalla.articulos.padre.controles.stoact.linkico}'></i>
                                                      </a>
                                                      ${registro.nomart}
                                                    `;
                                    auxResumenMateriales.push(auxMat);                                                                        
                                }                                                          
                            }
                            if( e == 21 || e == "21" || e == "21R" || e == "R21" || e == "21SL" || e == "SL21"){ 
                                agrupado=true; 
                            }                                                                        
                        })
            
                        // Almacenamos en el state que pasamos al stepwizar las lineas que no son herramientas para crear solo estos pasos.
                        if( !esHerramienta && !material && !agrupado ){
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }                            
                            totalLineas.push(aux);
                        }
                        // Almacenamos en el state de lineas agrupadas para crear un único paso para ellos
                        if( agrupado ){
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineasAgrupadas(lineasAgrupadas => [...lineasAgrupadas, aux]);
                            if( !siNoInsertado ){
                                siNoInsertado = true;
                                totalLineas.push(aux);
                            }                               
                        } 
                        // Almacenamos en el state de materiales las líneas de materiales para crear un único paso para ellos
                        if( material ){
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineasMateriales(lineasMateriales => [...lineasMateriales, aux]);
                            if( !materialInsertado ){
                                materialInsertado = true;                                
                                totalLineas.push(aux);
                            }
                        }
                        if( esHerramienta ){
                            // En el caso de ser herramientas las guardamos en otro state pero sobreescribiendo el registro para que solo acumule 1, con lo que al final tendremos en este state solo un registro. Este state es el que pasamos al wizard en el caso de que todas las lineas sean de herramientas para que al darle a siguiente en este único paso el backend ejecute todas las acciones necesarias que se realizan en la última línea del wizard
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineaSoloHerramientas([...lineaSoloHerramientas, aux]);
                        }          
                    }
    
                }
            }
            saveResumenHerramientas(auxResumenHerramientas)  
            saveResumenMateriales(auxResumenMateriales)
            
            // Guardamos en el state de lineas las lineas para crear los pasos
            saveLineas(totalLineas);
            
            // Modificación corrección mensaje trabajo no disponble mientras no llegan las lineas
            saveTrabajoSinLineas(false);
            if( todasLineasSinConfiguracion ){
                saveTrabajoSinLineas(true);
            }
            
        }else if( respuesta.data.length == 0 ){
            // Si i es igual a 0 no hay lineas
            saveTrabajoSinLineas(true);
            saveLoadedSteps(true);
        }
        
        saveLoadedSteps(true);

    }

    const steps = []
    for (let i = 1 ; i <= lineas.length; i++) {    
            steps.push(
                <Step>
                    <StepLabel></StepLabel>
                </Step>
            )
    }

    const noLinesNext = () => {
        propiedades.history.push(getIndexUrl(menuMode));
    } 

    return(
        <div>
            {
                lineas.length > 0 && loadedSteps 
                ?
                <Fragment>
                    <Informes
                        select =     { informeSelect }
                        formulario = { cabecera }
                        loadReg =    { informeLoadReg ? true : false }
                        estadoForm=  { informeEstadoForm } 
                        traduction=  { informeTraduction }
                    />   
                    <div className="row">
                        <hr data-content={currentStep+1+"/"+lineas.length} className="hr-text-xl"></hr>
                    </div>
                    <div className="row" style={{overflowY: "auto"}}>
                        <div className="col-md-12 stepsContainer">
                            <Stepper activeStep={currentStep}>
                                {steps}                      
                            </Stepper>
                        </div>
                    </div>
                    
                    <StepWizard>
                        {
                            lineas.map((linea, index, lineas) => {
                                
                                if( index == 0 && lineasMateriales.length > 0 ){
                                    return(
                                        <WizardMaterialesStepMateriales
                                            key={ index }
                                            materiales={ lineasMateriales }
                                            saveMateriales={ saveLineasMateriales }
                                            cabecera={ cabecera }
                                            paso={ index+1 }
                                            pasos={ lineas.length }
                                            // linea={ linea.linea }
                                            // respuesta={ linea.respuesta }
                                            saveCurrentStep={ saveCurrentStep }
                                            controles={ controles } 
                                            estadoForm={ estadoForm }
                                            // adjuntos={ adjuntos }
                                            // adjuntosExistentes={ adjuntosExistentes }
                                            confPantalla={ confPantalla }
                                            saveLoadReg={ saveLoadReg }
                                            propiedades={ propiedades }
                                            // regAnterior={ regAnterior }
                                            saveRegAnterior={ saveRegAnterior }
                                            resumenHerramientas={ resumenHerramientas }
                                            resumenMateriales={ resumenMateriales }
                                            loadingForms={ loadingForms }
                                            saveLoadingForms={ saveLoadingForms }
                                            soloHerramientas={ false }
                                            adjuntosCabecera={ adjuntosCabecera }
                                            updatemateriales={ controles.wizardmateriales.updatemateriales } 
                                            nomEquipo={ nomEquipo }
                                        />
                                    )
                                }else if( index == 1 && lineasAgrupadas.length > 0 ){
                                    return(
                                        <WizardMaterialesStepAgrupado
                                            key={ index }
                                            lineasAgrupadas={ lineasAgrupadas }
                                            saveLineasAgrupadas={ saveLineasAgrupadas }                                            
                                            cabecera={ cabecera }
                                            paso={ index+1 }
                                            pasos={ lineas.length }
                                            saveCurrentStep={ saveCurrentStep }
                                            controles={ controles } 
                                            estadoForm={ estadoForm }
                                            confPantalla={ confPantalla }
                                            saveLoadReg={ saveLoadReg }
                                            propiedades={ propiedades }
                                            saveRegAnterior={ saveRegAnterior }
                                            resumenHerramientas={ resumenHerramientas }
                                            resumenMateriales={ resumenMateriales }
                                            loadingForms={ loadingForms }
                                            saveLoadingForms={ saveLoadingForms }
                                            soloHerramientas={ false }
                                            adjuntosCabecera={ adjuntosCabecera }
                                            updateagrupados={ controles.wizardmateriales.updateagrupados } 
                                            nomEquipo={ nomEquipo }
                                        />
                                    )
                                }else{
                                    return(                                    
                                        <WizardStepMateriales                                    
                                            key={ index }
                                            cabecera={ cabecera }
                                            paso={  index+1  }
                                            pasos={ lineas.length }
                                            linea={ linea.linea }
                                            respuesta={ linea.respuesta }
                                            saveCurrentStep={ saveCurrentStep }
                                            controles={ controles } 
                                            estadoForm={ estadoForm }
                                            adjuntos={ adjuntos }
                                            adjuntosExistentes={ adjuntosExistentes }
                                            confPantalla={ confPantalla }
                                            saveLoadReg={ saveLoadReg }
                                            propiedades={ propiedades }
                                            regAnterior={ regAnterior }
                                            saveRegAnterior={ saveRegAnterior }
                                            resumenHerramientas={ resumenHerramientas }
                                            resumenMateriales={ resumenMateriales }
                                            loadingForms={ loadingForms }
                                            saveLoadingForms={ saveLoadingForms }
                                            soloHerramientas={ false }
                                            adjuntosCabecera={ adjuntosCabecera }
                                            nomEquipo={ nomEquipo }
                                        />
                                    )
                                }                             
                            })
                        }                
                    </StepWizard>                    
                </Fragment>
                :
                    lineaSoloHerramientas.length > 0 && loadedSteps && !trabajoSinLineas
                    ?
                        <Fragment>
                            <Informes
                                select =     { informeSelect }
                                formulario = { cabecera }
                                loadReg =    { informeLoadReg ? true : false }
                                estadoForm=  { informeEstadoForm } 
                                traduction=  { informeTraduction }
                            />   
                            <div className="row">
                                <hr data-content={currentStep+1+"/1"} className="hr-text-xl"></hr>
                            </div>
                            <div className="row" style={{overflowY: "auto"}}>
                                <div className="col-md-12 stepsContainer">
                                    <Stepper activeStep={currentStep}>
                                        <Step>
                                            <StepLabel></StepLabel>
                                        </Step>                      
                                    </Stepper>
                                </div>
                            </div>
                            
                            <StepWizard>
                                {
                                    lineaSoloHerramientas.map((linea, index, lineas) => {
                                        return(                                    
                                            <WizardStepMateriales                                    
                                                key={ index }
                                                cabecera={ cabecera }
                                                paso={ index+1 }
                                                pasos={ lineas }
                                                linea={ linea.linea }
                                                respuesta={ linea.respuesta }
                                                saveCurrentStep={ saveCurrentStep }
                                                controles={ controles } 
                                                estadoForm={ estadoForm }
                                                adjuntos={ adjuntos }
                                                adjuntosExistentes={ adjuntosExistentes }
                                                confPantalla={ confPantalla }
                                                saveLoadReg={ saveLoadReg }
                                                propiedades={ propiedades }
                                                regAnterior={ regAnterior }
                                                saveRegAnterior={ saveRegAnterior }
                                                resumenHerramientas={ resumenHerramientas }
                                                resumenMateriales={ resumenMateriales }
                                                loadingForms={ loadingForms }
                                                saveLoadingForms={ saveLoadingForms }
                                                soloHerramientas={ true }
                                                adjuntosCabecera={ adjuntosCabecera }
                                                nomEquipo={ nomEquipo }
                                            />
                                        )
                                    
                                    })
                                }                
                            </StepWizard>                            
                        </Fragment>
                    :
                        trabajoSinLineas && loadedSteps && loadedHijosState
                        ?
                            <Fragment>
                                <h1 className="text-center">{controles.wizardmateriales.nolines.title}</h1>
                                <div className="float-right">
                                    <ButtonOutline 
                                        clases={ "btn-trab" }
                                        color={ "primary" }  
                                        type="button" 
                                        icono={ "fal fa-arrow-right " }   
                                        id=""     
                                        texto={ "" }   
                                        accion={ noLinesNext } 
                                        disabled={ false }
                                    />
                                </div>
                            </Fragment>
                        : 
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>
            }
        </div>
    )
}

export default WizardMateriales;