import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';

const url = window.location;

function MenuEnc({ miNivel, activeMenuColor, confApp }){

    const [ actual, saveActual ] = useState(0);
    const [ open1, saveOpen1 ] = useState(false);
    const [ open2, saveOpen2 ] = useState(false); 
    const [ open9, saveOpen9 ] = useState(false); 

    useEffect(() => {
        loadTree();

        if( url.pathname.includes("/edirap") ){
            saveActual(1);
        }
        if( url.pathname.includes("/stockubi") || url.pathname.includes("/stockmov") || url.pathname.includes("/stockreu") ){
            saveActual(2);
        }
        if( url.pathname.includes("/impstoart") ){
            saveActual(9);
        }        

    },[])

    const handleOpen = (e, open, saveOpen, indice) => {

        if( open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('openMenu');  
                subMenu.classList.add('closeMenu');             
            }
        }else if( !open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('closeMenu');  
                subMenu.classList.add('openMenu');               
            }
        }
        saveOpen(!open);
    }

    return(
              
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" data-animation-speed="600" role="menu" data-accordion="true">
            {/* {
                miNivel <= confApp.documentosenc.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/documentosenc" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.documentosenc.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.documentosenc.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            } */}
            <li className="nav-item">
                <NavLink to="/inicio" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.documentosenc.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.documentostrab.sidebarmenu.titulo}</p>
                </NavLink>
            </li>  
            {
                miNivel <= confApp.supply.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/supply" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.supply.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.supply.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            
            }
            <li className="nav-item">
                <NavLink to="/checklist" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                <i className={confApp.checklist.sidebarmenu.icono} style={{marginRight:5}} />
                <p>{confApp.checklist.sidebarmenu.titulo}</p>
                </NavLink>
            </li>
            {
                miNivel <= confApp.edirap.panminlvl
                ?
                // <li className={ actual == 1 ? "nav-item menu-open " : "nav-item "} >
                    // <a href="#" className={ actual == 1 ? "nav-link active" : "nav-link"} style={{paddingLeft:10}} >
                <li className={ open1 === true || actual === 1 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open1, saveOpen1, 1) } >
                    <a href="#" className={ actual == 1 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                        <i className={confApp.documentos.sidebarmenu.icono}></i>
                        <p>
                            {confApp.documentos.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub1" className="nav nav-treeview sub">                        
                        {
                            miNivel <= confApp.edirap.panminlvl
                            ?
                            <li className="nav-item" >
                                <NavLink to="/edirap" className="nav-link" activeClassName="active" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.edirap.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.edirap.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                                                                        
                    </ul>
                </li>
                :
                null
            }  
            {
                miNivel <= confApp.stockubi.panminlvl || miNivel <= confApp.stockmov.panminlvl || miNivel <= confApp.stockreu.panminlvl
                ?
                <li className={ open2 === true || actual === 2  ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open2, saveOpen2, 2) } >
                    <a href="#" className={ actual == 2  ? "nav-link2 active" : "nav-link2"}  >
                        <i className={confApp.stockmov.sidebarmenuparent.icono}></i>
                        <p>
                            {confApp.stockmov.sidebarmenuparent.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub2" className="nav nav-treeview sub">
                        {
                            miNivel <= confApp.stockubi.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockubi" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.stockubi.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p  style={{paddingLeft:5}}>{confApp.stockubi.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.stockmov.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockmov" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.stockmov.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.stockmov.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.stockmov.panminlvl
                            ?
                            <li className="nav-item">
                                <NavLink to="/stockreu" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.stockreu.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.stockreu.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li>  
                            :
                            null
                        }                                                              
                    </ul>
                </li>
                :
                null
            }
            {
                miNivel <= confApp.articulos.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/articulos" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.articulos.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.articulos.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            }
            {
                miNivel <= confApp.equipos.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/equipos" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.equipos.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.equipos.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            }
            {
                miNivel <= confApp.centrosadj.panminlvl
                ?
                <li className="nav-item">
                    <NavLink to="/adjcentros" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.centrosadj.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.centrosadj.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                :
                null
            }
            <li className={ open9 === true || actual === 9 ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open9, saveOpen9, 9) } >
                        <a href="#" className={ actual == 9 ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                            <i className={confApp.menuadmin.impexp.icono}></i>
                            <p>
                                {confApp.menuadmin.impexp.titulo}
                                <i className="fas fa-angle-left right"></i>
                            </p>
                        </a>
                        <ul id="sub9" className="nav nav-treeview sub">
                            <li className="nav-item">
                                <NavLink to="/impstoart" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.impstoart.sidebarmenu.icono} style={{marginRight:5}} />
                                <p>{confApp.impstoart.sidebarmenu.titulo}</p>
                                </NavLink>
                            </li> 
                        </ul>
                </li>   
            {/* {
                miNivel <= confApp.ubicaciones.panminlvl || miNivel <= confApp.apartados.panminlvl || miNivel <= confApp.paises.panminlvl || miNivel <= confApp.tipdoc.panminlvl ||  miNivel <= confApp.estdoc.panminlvl || miNivel <= confApp.tipuni.panminlvl || miNivel <= confApp.tipage.panminlvl || miNivel <= confApp.tipobj.panminlvl || miNivel <= confApp.tipcen.panminlvl || miNivel <= confApp.loginusu.panminlvl || miNivel <= confApp.tipgf.panminlvl
                ?
                <li className="nav-item">
                    <a href="#" className="nav-link" style={{paddingLeft:10}} >
                        <i className="nav-icon fal fa-cog"></i>
                        <p>
                            {confApp.appcfg.sidebarmenu.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                            {
                                miNivel <= confApp.ubicaciones.panminlvl
                                ?
                                <li className="nav-item">
                                    <NavLink to="/ubicaciones" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.ubicaciones.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.ubicaciones.sidebarmenu.titulo}</p>
                                    </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.apartados.panminlvl
                                ?
                                <li className="nav-item">
                                    <NavLink to="/apartados" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.apartados.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.apartados.sidebarmenu.titulo}</p>
                                    </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.paises.panminlvl
                                ?
                                <li className="nav-item">
                                    <NavLink to="/paises" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.paises.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.paises.sidebarmenu.titulo}</p>
                                    </NavLink>
                                </li>
                                :
                                null
                            }
                            {
                                miNivel <= confApp.tipdoc.panminlvl
                                    ?
                                    <li className="nav-item">
                                    <NavLink to="/tipdoc" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                        <i className={confApp.tipdoc.sidebarmenu.icono} style={{marginRight:5}} />
                                        <p>{confApp.tipdoc.sidebarmenu.titulo}</p>
                                    </NavLink>
                                    </li>  
                                    :
                                    null
                            }
                            {
                                miNivel <= confApp.estdoc.panminlvl
                                ?
                                <li className="nav-item">
                                    <NavLink to="/estdoc" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.estdoc.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.estdoc.sidebarmenu.titulo}</p>
                                    </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.tipuni.panminlvl
                                ?
                                <li className="nav-item">
                                <NavLink to="/tipuni" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.tipuni.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.tipuni.sidebarmenu.titulo}</p>
                                </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.tipage.panminlvl
                                ?
                                <li className="nav-item">
                                <NavLink to="/tipage" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.tipage.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.tipage.sidebarmenu.titulo}</p>
                                </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.tipobj.panminlvl
                                ?
                                <li className="nav-item">
                                <NavLink to="/tipobj" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.tipobj.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.tipobj.sidebarmenu.titulo}</p>
                                </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.tipcen.panminlvl
                                ?
                                <li className="nav-item">
                                <NavLink to="/tipcen" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.tipcen.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.tipcen.sidebarmenu.titulo}</p>
                                </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.loginusu.panminlvl
                                ?
                                <li className="nav-item">
                                    <NavLink to="/loginusu" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.loginusu.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.loginusu.sidebarmenu.titulo}</p>
                                    </NavLink>
                                </li>
                                :
                                null
                            }
                            {
                                miNivel <= confApp.tipgf.panminlvl
                                ?
                                <li className="nav-item">
                                <NavLink to="/tipgf" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.tipgf.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.tipgf.sidebarmenu.titulo}</p>
                                </NavLink>
                                </li>  
                                :
                                null
                            }
                            {
                                miNivel <= confApp.appcfg.panminlvl
                                ?
                                <li className="nav-item">
                                    <NavLink to="/appcfg" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                    <i className={confApp.appcfg.sidebarmenu.icono} style={{marginRight:5}} />
                                    <p>{confApp.appcfg.sidebarmenu.titulo}</p>
                                    </NavLink>
                                </li>  
                                :
                                null
                            }

                    </ul>
                </li>
                :
                null
            } */}

            </ul>
        </nav>                          

       

    )
}

export default MenuEnc;